/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * NCPS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KYCRequiredRespAPIModel
 */
export interface KYCRequiredRespAPIModel {
    /**
     * 
     * @type {boolean}
     * @memberof KYCRequiredRespAPIModel
     */
    required: boolean;
}

/**
 * Check if a given object implements the KYCRequiredRespAPIModel interface.
 */
export function instanceOfKYCRequiredRespAPIModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "required" in value;

    return isInstance;
}

export function KYCRequiredRespAPIModelFromJSON(json: any): KYCRequiredRespAPIModel {
    return KYCRequiredRespAPIModelFromJSONTyped(json, false);
}

export function KYCRequiredRespAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): KYCRequiredRespAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'required': json['required'],
    };
}

export function KYCRequiredRespAPIModelToJSON(value?: KYCRequiredRespAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'required': value.required,
    };
}

