import { CircularProgress } from '@mui/material';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { MoneyAmountValue } from '@/features/dictionary/components';
import { usePurchaseKYCLimits, useRates } from '@/features/purchase/hooks';
import { I18nFeatureBuyPurchase } from '@/generated/i18n/i18n';

import type { SubmitButtonProps } from './types';

const SubmitButton: React.FC<SubmitButtonProps> = ({ 'data-test': dataTest, loading: baseLoading }) => {
  const {
    data: { data: rates },
    loading,
  } = useRates();
  const { isKYCRequired } = usePurchaseKYCLimits();

  const rate = useMemo(
    () =>
      rates?.price.total ? (
        <MoneyAmountValue value={rates.price.total.amount} currency={rates.price.total.currency} withCurrency />
      ) : null,
    [rates?.price.total],
  );
  return (
    <span data-test={dataTest}>
      {(loading || baseLoading) && (
        <>
          <CircularProgress color="inherit" size={16} />{' '}
        </>
      )}
      {isKYCRequired ? (
        <FormattedMessage id={I18nFeatureBuyPurchase.COMPONENTS_SUBMIT_KYC_TITLE} values={{ rate }} />
      ) : (
        <FormattedMessage id={I18nFeatureBuyPurchase.COMPONENTS_SUBMIT_BUY_TITLE} values={{ rate }} />
      )}
    </span>
  );
};

export default React.memo(SubmitButton);
