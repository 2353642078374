import ArrowBackIcon from '@mui/icons-material/KeyboardBackspace';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export interface BackButtonProps {
  backUrl: string;
  className?: string;
}

export const BackButton: React.FC<BackButtonProps> = ({ backUrl, className }) => {
  const navigate = useNavigate();
  const doClick = useCallback(() => navigate(backUrl), [backUrl, navigate]);
  return <ArrowBackIcon onClick={doClick} className={className} />;
};

export const withBackURL =
  (backUrl: string) =>
  (props: Omit<BackButtonProps, 'backUrl'>): React.ReactElement =>
    <BackButton backUrl={backUrl} {...props} />;

export default React.memo(BackButton);
