import pDebounce from 'p-debounce';

import {
  B2bUserCardLimitsUnverifiedTypeEnum,
  B2bUserKycAccessTokenKycLevelEnum,
  UserApi,
} from '@/generated/api/mercuryo/api';
import { MercuryoWidgetApi } from '@/generated/api/ncps/api';
import { mercuryoConfigurationFactory, ncpsConfigurationFactory } from '@/infrastructure/security/auth.provider';
import { parseMercuryoCall } from '@/utils/api';

const userApi = new UserApi(mercuryoConfigurationFactory('auth'));
const mercuryoApi = new MercuryoWidgetApi(ncpsConfigurationFactory('auth'));

export const queryKYCToken = async (level: B2bUserKycAccessTokenKycLevelEnum) =>
  parseMercuryoCall(userApi.b2bUserKycAccessToken(level));

export const queryKYCLimits = pDebounce(
  async () => parseMercuryoCall(userApi.b2bUserCardLimitsUnverified(B2bUserCardLimitsUnverifiedTypeEnum.buy)),
  0,
);

export const queryKYCExplicitRequirement = async () => (await mercuryoApi.getKYCRequired()).required;
export const postKYCExplicitRequirement = async () => {
  await mercuryoApi.markKYCRequired();
};
export const deleteKYCExplicitRequirement = async () => {
  await mercuryoApi.markKYCOptional();
};
