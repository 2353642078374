import { useEffect, useRef } from 'react';

import type { Func } from '@/utils/ts';

export default function useFetching(fetch: Func, isDirty: boolean, loading?: boolean): void {
  const fetching = useRef(false);

  useEffect(() => {
    if (!loading && isDirty && !fetching.current) {
      fetching.current = true;
      (async () => {
        try {
          await fetch();
        } finally {
          fetching.current = false;
        }
      })();
    }
  }, [fetch, isDirty, loading]);
}
