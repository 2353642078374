/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bValidateWallet200ResponseDataAPIModel
 */
export interface B2bValidateWallet200ResponseDataAPIModel {
    /**
     * Validation result
     * @type {string}
     * @memberof B2bValidateWallet200ResponseDataAPIModel
     */
    status?: B2bValidateWallet200ResponseDataAPIModelStatusEnum;
}


/**
 * @export
 */
export const B2bValidateWallet200ResponseDataAPIModelStatusEnum = {
    success: 'success',
    fail: 'fail'
} as const;
export type B2bValidateWallet200ResponseDataAPIModelStatusEnum = typeof B2bValidateWallet200ResponseDataAPIModelStatusEnum[keyof typeof B2bValidateWallet200ResponseDataAPIModelStatusEnum];


/**
 * Check if a given object implements the B2bValidateWallet200ResponseDataAPIModel interface.
 */
export function instanceOfB2bValidateWallet200ResponseDataAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bValidateWallet200ResponseDataAPIModelFromJSON(json: any): B2bValidateWallet200ResponseDataAPIModel {
    return B2bValidateWallet200ResponseDataAPIModelFromJSONTyped(json, false);
}

export function B2bValidateWallet200ResponseDataAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bValidateWallet200ResponseDataAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function B2bValidateWallet200ResponseDataAPIModelToJSON(value?: B2bValidateWallet200ResponseDataAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

