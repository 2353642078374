import type { LoadingStateWithDirty } from '@/utils/model';

export const NAMESPACE = 'dictionary';

export interface Currency {
  code: string;
  name?: string;
  digitsToDisplay: number;
  digitsTotal: number;
}

export interface DictionaryState {
  currencies: LoadingStateWithDirty<Currency[]>;
}
