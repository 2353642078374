import React from 'react';

import { FormattedMessage } from '@/components';
import { CallbackBanner } from '@/features/callback/components';
import { useCallbackProcessing } from '@/features/callback/hooks';
import { I18nFeatureCallback } from '@/generated/i18n/i18n';
import { MainLayout } from '@/layout';

const KYCSuccessCallbackPage: React.FC = () => {
  useCallbackProcessing('KYC', true);
  return (
    <MainLayout header={{ title: <FormattedMessage id={I18nFeatureCallback.PAGE_TITLE} /> }}>
      <CallbackBanner data-test="callback" />
    </MainLayout>
  );
};

export default KYCSuccessCallbackPage;
