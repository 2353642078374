import type { NewCard } from '@/features/cards/types';
import { RedirectCardData } from '@/features/transaction/types';
import {
  B2bFiatBuyCard200ResponseDataRedirectAPIModel,
  BuyApi,
  HelperApi,
} from '@/generated/api/mercuryo/api';
import { mercuryoConfigurationFactory } from '@/infrastructure/security/auth.provider';
import { parseMercuryoCall } from '@/utils/api';

const buyApi = new BuyApi(mercuryoConfigurationFactory('auth'));
const helperApi = new HelperApi(mercuryoConfigurationFactory('auth'));

interface BaseCreateBuyTransactionRequest {
  address: string;
  merchantTx: string;
  trxToken: string;
  cvv: string;
  tin?: string;
}

export interface CreateBuyTransactionRequestByNewCard {
  card: NewCard;
}

export interface CreateBuyTransactionRequestByOldCard {
  cardId: string;
}

interface CreateSavedCardTransactionRequest {
  address: string;
  merchantTx: string;
  trxToken: string;
  cardId?: string;
}

export type CreateBuyTransactionRequest = BaseCreateBuyTransactionRequest &
  (CreateBuyTransactionRequestByNewCard | CreateBuyTransactionRequestByOldCard);

export const requestCreateTransaction = async ({
  cvv,
  tin,
  merchantTx,
  address,
  trxToken,
  ...rest
}: CreateBuyTransactionRequest): Promise<B2bFiatBuyCard200ResponseDataRedirectAPIModel> => {
  const data = await parseMercuryoCall(
    buyApi.b2bFiatBuyCard({
      tin,
      address,
      trxToken,
      merchantTransactionId: merchantTx,
      cardCvv: cvv,
      ...((rest as CreateBuyTransactionRequestByOldCard).cardId
        ? { cardId: (rest as CreateBuyTransactionRequestByOldCard).cardId }
        : {
            cardNumber: (rest as CreateBuyTransactionRequestByNewCard).card.number,
            cardExpirationMonth: (rest as CreateBuyTransactionRequestByNewCard).card.expiration.month,
            cardExpirationYear: (rest as CreateBuyTransactionRequestByNewCard).card.expiration.year,
            cardHolderName: (rest as CreateBuyTransactionRequestByNewCard).card.holder,
          }),
    }),
  );
  if (!data?.redirect) {
    throw Error('no data');
  }
  return data.redirect;
};

export const requestCreateSavedCardTransaction = async ({
  merchantTx,
  address,
  trxToken,
  cardId,
}: CreateSavedCardTransactionRequest): Promise<RedirectCardData> => {
  const data = await parseMercuryoCall(buyApi.b2bBuy({ trxToken, address, merchantTransactionId: merchantTx, cardId }));

  if (!data?.redirectUrl) {
    throw Error('no data');
  }
  return { url: data.redirectUrl };
};

export const queryTransactionStatus = async (merchantTx: string) => {
  const data = await parseMercuryoCall(helperApi.b2bTransactions(merchantTx));
  return { status: data?.[0]?.status, failedReason: data?.[0]?.failedReason };
};
