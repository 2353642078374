import * as React from 'react';
import { Provider } from 'react-redux';

import configureStore from './configureStore';

let store: ReturnType<typeof configureStore> | undefined;
const getStore = () => {
  if (!store) {
    store = configureStore();
  }
  return store;
};

const withStore =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props: P) =>
    (
      <Provider store={getStore()}>
        <Component {...props} />
      </Provider>
    );

export default withStore;
