/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * NCPS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TokenWithInfoAPIModel,
  Web3CredentialsAPIModel,
} from '../models';
import {
    TokenWithInfoAPIModelFromJSON,
    TokenWithInfoAPIModelToJSON,
    Web3CredentialsAPIModelFromJSON,
    Web3CredentialsAPIModelToJSON,
} from '../models';

export interface LoginRequest {
    xRecaptcha: string;
    web3CredentialsAPIModel: Web3CredentialsAPIModel;
}

/**
 * AuthApi - interface
 * 
 * @export
 * @interface AuthApiInterface
 */
export interface AuthApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    authControllerLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * login operation by web3 wallet
     * @summary 
     * @param {string} xRecaptcha 
     * @param {Web3CredentialsAPIModel} web3CredentialsAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithInfoAPIModel>>;

    /**
     * login operation by web3 wallet
     * 
     */
    login(xRecaptcha: string, web3CredentialsAPIModel: Web3CredentialsAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithInfoAPIModel>;

}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI implements AuthApiInterface {

    /**
     */
    async authControllerLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authControllerLogoutRaw(initOverrides);
    }

    /**
     * login operation by web3 wallet
     * 
     */
    async loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithInfoAPIModel>> {
        if (requestParameters.xRecaptcha === null || requestParameters.xRecaptcha === undefined) {
            throw new runtime.RequiredError('xRecaptcha','Required parameter requestParameters.xRecaptcha was null or undefined when calling login.');
        }

        if (requestParameters.web3CredentialsAPIModel === null || requestParameters.web3CredentialsAPIModel === undefined) {
            throw new runtime.RequiredError('web3CredentialsAPIModel','Required parameter requestParameters.web3CredentialsAPIModel was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xRecaptcha !== undefined && requestParameters.xRecaptcha !== null) {
            headerParameters['x-recaptcha'] = String(requestParameters.xRecaptcha);
        }

        const response = await this.request({
            path: `/auth/login-web3`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Web3CredentialsAPIModelToJSON(requestParameters.web3CredentialsAPIModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenWithInfoAPIModelFromJSON(jsonValue));
    }

    /**
     * login operation by web3 wallet
     * 
     */
    async login(xRecaptcha: string, web3CredentialsAPIModel: Web3CredentialsAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithInfoAPIModel> {
        const response = await this.loginRaw({ xRecaptcha: xRecaptcha, web3CredentialsAPIModel: web3CredentialsAPIModel }, initOverrides);
        return await response.value();
    }

}
