import { CircularProgress } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CardsSelect } from '@/features/cards/components';
import { useCards } from '@/features/cards/hooks';
import { storeSelectedCard } from '@/features/purchase/actions';
import { makeSelectSelectedCardId } from '@/features/purchase/selectors';

import type { PurchaseCardsSelectProps } from './types';

const selectSelectedCardId = makeSelectSelectedCardId();

const PurchaseCardsSelect: React.FC<PurchaseCardsSelectProps> = ({ 'data-test': dataTest }) => {
  const dispatch = useAppDispatch();
  const cards = useCards();
  const selectedCardId = useAppSelector(selectSelectedCardId);
  const doSelectCard = useCallback(async (cardId: string) => dispatch(storeSelectedCard(cardId)), [dispatch]);
  useEffect(() => {
    if (!selectedCardId && cards.data.data?.length) {
      doSelectCard(cards.data.data[0].cardId);
    }
  }, [cards.data.data, doSelectCard, selectedCardId]);

  if (!selectedCardId) {
    return <CircularProgress color="inherit" size={16} />;
  }

  return <CardsSelect data-test={dataTest} value={selectedCardId} onChange={doSelectCard} />;
};

export default React.memo(PurchaseCardsSelect);
