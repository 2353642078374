import React from 'react';

import { Space } from '@/components/layout';

import type { SubmittableProps } from './types';

const Submittable: React.FC<SubmittableProps> = ({ onSubmit, className, style, children }) =>
  onSubmit ? (
    <form
      style={style}
      className={className}
      onSubmit={(e): void => {
        e.stopPropagation();
        e.preventDefault();
        onSubmit();
      }}
    >
      {children}
    </form>
  ) : (
    <Space size={0} direction="vertical" className={className} style={style}>
      {children}
    </Space>
  );

export default React.memo(Submittable);
