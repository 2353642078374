/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  B2bIntercomLink200ResponseAPIModel,
  B2bRates200ResponseAPIModel,
  B2bTransactions200ResponseAPIModel,
  B2bValidateIban200ResponseAPIModel,
  B2bValidateWallet200ResponseAPIModel,
  B2bValidateWallet400ResponseAPIModel,
  PublicCurrencies200ResponseAPIModel,
  WebappError400001APIModel,
  WebappError400064APIModel,
  WebappError401000APIModel,
  WebappError403007APIModel,
  WebappError405000APIModel,
  WebappError500000APIModel,
  WebappError500001APIModel,
} from '../models';
import {
    B2bIntercomLink200ResponseAPIModelFromJSON,
    B2bIntercomLink200ResponseAPIModelToJSON,
    B2bRates200ResponseAPIModelFromJSON,
    B2bRates200ResponseAPIModelToJSON,
    B2bTransactions200ResponseAPIModelFromJSON,
    B2bTransactions200ResponseAPIModelToJSON,
    B2bValidateIban200ResponseAPIModelFromJSON,
    B2bValidateIban200ResponseAPIModelToJSON,
    B2bValidateWallet200ResponseAPIModelFromJSON,
    B2bValidateWallet200ResponseAPIModelToJSON,
    B2bValidateWallet400ResponseAPIModelFromJSON,
    B2bValidateWallet400ResponseAPIModelToJSON,
    PublicCurrencies200ResponseAPIModelFromJSON,
    PublicCurrencies200ResponseAPIModelToJSON,
    WebappError400001APIModelFromJSON,
    WebappError400001APIModelToJSON,
    WebappError400064APIModelFromJSON,
    WebappError400064APIModelToJSON,
    WebappError401000APIModelFromJSON,
    WebappError401000APIModelToJSON,
    WebappError403007APIModelFromJSON,
    WebappError403007APIModelToJSON,
    WebappError405000APIModelFromJSON,
    WebappError405000APIModelToJSON,
    WebappError500000APIModelFromJSON,
    WebappError500000APIModelToJSON,
    WebappError500001APIModelFromJSON,
    WebappError500001APIModelToJSON,
} from '../models';

export interface B2bRatesRequest {
    isAmountWithoutFee?: boolean;
}

export interface B2bTransactionsRequest {
    merchantTransactionId?: string;
    dateStart?: string;
    dateEnd?: string;
    status?: string;
    limit?: string;
    offset?: string;
}

export interface B2bValidateIbanRequest {
    iban: string;
}

export interface B2bValidateWalletRequest {
    currency: string;
    address: string;
}

/**
 * HelperApi - interface
 * 
 * @export
 * @interface HelperApiInterface
 */
export interface HelperApiInterface {
    /**
     * GET /b2b/intercom/link
     * @summary Get link to intercom
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelperApiInterface
     */
    b2bIntercomLinkRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bIntercomLink200ResponseAPIModel>>;

    /**
     * GET /b2b/intercom/link
     * Get link to intercom
     */
    b2bIntercomLink(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bIntercomLink200ResponseAPIModel>;

    /**
     * GET /b2b/rates
     * @summary Get buy and sell rates for multiple cryptocurrencies at once
     * @param {boolean} [isAmountWithoutFee] Return rates without fee included
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelperApiInterface
     */
    b2bRatesRaw(requestParameters: B2bRatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bRates200ResponseAPIModel>>;

    /**
     * GET /b2b/rates
     * Get buy and sell rates for multiple cryptocurrencies at once
     */
    b2bRates(isAmountWithoutFee?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bRates200ResponseAPIModel>;

    /**
     * GET /b2b/transactions
     * @summary B2B transactions list
     * @param {string} [merchantTransactionId] Merchant transaction id
     * @param {string} [dateStart] Unix Timestamp search from
     * @param {string} [dateEnd] Unix Timestamp search end
     * @param {string} [status] Transaction status
     * @param {string} [limit] Limit of rows max 50, min 5
     * @param {string} [offset] Number of rows to skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelperApiInterface
     */
    b2bTransactionsRaw(requestParameters: B2bTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bTransactions200ResponseAPIModel>>;

    /**
     * GET /b2b/transactions
     * B2B transactions list
     */
    b2bTransactions(merchantTransactionId?: string, dateStart?: string, dateEnd?: string, status?: string, limit?: string, offset?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bTransactions200ResponseAPIModel>;

    /**
     * GET /b2b/validate-iban
     * @summary Validate provided IBAN
     * @param {string} iban IBAN to validate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelperApiInterface
     */
    b2bValidateIbanRaw(requestParameters: B2bValidateIbanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bValidateIban200ResponseAPIModel>>;

    /**
     * GET /b2b/validate-iban
     * Validate provided IBAN
     */
    b2bValidateIban(iban: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bValidateIban200ResponseAPIModel>;

    /**
     * GET /b2b/validate-wallet
     * @summary B2B validate wallet
     * @param {string} currency Crypto currency
     * @param {string} address Wallet address to check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelperApiInterface
     */
    b2bValidateWalletRaw(requestParameters: B2bValidateWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bValidateWallet200ResponseAPIModel>>;

    /**
     * GET /b2b/validate-wallet
     * B2B validate wallet
     */
    b2bValidateWallet(currency: string, address: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bValidateWallet200ResponseAPIModel>;

    /**
     * GET /lib/currencies
     * @summary Get supported currencies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelperApiInterface
     */
    publicCurrenciesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicCurrencies200ResponseAPIModel>>;

    /**
     * GET /lib/currencies
     * Get supported currencies
     */
    publicCurrencies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicCurrencies200ResponseAPIModel>;

}

/**
 * 
 */
export class HelperApi extends runtime.BaseAPI implements HelperApiInterface {

    /**
     * GET /b2b/intercom/link
     * Get link to intercom
     */
    async b2bIntercomLinkRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bIntercomLink200ResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/intercom/link`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bIntercomLink200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/intercom/link
     * Get link to intercom
     */
    async b2bIntercomLink(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bIntercomLink200ResponseAPIModel> {
        const response = await this.b2bIntercomLinkRaw(initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/rates
     * Get buy and sell rates for multiple cryptocurrencies at once
     */
    async b2bRatesRaw(requestParameters: B2bRatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bRates200ResponseAPIModel>> {
        const queryParameters: any = {};

        if (requestParameters.isAmountWithoutFee !== undefined) {
            queryParameters['is_amount_without_fee'] = requestParameters.isAmountWithoutFee;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Sdk-Partner-Token"] = this.configuration.apiKey("Sdk-Partner-Token"); // sdk-partner-token authentication
        }

        const response = await this.request({
            path: `/b2b/rates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bRates200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/rates
     * Get buy and sell rates for multiple cryptocurrencies at once
     */
    async b2bRates(isAmountWithoutFee?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bRates200ResponseAPIModel> {
        const response = await this.b2bRatesRaw({ isAmountWithoutFee: isAmountWithoutFee }, initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/transactions
     * B2B transactions list
     */
    async b2bTransactionsRaw(requestParameters: B2bTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bTransactions200ResponseAPIModel>> {
        const queryParameters: any = {};

        if (requestParameters.merchantTransactionId !== undefined) {
            queryParameters['merchant_transaction_id'] = requestParameters.merchantTransactionId;
        }

        if (requestParameters.dateStart !== undefined) {
            queryParameters['date_start'] = requestParameters.dateStart;
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['date_end'] = requestParameters.dateEnd;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/transactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bTransactions200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/transactions
     * B2B transactions list
     */
    async b2bTransactions(merchantTransactionId?: string, dateStart?: string, dateEnd?: string, status?: string, limit?: string, offset?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bTransactions200ResponseAPIModel> {
        const response = await this.b2bTransactionsRaw({ merchantTransactionId: merchantTransactionId, dateStart: dateStart, dateEnd: dateEnd, status: status, limit: limit, offset: offset }, initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/validate-iban
     * Validate provided IBAN
     */
    async b2bValidateIbanRaw(requestParameters: B2bValidateIbanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bValidateIban200ResponseAPIModel>> {
        if (requestParameters.iban === null || requestParameters.iban === undefined) {
            throw new runtime.RequiredError('iban','Required parameter requestParameters.iban was null or undefined when calling b2bValidateIban.');
        }

        const queryParameters: any = {};

        if (requestParameters.iban !== undefined) {
            queryParameters['iban'] = requestParameters.iban;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Sdk-Partner-Token"] = this.configuration.apiKey("Sdk-Partner-Token"); // sdk-partner-token authentication
        }

        const response = await this.request({
            path: `/b2b/validate-iban`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bValidateIban200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/validate-iban
     * Validate provided IBAN
     */
    async b2bValidateIban(iban: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bValidateIban200ResponseAPIModel> {
        const response = await this.b2bValidateIbanRaw({ iban: iban }, initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/validate-wallet
     * B2B validate wallet
     */
    async b2bValidateWalletRaw(requestParameters: B2bValidateWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bValidateWallet200ResponseAPIModel>> {
        if (requestParameters.currency === null || requestParameters.currency === undefined) {
            throw new runtime.RequiredError('currency','Required parameter requestParameters.currency was null or undefined when calling b2bValidateWallet.');
        }

        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling b2bValidateWallet.');
        }

        const queryParameters: any = {};

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/validate-wallet`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bValidateWallet200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/validate-wallet
     * B2B validate wallet
     */
    async b2bValidateWallet(currency: string, address: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bValidateWallet200ResponseAPIModel> {
        const response = await this.b2bValidateWalletRaw({ currency: currency, address: address }, initOverrides);
        return await response.value();
    }

    /**
     * GET /lib/currencies
     * Get supported currencies
     */
    async publicCurrenciesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicCurrencies200ResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/lib/currencies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicCurrencies200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /lib/currencies
     * Get supported currencies
     */
    async publicCurrencies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicCurrencies200ResponseAPIModel> {
        const response = await this.publicCurrenciesRaw(initOverrides);
        return await response.value();
    }

}
