import { createSelector } from '@reduxjs/toolkit';

import type { AppRootState } from '@/app/store';
import { makeSelectCards } from '@/features/cards/selectors';
import { newCardId } from '@/features/cards/types';
import { makeSelectRequest } from '@/features/global/selectors';
import { NAMESPACE, PurchaseLimits } from '@/features/purchase/types';
import { LoadingStateWithDirty, mapStoredState, storedDirtyData } from '@/utils/model';

export const makeSelectRequestedPurchase = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE],
    (state) => state.requested,
  );

export const makeSelectSelectedCardId = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE],
    (state) => state.selectedCardId,
  );

export const makeSelectSelectedCard = () =>
  createSelector(makeSelectCards(), makeSelectSelectedCardId(), (cards, id) =>
    id && id !== newCardId ? mapStoredState(cards, (state) => state.find(({ cardId }) => cardId === id)) : undefined,
  );

export const makeSelectPurchase = () =>
  createSelector(
    makeSelectRequest(),
    (state: AppRootState) => state[NAMESPACE].purchase,
    ({ address, merchantTx }, purchase) => ({ address, merchantTx, ...purchase }),
  );

export const makeSelectPurchaseKey = () => createSelector(makeSelectPurchase(), (state) => state.purchaseKey);

export const makeSelectRatePurchaseKey = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE],
    (state) => state.rate.purchaseKey,
  );
export const makeSelectRateDirtiness = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE],
    makeSelectPurchaseKey(),
    makeSelectRatePurchaseKey(),
    (state, purchaseKey, ratesKey) => !!state.rate.value.isDirty || ratesKey !== purchaseKey,
  );

export const makeSelectRates = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE],
    makeSelectRateDirtiness(),
    (state, isDirty) => ({ ...state.rate.value, isDirty }),
  );

export const makeSelectRatePending = (purchaseKey: string) =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE],
    (state) => state.pendingRates.includes(purchaseKey),
  );

export const makeSelectCardLimits = (pair: string) =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].limits,
    (limits): LoadingStateWithDirty<PurchaseLimits> => limits[pair] || storedDirtyData,
  );
export const makeSelectCardLimitsDirtiness = (pair: string) =>
  createSelector(makeSelectCardLimits(pair), (state) => state.isDirty);
