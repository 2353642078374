import React from 'react';

import { FormattedMessage } from '@/components';
import { MercuryoKYCStatusAPIModel } from '@/generated/api/ncps/api';
import { I18nFeatureMock } from '@/generated/i18n/i18n';

import type { KYCStatusViewProps } from './types';

const KYCStatusView: React.FC<KYCStatusViewProps> = ({ 'data-test': dataTest, value }) => {
  switch (value) {
    case MercuryoKYCStatusAPIModel.complete:
      return <FormattedMessage data-test={dataTest} id={I18nFeatureMock.COMPONENTS_KYC_STATUSES_COMPLETE} />;
    case MercuryoKYCStatusAPIModel.incomplete:
      return <FormattedMessage data-test={dataTest} id={I18nFeatureMock.COMPONENTS_KYC_STATUSES_INCOMPLETE} />;
    case MercuryoKYCStatusAPIModel.failed:
      return <FormattedMessage data-test={dataTest} id={I18nFeatureMock.COMPONENTS_KYC_STATUSES_FAILED} />;
    case MercuryoKYCStatusAPIModel.under_review:
      return <FormattedMessage data-test={dataTest} id={I18nFeatureMock.COMPONENTS_KYC_STATUSES_UNDERREVIEW} />;
    default:
      return null;
  }
};

export default React.memo(KYCStatusView);
