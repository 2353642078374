import { createReducer } from '@reduxjs/toolkit';

import { storeCallbackStatus } from '@/features/callback/actions';

import type { CallbackState } from './types';

const initialState: CallbackState = {
  status: 'processing',
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(storeCallbackStatus, (state, { payload }) => ({
    ...state,
    status: payload,
  }));
});

export default reducer;
