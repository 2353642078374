import { useCallback, useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { fetchRates } from '@/features/purchase/actions';
import { makeSelectPurchaseKey, makeSelectRates, makeSelectRatePending } from '@/features/purchase/selectors';
import { useLoadableData } from '@/hooks';

const selectRates = makeSelectRates();
const selectPurchaseKey = makeSelectPurchaseKey();

const useRates = () => {
  const purchaseKey = useAppSelector(selectPurchaseKey);
  const selectPending = useMemo(() => makeSelectRatePending(purchaseKey), [purchaseKey]);
  const fetch = useCallback((force: boolean) => fetchRates({ force, purchaseKey }), [purchaseKey]);
  return useLoadableData(fetch, selectRates, selectPending);
};

export default useRates;
