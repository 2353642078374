import { styled } from '@mui/material';
import React, { PropsWithChildren } from 'react';

type StylesProps = { size: number };

// FIXME: should use: 1. ltr from theme, 2. spacing from theme
const HorizontalSpace = styled('span')<StylesProps>`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    ${({ size }: StylesProps): string => `margin-right: ${size * 8}px`};
    margin-left: 0;
  }
`;

const VerticalSpace = styled('span')<StylesProps>`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: ${({ size }: StylesProps): string => `${size * 8}px`};
  }
`;

export interface SpaceProps extends PropsWithChildren<React.HTMLAttributes<HTMLElement>> {
  ref?: React.Ref<HTMLElement>;
  className?: string;
  style?: React.CSSProperties;
  direction?: 'horizontal' | 'vertical';
  size?: number;
  wrapItems?: boolean;
}

const Space: React.FC<SpaceProps> = React.forwardRef(
  ({ className, style, direction, size = 1, children, wrapItems = false, ...props }, ref: React.Ref<HTMLElement>) => {
    if (children === null || children === undefined) {
      return null;
    }

    return direction === 'vertical' ? (
      <VerticalSpace ref={ref} size={size} style={style} className={className} {...props}>
        {wrapItems
          ? React.Children.map(children, (child, index) =>
              // prettier-ignore
              <span
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              style={{}}
            >
              {child}
            </span>,
            )
          : children}
      </VerticalSpace>
    ) : (
      <HorizontalSpace ref={ref} size={size} style={style} className={className} {...props}>
        {wrapItems
          ? React.Children.map(children, (child, index) =>
              // prettier-ignore
              <span
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              style={{}}
            >
              {child}
            </span>,
            )
          : children}
      </HorizontalSpace>
    );
  },
);

export default React.memo(Space);
