import type { B2bUserData200ResponseDataAPIModel } from '@/generated/api/mercuryo/api';
import type { InitStatus, LoadingStateWithDirty } from '@/utils/model';

export const NAMESPACE = 'auth';

export enum AuthTokenStatus {
  EMPTY = 'EMPTY',
  FORBIDDEN = 'FORBIDDEN',
  AUTHORIZED = 'AUTHORIZED',
}

export interface User extends B2bUserData200ResponseDataAPIModel {}

export interface AuthState {
  tokenStatus: AuthTokenStatus;
  initStatus: InitStatus;
  token: string;
  user: LoadingStateWithDirty<User>;
}
