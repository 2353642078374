import type { LoadingStateWithDirty } from '@/utils/model';

export const NAMESPACE = 'cards';
export const newCardId = 'new';

export interface SavedCard {
  cardId: string;
  maskedNumber: string;
}

export interface NewCard {
  expiration: {
    year: string;
    month: string;
  };
  holder: string;
  number: string;
}

export interface CardsState {
  cards: LoadingStateWithDirty<SavedCard[]>;
}
