import { Currency } from '@/features/dictionary/types';
import {
  HelperApi,
  PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel,
} from '@/generated/api/mercuryo/api';
import { mercuryoConfigurationFactory } from '@/infrastructure/security/auth.provider';
import { parseMercuryoCall } from '@/utils/api';
import { notEmpty } from '@/utils/ts';

const helperApi = new HelperApi(mercuryoConfigurationFactory('auth'));

const parseCurrency = (
  code: string,
  { fullname, totalDigits, displayDigits }: PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel,
): Currency | null =>
  totalDigits && displayDigits
    ? {
        code,
        name: fullname,
        digitsToDisplay: displayDigits,
        digitsTotal: totalDigits,
      }
    : null;

export const queryCurrencies = async () =>
  Object.entries((await parseMercuryoCall(helperApi.publicCurrencies())).config?.displayOptions || {})
    .map(([k, v]) => parseCurrency(k, v))
    .filter(notEmpty);
