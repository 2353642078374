import { createAction } from '@reduxjs/toolkit';

import { createAppAsyncThunk } from '@/app/actions';

import { FeatureFlags, NAMESPACE } from './types';

export const storeFeatureFlags = createAction<{ value: FeatureFlags[] }>(`${NAMESPACE}/storeFeatureFlags`);

export const initialize = createAppAsyncThunk(`${NAMESPACE}/initialize`, async (_, { dispatch }) => {
  await dispatch(storeFeatureFlags({ value: [] }));
});
