import React from 'react';

import { MoneyAmountValue } from '@/features/dictionary/components';
import { withRatesError, withRatesLoading } from '@/features/purchase/components/RatesSummary/hocs';

import RatesUnavailable from '../RatesUnavailable';

import type { RatesSubtotalProps } from './types';

const RatesSubtotal: React.FC<RatesSubtotalProps> = ({ 'data-test': dataTest, rates }) =>
  rates?.data?.price.subtotal ? (
    <MoneyAmountValue
      value={rates.data.price.subtotal.amount}
      currency={rates.data.price.subtotal.currency}
      withCurrency
      data-test={dataTest}
    />
  ) : (
    <RatesUnavailable data-test={dataTest} />
  );

export default React.memo(withRatesLoading(withRatesError(RatesSubtotal)));
