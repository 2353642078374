import { createSelector } from '@reduxjs/toolkit';

import type { AppRootState } from '@/app/store';
import { NAMESPACE } from '@/features/cards/types';

export const makeSelectCards = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE],
    (state) => state.cards,
  );
