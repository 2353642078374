import { useMemo } from 'react';

import { fetchCurrencies } from '@/features/dictionary/actions';
import { makeSelectCurrency } from '@/features/dictionary/selectors';
import { useLoadableData } from '@/hooks';

const fetch = (force: boolean) => fetchCurrencies({ force });

export default function useCurrency(code: string) {
  const dataSelector = useMemo(() => makeSelectCurrency(code), [code]);
  return useLoadableData(fetch, dataSelector);
}
