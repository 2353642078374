import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/app/hooks';
import { initializeFromURL } from '@/features/global/actions';
import { GlobalBackdrop } from '@/features/global/components';
import {
  makeSelectPending,
  makeSelectRequestInitialized,
  makeSelectRequestURLProcessed,
} from '@/features/global/selectors';
import { useFetching } from '@/hooks';

const selectInitialization = makeSelectRequestInitialized();
const selectIsURLProcessed = makeSelectRequestURLProcessed();
const selectURLProcessPending = makeSelectPending(initializeFromURL);

const withGlobalInitialization =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props: P) => {
    const dispatch = useAppDispatch();
    const isInitialized = useSelector(selectInitialization);
    const isURLProcessed = useSelector(selectIsURLProcessed);
    const isURLProcessing = useSelector(selectURLProcessPending);
    const initFromURL = useCallback(async () => dispatch(initializeFromURL()), [dispatch]);
    useFetching(initFromURL, !isURLProcessed, isURLProcessing);
    return isInitialized ? <Component {...props} /> : <GlobalBackdrop isLoading />;
  };

export default withGlobalInitialization;
