import { Button, Stack } from '@mui/material';
import isFunction from 'lodash/isFunction';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { SecondaryButton } from '@/components/input/SecondaryButton';
import FormattedMessage from '@/components/output/FormattedMessage';
import NotificationMessage from '@/components/output/NotificationMessage';
import { I18nComponents } from '@/generated/i18n/i18n';

import type { DataFetchErrorProps } from './types';

const DataFetchError: React.FC<DataFetchErrorProps> = ({
  'data-test': dataTest,
  back,
  refresh,
  title,
  description,
  extra,
}) => {
  const navigate = useNavigate();
  const onBack = useCallback(() => (isFunction(back) ? back() : navigate(-1)), [back, navigate]);
  return (
    <NotificationMessage title={title || <FormattedMessage id={I18nComponents.DATA_FETCH_TITLE} />} severity="error">
      <Stack direction="column">
        {description || <FormattedMessage id={I18nComponents.DATA_FETCH_DESCRIPTION} />}
        {refresh && (
          <Button data-test={dataTest && `${dataTest}-refresh`} onClick={refresh}>
            <FormattedMessage id={I18nComponents.DATA_FETCH_REFRESH} />
          </Button>
        )}
        {back && (
          <SecondaryButton
            data-test={dataTest && `${dataTest}-back`}
            onClick={onBack}
            title={<FormattedMessage id={I18nComponents.DATA_FETCH_BACK} tagName="span" />}
          />
        )}
        {extra}
      </Stack>
    </NotificationMessage>
  );
};

export default React.memo(DataFetchError);
