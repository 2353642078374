import { createAction } from '@reduxjs/toolkit';

import { createAppAsyncThunk } from '@/app/actions';

import { queryCards } from './api';
import { makeSelectCards } from './selectors';
import { NAMESPACE } from './types';

import type { SavedCard } from './types';

export const markCardsDirty = createAction<SavedCard[]>(`${NAMESPACE}/storeCards`);

export const fetchCards = createAppAsyncThunk(
  `${NAMESPACE}/fetchCards`,
  async ({ force }: { force?: boolean }, { getState, abort }) => {
    const saved = makeSelectCards()(getState());
    if (!force && !saved.isDirty) {
      abort('cached');
      throw new Error(); // to make ts happier
    }
    return queryCards();
  },
);
