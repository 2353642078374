export type MercuryQueryParams =
  | 'lang'
  | 'token'
  | 'buy-network'
  | 'buy-amount'
  | 'buy-currency'
  | 'fiat-currency'
  | 'tx-id'
  | 'address'
  | 'mock'
  | 'messaging-init';

export interface MercuryoCommandInit {
  type: 'init';
  data: {
    token: string;
    buy: { amount: string; currency: string, network?: string };
    fiat: { currency: string };
    txId: string;
    destination: string;
    locale?: string;
    isMock?: boolean;
  };
}

export interface MercuryoCommandUpdateTx {
  type: 'update-tx';
  data: {
    txId: string;
  };
}

export interface MercuryoCallbackReadyToInit {
  type: 'ready-to-init';
}

export interface MercuryoCallbackLoaded {
  type: 'loaded';
}

export interface MercuryoCallbackUpdateTx {
  type: 'update-tx-req';
}

export interface MercuryoCallbackSucceeded {
  type: 'payment-succeeded';
  data?: {
    txId: string;
    address: string;
    amount: string;
  };
}

export interface MercuryoCallbackFailed {
  type: 'payment-failed';
  data?: {
    retry?: boolean;
  };
}

export type MercuryoCommand = MercuryoCommandInit | MercuryoCommandUpdateTx;
export type MercuryoCallback =
  | MercuryoCallbackReadyToInit
  | MercuryoCallbackLoaded
  | MercuryoCallbackUpdateTx
  | MercuryoCallbackSucceeded
  | MercuryoCallbackFailed;

// That is a simple guard for safely adding new command type. There is no way to "get" all command types automatically,
// but we can "fail" the compilation after a new command type add, to notice the type should be added to the commandTypes.
type PossibleMercuryoCommandTypesGuard = Exclude<
  MercuryoCommand['type'],
  MercuryoCommandInit['type'] | MercuryoCommandUpdateTx['type']
> extends never
  ? MercuryoCommand['type']
  : never;

const commandTypes: PossibleMercuryoCommandTypesGuard[] = ['init', 'update-tx'];
export const isMercuryoCommand = (message: unknown): message is MercuryoCommand =>
  commandTypes.includes((message as MercuryoCommand)?.type);

export const NAMESPACE = 'intercom';

export interface IntercomState {}
