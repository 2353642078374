import moment from 'moment';
import ms from 'ms';
import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import useStateMountSafe from '@/hooks/useStateMountSafe';
import useSubmitting from '@/hooks/useSubmitting';
import useTimer from '@/hooks/useTimer';
import type { Func } from '@/utils/ts';

export interface UseIFrameLoading {
  markLoaded: () => void;
  withMarkLoading: <V extends unknown[], R>(func: Func<V, R>) => Func<V, R>;
  state: 'loading' | 'loaded' | 'timeout';
}

export interface UseIFrameLoadingParams {
  loaded?: boolean;
  timeout?: string; // ms
}

export default function useIFrameLoading({
  timeout,
  loaded: initLoaded,
}: UseIFrameLoadingParams = {}): UseIFrameLoading {
  const [loading, , , markLoading, markLoaded] = useSubmitting(!initLoaded);
  const [reloadingReqId, setReloadingReqId] = useStateMountSafe<string | undefined>();
  const tillTime = useMemo(
    () =>
      reloadingReqId || loading
        ? moment()
            .add(ms(timeout || '15s'), 'ms')
            .toDate()
        : undefined,
    [reloadingReqId, loading, timeout],
  );
  const [expiresIn] = useTimer(tillTime);
  return useMemo(
    () => ({
      markLoaded: () => {
        setReloadingReqId(undefined);
        return markLoaded();
      },
      withMarkLoading:
        <V extends unknown[], R>(func: Func<V, R>) =>
        async (...params: V) => {
          markLoading();
          setReloadingReqId(uuid());
          return func(...params);
        },
      // eslint-disable-next-line no-nested-ternary
      state: !!tillTime && !expiresIn ? 'timeout' : loading ? 'loading' : 'loaded',
    }),
    [expiresIn, loading, markLoaded, markLoading, setReloadingReqId, tillTime],
  );
}
