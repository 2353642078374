/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  B2bRefreshToken200ResponseAPIModel,
  B2bUserCardLimits200ResponseAPIModel,
  B2bUserCardLimitsUnverified200ResponseAPIModel,
  B2bUserCards200ResponseAPIModel,
  B2bUserData200ResponseAPIModel,
  B2bUserGetKycDocuments200ResponseAPIModel,
  B2bUserIbanLimitsBuy200ResponseAPIModel,
  B2bUserKycAccessToken200ResponseAPIModel,
  B2bUserKycShareToken200ResponseAPIModel,
  B2bUserKycShareTokenRequestAPIModel,
  B2bUserSendKycDocuments200ResponseAPIModel,
  B2bUserSendKycDocumentsRequestAPIModel,
  B2bUserSetPhone200ResponseAPIModel,
  B2bUserSetPhoneRequestAPIModel,
  B2bUserSignIn200ResponseAPIModel,
  B2bUserSignInRequestAPIModel,
  B2bUserSignUpRequestAPIModel,
  WebappError400001APIModel,
  WebappError400037APIModel,
  WebappError400064APIModel,
  WebappError401000APIModel,
  WebappError403007APIModel,
  WebappError403023APIModel,
  WebappError403030APIModel,
  WebappError405000APIModel,
  WebappError500001APIModel,
} from '../models';
import {
    B2bRefreshToken200ResponseAPIModelFromJSON,
    B2bRefreshToken200ResponseAPIModelToJSON,
    B2bUserCardLimits200ResponseAPIModelFromJSON,
    B2bUserCardLimits200ResponseAPIModelToJSON,
    B2bUserCardLimitsUnverified200ResponseAPIModelFromJSON,
    B2bUserCardLimitsUnverified200ResponseAPIModelToJSON,
    B2bUserCards200ResponseAPIModelFromJSON,
    B2bUserCards200ResponseAPIModelToJSON,
    B2bUserData200ResponseAPIModelFromJSON,
    B2bUserData200ResponseAPIModelToJSON,
    B2bUserGetKycDocuments200ResponseAPIModelFromJSON,
    B2bUserGetKycDocuments200ResponseAPIModelToJSON,
    B2bUserIbanLimitsBuy200ResponseAPIModelFromJSON,
    B2bUserIbanLimitsBuy200ResponseAPIModelToJSON,
    B2bUserKycAccessToken200ResponseAPIModelFromJSON,
    B2bUserKycAccessToken200ResponseAPIModelToJSON,
    B2bUserKycShareToken200ResponseAPIModelFromJSON,
    B2bUserKycShareToken200ResponseAPIModelToJSON,
    B2bUserKycShareTokenRequestAPIModelFromJSON,
    B2bUserKycShareTokenRequestAPIModelToJSON,
    B2bUserSendKycDocuments200ResponseAPIModelFromJSON,
    B2bUserSendKycDocuments200ResponseAPIModelToJSON,
    B2bUserSendKycDocumentsRequestAPIModelFromJSON,
    B2bUserSendKycDocumentsRequestAPIModelToJSON,
    B2bUserSetPhone200ResponseAPIModelFromJSON,
    B2bUserSetPhone200ResponseAPIModelToJSON,
    B2bUserSetPhoneRequestAPIModelFromJSON,
    B2bUserSetPhoneRequestAPIModelToJSON,
    B2bUserSignIn200ResponseAPIModelFromJSON,
    B2bUserSignIn200ResponseAPIModelToJSON,
    B2bUserSignInRequestAPIModelFromJSON,
    B2bUserSignInRequestAPIModelToJSON,
    B2bUserSignUpRequestAPIModelFromJSON,
    B2bUserSignUpRequestAPIModelToJSON,
    WebappError400001APIModelFromJSON,
    WebappError400001APIModelToJSON,
    WebappError400037APIModelFromJSON,
    WebappError400037APIModelToJSON,
    WebappError400064APIModelFromJSON,
    WebappError400064APIModelToJSON,
    WebappError401000APIModelFromJSON,
    WebappError401000APIModelToJSON,
    WebappError403007APIModelFromJSON,
    WebappError403007APIModelToJSON,
    WebappError403023APIModelFromJSON,
    WebappError403023APIModelToJSON,
    WebappError403030APIModelFromJSON,
    WebappError403030APIModelToJSON,
    WebappError405000APIModelFromJSON,
    WebappError405000APIModelToJSON,
    WebappError500001APIModelFromJSON,
    WebappError500001APIModelToJSON,
} from '../models';

export interface B2bUserCardLimitsRequest {
    from: string;
    to: string;
    type: B2bUserCardLimitsTypeEnum;
}

export interface B2bUserCardLimitsUnverifiedRequest {
    type: B2bUserCardLimitsUnverifiedTypeEnum;
}

export interface B2bUserIbanLimitsBuyRequest {
    cryptoCurrency: string;
    fiatCurrency: string;
    paymentMethod: B2bUserIbanLimitsBuyPaymentMethodEnum;
}

export interface B2bUserIbanLimitsSellRequest {
    cryptoCurrency: string;
    fiatCurrency: string;
}

export interface B2bUserKycAccessTokenRequest {
    kycLevel?: B2bUserKycAccessTokenKycLevelEnum;
    countryCode?: string;
}

export interface B2bUserKycShareTokenRequest {
    b2bUserKycShareTokenRequestAPIModel: B2bUserKycShareTokenRequestAPIModel;
}

export interface B2bUserSendKycDocumentsRequest {
    b2bUserSendKycDocumentsRequestAPIModel: B2bUserSendKycDocumentsRequestAPIModel;
}

export interface B2bUserSetPhoneRequest {
    b2bUserSetPhoneRequestAPIModel: B2bUserSetPhoneRequestAPIModel;
}

export interface B2bUserSignInRequest {
    b2bUserSignInRequestAPIModel: B2bUserSignInRequestAPIModel;
}

export interface B2bUserSignUpRequest {
    b2bUserSignUpRequestAPIModel: B2bUserSignUpRequestAPIModel;
}

/**
 * UserApi - interface
 * 
 * @export
 * @interface UserApiInterface
 */
export interface UserApiInterface {
    /**
     * GET /b2b/user/refresh-token
     * @summary Refresh b2b-bearer-token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    b2bRefreshTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bRefreshToken200ResponseAPIModel>>;

    /**
     * GET /b2b/user/refresh-token
     * Refresh b2b-bearer-token
     */
    b2bRefreshToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bRefreshToken200ResponseAPIModel>;

    /**
     * GET /b2b/user/card-limits
     * @summary Get card limits
     * @param {string} from Currency to convert from
     * @param {string} to Currency to convert to
     * @param {'buy' | 'sell'} type Type of convert
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    b2bUserCardLimitsRaw(requestParameters: B2bUserCardLimitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserCardLimits200ResponseAPIModel>>;

    /**
     * GET /b2b/user/card-limits
     * Get card limits
     */
    b2bUserCardLimits(from: string, to: string, type: B2bUserCardLimitsTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserCardLimits200ResponseAPIModel>;

    /**
     * GET /b2b/user/card-limits-unverified
     * @summary Get total and remaining card limits for unverified user
     * @param {'buy'} type Type of convert. Sell is not available for unverified user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    b2bUserCardLimitsUnverifiedRaw(requestParameters: B2bUserCardLimitsUnverifiedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserCardLimitsUnverified200ResponseAPIModel>>;

    /**
     * GET /b2b/user/card-limits-unverified
     * Get total and remaining card limits for unverified user
     */
    b2bUserCardLimitsUnverified(type: B2bUserCardLimitsUnverifiedTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserCardLimitsUnverified200ResponseAPIModel>;

    /**
     * GET /b2b/user/cards
     * @summary Get saved cards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    b2bUserCardsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserCards200ResponseAPIModel>>;

    /**
     * GET /b2b/user/cards
     * Get saved cards
     */
    b2bUserCards(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserCards200ResponseAPIModel>;

    /**
     * GET /b2b/user/data
     * @summary B2B user data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    b2bUserDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserData200ResponseAPIModel>>;

    /**
     * GET /b2b/user/data
     * B2B user data
     */
    b2bUserData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserData200ResponseAPIModel>;

    /**
     * GET /b2b/user/kyc-documents
     * @summary Get user’s previously uploaded KYC documents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    b2bUserGetKycDocumentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserGetKycDocuments200ResponseAPIModel>>;

    /**
     * GET /b2b/user/kyc-documents
     * Get user’s previously uploaded KYC documents
     */
    b2bUserGetKycDocuments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserGetKycDocuments200ResponseAPIModel>;

    /**
     * GET /b2b/user/iban-limits/buy
     * @summary Get IBAN limits for buy operations
     * @param {string} cryptoCurrency Crypto currency to buy
     * @param {string} fiatCurrency Fiat currency to buy with
     * @param {'iban_exchange' | 'iban_invoice'} paymentMethod Selected payment method
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    b2bUserIbanLimitsBuyRaw(requestParameters: B2bUserIbanLimitsBuyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserIbanLimitsBuy200ResponseAPIModel>>;

    /**
     * GET /b2b/user/iban-limits/buy
     * Get IBAN limits for buy operations
     */
    b2bUserIbanLimitsBuy(cryptoCurrency: string, fiatCurrency: string, paymentMethod: B2bUserIbanLimitsBuyPaymentMethodEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserIbanLimitsBuy200ResponseAPIModel>;

    /**
     * GET /b2b/user/iban-limits/sell
     * @summary Get IBAN limits for sell operations
     * @param {string} cryptoCurrency Crypto currency to sell
     * @param {string} fiatCurrency Fiat currency to sell to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    b2bUserIbanLimitsSellRaw(requestParameters: B2bUserIbanLimitsSellRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserIbanLimitsBuy200ResponseAPIModel>>;

    /**
     * GET /b2b/user/iban-limits/sell
     * Get IBAN limits for sell operations
     */
    b2bUserIbanLimitsSell(cryptoCurrency: string, fiatCurrency: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserIbanLimitsBuy200ResponseAPIModel>;

    /**
     * GET /b2b/user/kyc-access-token
     * @summary Get KYC access token for iframe
     * @param {1 | 2} [kycLevel] Level of kyc
     * @param {string} [countryCode] Country of user. We will use this country if we don\&#39;t already know the user\&#39;s country.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    b2bUserKycAccessTokenRaw(requestParameters: B2bUserKycAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserKycAccessToken200ResponseAPIModel>>;

    /**
     * GET /b2b/user/kyc-access-token
     * Get KYC access token for iframe
     */
    b2bUserKycAccessToken(kycLevel?: B2bUserKycAccessTokenKycLevelEnum, countryCode?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserKycAccessToken200ResponseAPIModel>;

    /**
     * POST /b2b/user/kyc-share-token
     * @summary Send share-token for KYC
     * @param {B2bUserKycShareTokenRequestAPIModel} b2bUserKycShareTokenRequestAPIModel JSON Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    b2bUserKycShareTokenRaw(requestParameters: B2bUserKycShareTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserKycShareToken200ResponseAPIModel>>;

    /**
     * POST /b2b/user/kyc-share-token
     * Send share-token for KYC
     */
    b2bUserKycShareToken(b2bUserKycShareTokenRequestAPIModel: B2bUserKycShareTokenRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserKycShareToken200ResponseAPIModel>;

    /**
     * POST /b2b/user/kyc-documents
     * @summary Send user’s KYC documents
     * @param {B2bUserSendKycDocumentsRequestAPIModel} b2bUserSendKycDocumentsRequestAPIModel JSON Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    b2bUserSendKycDocumentsRaw(requestParameters: B2bUserSendKycDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserSendKycDocuments200ResponseAPIModel>>;

    /**
     * POST /b2b/user/kyc-documents
     * Send user’s KYC documents
     */
    b2bUserSendKycDocuments(b2bUserSendKycDocumentsRequestAPIModel: B2bUserSendKycDocumentsRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserSendKycDocuments200ResponseAPIModel>;

    /**
     * POST /b2b/user/set-phone
     * @summary Set user phone
     * @param {B2bUserSetPhoneRequestAPIModel} b2bUserSetPhoneRequestAPIModel New user phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    b2bUserSetPhoneRaw(requestParameters: B2bUserSetPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserSetPhone200ResponseAPIModel>>;

    /**
     * POST /b2b/user/set-phone
     * Set user phone
     */
    b2bUserSetPhone(b2bUserSetPhoneRequestAPIModel: B2bUserSetPhoneRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserSetPhone200ResponseAPIModel>;

    /**
     * POST /b2b/user/sign-in
     * @summary Sign-in user
     * @param {B2bUserSignInRequestAPIModel} b2bUserSignInRequestAPIModel JSON Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    b2bUserSignInRaw(requestParameters: B2bUserSignInRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserSignIn200ResponseAPIModel>>;

    /**
     * POST /b2b/user/sign-in
     * Sign-in user
     */
    b2bUserSignIn(b2bUserSignInRequestAPIModel: B2bUserSignInRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserSignIn200ResponseAPIModel>;

    /**
     * POST /b2b/user/sign-up
     * @summary Sign-up user
     * @param {B2bUserSignUpRequestAPIModel} b2bUserSignUpRequestAPIModel JSON Body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    b2bUserSignUpRaw(requestParameters: B2bUserSignUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserSignIn200ResponseAPIModel>>;

    /**
     * POST /b2b/user/sign-up
     * Sign-up user
     */
    b2bUserSignUp(b2bUserSignUpRequestAPIModel: B2bUserSignUpRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserSignIn200ResponseAPIModel>;

}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI implements UserApiInterface {

    /**
     * GET /b2b/user/refresh-token
     * Refresh b2b-bearer-token
     */
    async b2bRefreshTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bRefreshToken200ResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/user/refresh-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bRefreshToken200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/user/refresh-token
     * Refresh b2b-bearer-token
     */
    async b2bRefreshToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bRefreshToken200ResponseAPIModel> {
        const response = await this.b2bRefreshTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/user/card-limits
     * Get card limits
     */
    async b2bUserCardLimitsRaw(requestParameters: B2bUserCardLimitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserCardLimits200ResponseAPIModel>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling b2bUserCardLimits.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling b2bUserCardLimits.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling b2bUserCardLimits.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/user/card-limits`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bUserCardLimits200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/user/card-limits
     * Get card limits
     */
    async b2bUserCardLimits(from: string, to: string, type: B2bUserCardLimitsTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserCardLimits200ResponseAPIModel> {
        const response = await this.b2bUserCardLimitsRaw({ from: from, to: to, type: type }, initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/user/card-limits-unverified
     * Get total and remaining card limits for unverified user
     */
    async b2bUserCardLimitsUnverifiedRaw(requestParameters: B2bUserCardLimitsUnverifiedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserCardLimitsUnverified200ResponseAPIModel>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling b2bUserCardLimitsUnverified.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/user/card-limits-unverified`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bUserCardLimitsUnverified200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/user/card-limits-unverified
     * Get total and remaining card limits for unverified user
     */
    async b2bUserCardLimitsUnverified(type: B2bUserCardLimitsUnverifiedTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserCardLimitsUnverified200ResponseAPIModel> {
        const response = await this.b2bUserCardLimitsUnverifiedRaw({ type: type }, initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/user/cards
     * Get saved cards
     */
    async b2bUserCardsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserCards200ResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/user/cards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bUserCards200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/user/cards
     * Get saved cards
     */
    async b2bUserCards(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserCards200ResponseAPIModel> {
        const response = await this.b2bUserCardsRaw(initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/user/data
     * B2B user data
     */
    async b2bUserDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserData200ResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/user/data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bUserData200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/user/data
     * B2B user data
     */
    async b2bUserData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserData200ResponseAPIModel> {
        const response = await this.b2bUserDataRaw(initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/user/kyc-documents
     * Get user’s previously uploaded KYC documents
     */
    async b2bUserGetKycDocumentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserGetKycDocuments200ResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/user/kyc-documents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bUserGetKycDocuments200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/user/kyc-documents
     * Get user’s previously uploaded KYC documents
     */
    async b2bUserGetKycDocuments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserGetKycDocuments200ResponseAPIModel> {
        const response = await this.b2bUserGetKycDocumentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/user/iban-limits/buy
     * Get IBAN limits for buy operations
     */
    async b2bUserIbanLimitsBuyRaw(requestParameters: B2bUserIbanLimitsBuyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserIbanLimitsBuy200ResponseAPIModel>> {
        if (requestParameters.cryptoCurrency === null || requestParameters.cryptoCurrency === undefined) {
            throw new runtime.RequiredError('cryptoCurrency','Required parameter requestParameters.cryptoCurrency was null or undefined when calling b2bUserIbanLimitsBuy.');
        }

        if (requestParameters.fiatCurrency === null || requestParameters.fiatCurrency === undefined) {
            throw new runtime.RequiredError('fiatCurrency','Required parameter requestParameters.fiatCurrency was null or undefined when calling b2bUserIbanLimitsBuy.');
        }

        if (requestParameters.paymentMethod === null || requestParameters.paymentMethod === undefined) {
            throw new runtime.RequiredError('paymentMethod','Required parameter requestParameters.paymentMethod was null or undefined when calling b2bUserIbanLimitsBuy.');
        }

        const queryParameters: any = {};

        if (requestParameters.cryptoCurrency !== undefined) {
            queryParameters['crypto_currency'] = requestParameters.cryptoCurrency;
        }

        if (requestParameters.fiatCurrency !== undefined) {
            queryParameters['fiat_currency'] = requestParameters.fiatCurrency;
        }

        if (requestParameters.paymentMethod !== undefined) {
            queryParameters['payment_method'] = requestParameters.paymentMethod;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/user/iban-limits/buy`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bUserIbanLimitsBuy200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/user/iban-limits/buy
     * Get IBAN limits for buy operations
     */
    async b2bUserIbanLimitsBuy(cryptoCurrency: string, fiatCurrency: string, paymentMethod: B2bUserIbanLimitsBuyPaymentMethodEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserIbanLimitsBuy200ResponseAPIModel> {
        const response = await this.b2bUserIbanLimitsBuyRaw({ cryptoCurrency: cryptoCurrency, fiatCurrency: fiatCurrency, paymentMethod: paymentMethod }, initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/user/iban-limits/sell
     * Get IBAN limits for sell operations
     */
    async b2bUserIbanLimitsSellRaw(requestParameters: B2bUserIbanLimitsSellRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserIbanLimitsBuy200ResponseAPIModel>> {
        if (requestParameters.cryptoCurrency === null || requestParameters.cryptoCurrency === undefined) {
            throw new runtime.RequiredError('cryptoCurrency','Required parameter requestParameters.cryptoCurrency was null or undefined when calling b2bUserIbanLimitsSell.');
        }

        if (requestParameters.fiatCurrency === null || requestParameters.fiatCurrency === undefined) {
            throw new runtime.RequiredError('fiatCurrency','Required parameter requestParameters.fiatCurrency was null or undefined when calling b2bUserIbanLimitsSell.');
        }

        const queryParameters: any = {};

        if (requestParameters.cryptoCurrency !== undefined) {
            queryParameters['crypto_currency'] = requestParameters.cryptoCurrency;
        }

        if (requestParameters.fiatCurrency !== undefined) {
            queryParameters['fiat_currency'] = requestParameters.fiatCurrency;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/user/iban-limits/sell`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bUserIbanLimitsBuy200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/user/iban-limits/sell
     * Get IBAN limits for sell operations
     */
    async b2bUserIbanLimitsSell(cryptoCurrency: string, fiatCurrency: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserIbanLimitsBuy200ResponseAPIModel> {
        const response = await this.b2bUserIbanLimitsSellRaw({ cryptoCurrency: cryptoCurrency, fiatCurrency: fiatCurrency }, initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/user/kyc-access-token
     * Get KYC access token for iframe
     */
    async b2bUserKycAccessTokenRaw(requestParameters: B2bUserKycAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserKycAccessToken200ResponseAPIModel>> {
        const queryParameters: any = {};

        if (requestParameters.kycLevel !== undefined) {
            queryParameters['kyc_level'] = requestParameters.kycLevel;
        }

        if (requestParameters.countryCode !== undefined) {
            queryParameters['country_code'] = requestParameters.countryCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/user/kyc-access-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bUserKycAccessToken200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/user/kyc-access-token
     * Get KYC access token for iframe
     */
    async b2bUserKycAccessToken(kycLevel?: B2bUserKycAccessTokenKycLevelEnum, countryCode?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserKycAccessToken200ResponseAPIModel> {
        const response = await this.b2bUserKycAccessTokenRaw({ kycLevel: kycLevel, countryCode: countryCode }, initOverrides);
        return await response.value();
    }

    /**
     * POST /b2b/user/kyc-share-token
     * Send share-token for KYC
     */
    async b2bUserKycShareTokenRaw(requestParameters: B2bUserKycShareTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserKycShareToken200ResponseAPIModel>> {
        if (requestParameters.b2bUserKycShareTokenRequestAPIModel === null || requestParameters.b2bUserKycShareTokenRequestAPIModel === undefined) {
            throw new runtime.RequiredError('b2bUserKycShareTokenRequestAPIModel','Required parameter requestParameters.b2bUserKycShareTokenRequestAPIModel was null or undefined when calling b2bUserKycShareToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/user/kyc-share-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: B2bUserKycShareTokenRequestAPIModelToJSON(requestParameters.b2bUserKycShareTokenRequestAPIModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bUserKycShareToken200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * POST /b2b/user/kyc-share-token
     * Send share-token for KYC
     */
    async b2bUserKycShareToken(b2bUserKycShareTokenRequestAPIModel: B2bUserKycShareTokenRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserKycShareToken200ResponseAPIModel> {
        const response = await this.b2bUserKycShareTokenRaw({ b2bUserKycShareTokenRequestAPIModel: b2bUserKycShareTokenRequestAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * POST /b2b/user/kyc-documents
     * Send user’s KYC documents
     */
    async b2bUserSendKycDocumentsRaw(requestParameters: B2bUserSendKycDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserSendKycDocuments200ResponseAPIModel>> {
        if (requestParameters.b2bUserSendKycDocumentsRequestAPIModel === null || requestParameters.b2bUserSendKycDocumentsRequestAPIModel === undefined) {
            throw new runtime.RequiredError('b2bUserSendKycDocumentsRequestAPIModel','Required parameter requestParameters.b2bUserSendKycDocumentsRequestAPIModel was null or undefined when calling b2bUserSendKycDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/user/kyc-documents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: B2bUserSendKycDocumentsRequestAPIModelToJSON(requestParameters.b2bUserSendKycDocumentsRequestAPIModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bUserSendKycDocuments200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * POST /b2b/user/kyc-documents
     * Send user’s KYC documents
     */
    async b2bUserSendKycDocuments(b2bUserSendKycDocumentsRequestAPIModel: B2bUserSendKycDocumentsRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserSendKycDocuments200ResponseAPIModel> {
        const response = await this.b2bUserSendKycDocumentsRaw({ b2bUserSendKycDocumentsRequestAPIModel: b2bUserSendKycDocumentsRequestAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * POST /b2b/user/set-phone
     * Set user phone
     */
    async b2bUserSetPhoneRaw(requestParameters: B2bUserSetPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserSetPhone200ResponseAPIModel>> {
        if (requestParameters.b2bUserSetPhoneRequestAPIModel === null || requestParameters.b2bUserSetPhoneRequestAPIModel === undefined) {
            throw new runtime.RequiredError('b2bUserSetPhoneRequestAPIModel','Required parameter requestParameters.b2bUserSetPhoneRequestAPIModel was null or undefined when calling b2bUserSetPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/user/set-phone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: B2bUserSetPhoneRequestAPIModelToJSON(requestParameters.b2bUserSetPhoneRequestAPIModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bUserSetPhone200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * POST /b2b/user/set-phone
     * Set user phone
     */
    async b2bUserSetPhone(b2bUserSetPhoneRequestAPIModel: B2bUserSetPhoneRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserSetPhone200ResponseAPIModel> {
        const response = await this.b2bUserSetPhoneRaw({ b2bUserSetPhoneRequestAPIModel: b2bUserSetPhoneRequestAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * POST /b2b/user/sign-in
     * Sign-in user
     */
    async b2bUserSignInRaw(requestParameters: B2bUserSignInRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserSignIn200ResponseAPIModel>> {
        if (requestParameters.b2bUserSignInRequestAPIModel === null || requestParameters.b2bUserSignInRequestAPIModel === undefined) {
            throw new runtime.RequiredError('b2bUserSignInRequestAPIModel','Required parameter requestParameters.b2bUserSignInRequestAPIModel was null or undefined when calling b2bUserSignIn.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Sdk-Partner-Token"] = this.configuration.apiKey("Sdk-Partner-Token"); // sdk-partner-token authentication
        }

        const response = await this.request({
            path: `/b2b/user/sign-in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: B2bUserSignInRequestAPIModelToJSON(requestParameters.b2bUserSignInRequestAPIModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bUserSignIn200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * POST /b2b/user/sign-in
     * Sign-in user
     */
    async b2bUserSignIn(b2bUserSignInRequestAPIModel: B2bUserSignInRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserSignIn200ResponseAPIModel> {
        const response = await this.b2bUserSignInRaw({ b2bUserSignInRequestAPIModel: b2bUserSignInRequestAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * POST /b2b/user/sign-up
     * Sign-up user
     */
    async b2bUserSignUpRaw(requestParameters: B2bUserSignUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bUserSignIn200ResponseAPIModel>> {
        if (requestParameters.b2bUserSignUpRequestAPIModel === null || requestParameters.b2bUserSignUpRequestAPIModel === undefined) {
            throw new runtime.RequiredError('b2bUserSignUpRequestAPIModel','Required parameter requestParameters.b2bUserSignUpRequestAPIModel was null or undefined when calling b2bUserSignUp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Sdk-Partner-Token"] = this.configuration.apiKey("Sdk-Partner-Token"); // sdk-partner-token authentication
        }

        const response = await this.request({
            path: `/b2b/user/sign-up`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: B2bUserSignUpRequestAPIModelToJSON(requestParameters.b2bUserSignUpRequestAPIModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bUserSignIn200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * POST /b2b/user/sign-up
     * Sign-up user
     */
    async b2bUserSignUp(b2bUserSignUpRequestAPIModel: B2bUserSignUpRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bUserSignIn200ResponseAPIModel> {
        const response = await this.b2bUserSignUpRaw({ b2bUserSignUpRequestAPIModel: b2bUserSignUpRequestAPIModel }, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const B2bUserCardLimitsTypeEnum = {
    buy: 'buy',
    sell: 'sell'
} as const;
export type B2bUserCardLimitsTypeEnum = typeof B2bUserCardLimitsTypeEnum[keyof typeof B2bUserCardLimitsTypeEnum];
/**
 * @export
 */
export const B2bUserCardLimitsUnverifiedTypeEnum = {
    buy: 'buy'
} as const;
export type B2bUserCardLimitsUnverifiedTypeEnum = typeof B2bUserCardLimitsUnverifiedTypeEnum[keyof typeof B2bUserCardLimitsUnverifiedTypeEnum];
/**
 * @export
 */
export const B2bUserIbanLimitsBuyPaymentMethodEnum = {
    exchange: 'iban_exchange',
    invoice: 'iban_invoice'
} as const;
export type B2bUserIbanLimitsBuyPaymentMethodEnum = typeof B2bUserIbanLimitsBuyPaymentMethodEnum[keyof typeof B2bUserIbanLimitsBuyPaymentMethodEnum];
/**
 * @export
 */
export const B2bUserKycAccessTokenKycLevelEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type B2bUserKycAccessTokenKycLevelEnum = typeof B2bUserKycAccessTokenKycLevelEnum[keyof typeof B2bUserKycAccessTokenKycLevelEnum];
