import React from 'react';

import { BodyDetails, FormattedMessage } from '@/components';
import { I18nFeatureBuyPurchase } from '@/generated/i18n/i18n';
import { useDimensions } from '@/hooks';

import type { AddressBlockProps } from './types';

const AddressBlock: React.FC<AddressBlockProps> = ({ 'data-test': dataTest, asset, address }) => {
  const [ref, dimension] = useDimensions({ liveMeasure: 'resize' });
  return (
    <BodyDetails ref={ref} data-test={dataTest}>
      <FormattedMessage
        id={I18nFeatureBuyPurchase.COMPONENTS_ADDRESS_BLOCK_TEXT}
        values={{
          asset: <strong>{asset}</strong>,
          address: (
            <span
              style={{
                fontWeight: 'bold',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: dimension?.width,
                display: 'inline-block',
                verticalAlign: 'bottom',
              }}
            >
              {address}
            </span>
          ),
        }}
      />
    </BodyDetails>
  );
};

export default React.memo(AddressBlock);
