import { Divider } from '@mui/material';
import React, { useCallback } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { FormattedMessage } from '@/components';
import { withAuthorizationInit } from '@/features/auth/hocs';
import { proceedMockTransaction } from '@/features/mock/actions';
import { Card3dsInput, MockBanner } from '@/features/mock/components';
import { withMockInitialization, withMockSupported, withMockTxData } from '@/features/mock/hocs';
import { I18nFeatureMock } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { MainLayout } from '@/layout';
import type { Value } from '@/utils/model';

const Card3dsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [code, setCode] = useStateMountSafe<Value<string>>({ hasError: false });
  const doAct = useCallback(
    () => dispatch(proceedMockTransaction({ code: code.value || '', delay: '1m' })),
    [code, dispatch],
  );
  return (
    <MainLayout
      header={{
        title: <FormattedMessage id={I18nFeatureMock.PAGES_CARD3DS_TITLE} />,
      }}
      buttons={{
        main: {
          title: <FormattedMessage id={I18nFeatureMock.PAGES_CARD3DS_SUBMIT} />,
          disabled: code.hasError || !code.value,
          submit: false,
          onAct: doAct,
        },
      }}
    >
      <MockBanner data-test="mock-banner" />
      <FormattedMessage id={I18nFeatureMock.PAGES_CARD3DS_DESCRIPTION} />
      <Divider orientation="horizontal" />
      <Card3dsInput data-test="mock-3ds" onChange={setCode} />
    </MainLayout>
  );
};

export default withAuthorizationInit(withMockInitialization(withMockSupported(withMockTxData(Card3dsPage))));
