import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/app/hooks';
import { initialize } from '@/features/feature-toggle/actions';
import { makeSelectIsToggleInitialized } from '@/features/feature-toggle/selectors';
import { useFetching } from '@/hooks';
import { BlankLayout } from '@/layout';
import { InitStatus } from '@/utils/model';

const selectInitialization = makeSelectIsToggleInitialized();

const withFeatureToggle =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props: P) => {
    const dispatch = useAppDispatch();
    const initState = useSelector(selectInitialization);
    const init = useCallback(async () => dispatch(initialize()), [dispatch]);
    useFetching(init, initState === InitStatus.NOT_INITIALIZED);
    return initState === InitStatus.FINISHED ? <Component {...props} /> : <BlankLayout />;
  };

export default withFeatureToggle;
