import moment, { Duration } from 'moment';

import { CommonLoadingState, storedDataError } from '@/utils/model';

export const normalizeDateTime = (
  date: Date,
  opts?: {
    res?: Response;
    delta?: Duration;
  },
): Date => {
  const responseDateString = opts?.res?.headers?.get('date') || opts?.res?.headers?.get('Date');
  if (!responseDateString) {
    return date;
  }
  const diff = moment(new Date()).diff(responseDateString, 's');
  const delta = opts?.delta || moment.duration({ second: 1 });
  return Math.abs(diff) > delta.asSeconds() ? moment(date).add(diff, 's').toDate() : date;
};

export const parseDateTime = (
  date: string,
  opts?: {
    res?: Response;
    delta?: Duration;
  },
): Date => normalizeDateTime(new Date(date), opts);

export class APIRequestError extends Error {
  constructor(status?: number) {
    super(status ? `Unable to retrieve the data by code "${status}"` : 'Unable to retrieve the data');
  }
}

export const parseMercuryoCall = async <R>(promise: Promise<{ data?: R; status?: number }>): Promise<R> => {
  const { data, status } = await promise;
  if (!data) {
    throw new APIRequestError(status);
  }
  return data;
};

export const withAPICall =
  <V extends unknown[], R>(func: (...args: V) => Promise<R>): ((...args: V) => Promise<CommonLoadingState<R>>) =>
  async (...args: V): Promise<CommonLoadingState<R>> => {
    try {
      const data = await func(...args);
      return { data };
    } catch (e) {
      console.error(e);
      return e instanceof Error ? storedDataError(e.message || `${e}`) : storedDataError(`${e}`);
    }
  };
