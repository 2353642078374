import ArrowBackIcon from '@mui/icons-material/KeyboardBackspace';
import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as LogoIcon } from '@/assets/icons/logo.svg';
import { Func } from '@/utils/ts';

const useStyles = makeStyles()(() => ({
  button: {
    cursor: 'pointer',
  },
}));

export enum TitleButtonType {
  LOGO = 'LOGO',
  BACK = 'BACK',
}

export type TitleButtonProp =
  | { type: TitleButtonType; onClick: Func; 'data-test'?: string }
  | React.FC<{ className: string; 'data-test'?: string }>;

export interface TitleButtonProps {
  'data-test'?: string;
  type: TitleButtonProp;
  className?: string;
}

const TitleButton: React.FC<TitleButtonProps> = ({ type, className: extraClassName, 'data-test': dataTest }) => {
  const { cx, classes } = useStyles();
  const className = useMemo(() => cx(classes.button, extraClassName), [classes.button, cx, extraClassName]);
  if (typeof type === 'function') {
    return type({
      className,
      'data-test': dataTest,
    });
  }
  switch (type.type) {
    case TitleButtonType.LOGO:
      return <LogoIcon onClick={type.onClick} className={className} data-test={dataTest} />;
    case TitleButtonType.BACK:
    default:
      return <ArrowBackIcon onClick={type.onClick} className={className} data-test="test" />;
  }
};

export default React.memo(TitleButton);
