/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bUserData200ResponseDataAPIModel
 */
export interface B2bUserData200ResponseDataAPIModel {
    /**
     * User country code
     * @type {string}
     * @memberof B2bUserData200ResponseDataAPIModel
     */
    countryCode?: string;
    /**
     * Email
     * @type {string}
     * @memberof B2bUserData200ResponseDataAPIModel
     */
    email?: string;
    /**
     * Status of email confirmation
     * @type {string}
     * @memberof B2bUserData200ResponseDataAPIModel
     */
    emailStatus?: string;
    /**
     * User first name
     * @type {string}
     * @memberof B2bUserData200ResponseDataAPIModel
     */
    firstName?: string;
    /**
     * Status of KYC1
     * @type {string}
     * @memberof B2bUserData200ResponseDataAPIModel
     */
    kyc1Status?: B2bUserData200ResponseDataAPIModelKyc1StatusEnum;
    /**
     * Status of KYC2
     * @type {string}
     * @memberof B2bUserData200ResponseDataAPIModel
     */
    kyc2Status?: B2bUserData200ResponseDataAPIModelKyc2StatusEnum;
    /**
     * User language code
     * @type {string}
     * @memberof B2bUserData200ResponseDataAPIModel
     */
    languageCode?: string;
    /**
     * User last name
     * @type {string}
     * @memberof B2bUserData200ResponseDataAPIModel
     */
    lastName?: string;
    /**
     * Phone
     * @type {string}
     * @memberof B2bUserData200ResponseDataAPIModel
     */
    phone?: string;
    /**
     * Status of phone confirmation
     * @type {string}
     * @memberof B2bUserData200ResponseDataAPIModel
     */
    phoneStatus?: string;
    /**
     * Status of user activation
     * @type {string}
     * @memberof B2bUserData200ResponseDataAPIModel
     */
    userStatus?: string;
}


/**
 * @export
 */
export const B2bUserData200ResponseDataAPIModelKyc1StatusEnum = {
    complete: 'complete',
    incomplete: 'incomplete',
    failed: 'failed',
    under_review: 'under_review'
} as const;
export type B2bUserData200ResponseDataAPIModelKyc1StatusEnum = typeof B2bUserData200ResponseDataAPIModelKyc1StatusEnum[keyof typeof B2bUserData200ResponseDataAPIModelKyc1StatusEnum];

/**
 * @export
 */
export const B2bUserData200ResponseDataAPIModelKyc2StatusEnum = {
    complete: 'complete',
    incomplete: 'incomplete',
    failed: 'failed',
    under_review: 'under_review'
} as const;
export type B2bUserData200ResponseDataAPIModelKyc2StatusEnum = typeof B2bUserData200ResponseDataAPIModelKyc2StatusEnum[keyof typeof B2bUserData200ResponseDataAPIModelKyc2StatusEnum];


/**
 * Check if a given object implements the B2bUserData200ResponseDataAPIModel interface.
 */
export function instanceOfB2bUserData200ResponseDataAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bUserData200ResponseDataAPIModelFromJSON(json: any): B2bUserData200ResponseDataAPIModel {
    return B2bUserData200ResponseDataAPIModelFromJSONTyped(json, false);
}

export function B2bUserData200ResponseDataAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserData200ResponseDataAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'emailStatus': !exists(json, 'email_status') ? undefined : json['email_status'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'kyc1Status': !exists(json, 'kyc1_status') ? undefined : json['kyc1_status'],
        'kyc2Status': !exists(json, 'kyc2_status') ? undefined : json['kyc2_status'],
        'languageCode': !exists(json, 'language_code') ? undefined : json['language_code'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'phoneStatus': !exists(json, 'phone_status') ? undefined : json['phone_status'],
        'userStatus': !exists(json, 'user_status') ? undefined : json['user_status'],
    };
}

export function B2bUserData200ResponseDataAPIModelToJSON(value?: B2bUserData200ResponseDataAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country_code': value.countryCode,
        'email': value.email,
        'email_status': value.emailStatus,
        'first_name': value.firstName,
        'kyc1_status': value.kyc1Status,
        'kyc2_status': value.kyc2Status,
        'language_code': value.languageCode,
        'last_name': value.lastName,
        'phone': value.phone,
        'phone_status': value.phoneStatus,
        'user_status': value.userStatus,
    };
}

