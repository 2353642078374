import type { I18nMessages } from '@/generated/i18n/i18n';
import type { InitState } from '@/utils/model';

export type Severity = 'success' | 'info' | 'warning' | 'error';

export interface BaseGlobalNotification {
  severity: Severity;
  titleId?: I18nMessages;
  type?: string;
}

export interface GlobalNotificationWithMessageId extends BaseGlobalNotification {
  messageId: I18nMessages;
}

export interface GlobalNotificationWithMessage extends BaseGlobalNotification {
  message: string;
}

export type GlobalNotification = GlobalNotificationWithMessageId | GlobalNotificationWithMessage;
export type GlobalIdentifiedNotification = GlobalNotification & { id: string };

export interface GlobalLoadingState {
  isLoading: boolean;
  messageId?: I18nMessages;
}

export interface WidgetInitialRequest {
  token?: string;
  locale?: string;
  buyCurrency?: string;
  buyAmount?: string;
  buyNetwork?: string;
  fiatCurrency?: string;
  merchantTx?: string;
  address?: string;
  isMockEnabled: boolean;
}

export const NAMESPACE = 'global';

export interface GlobalState {
  initializing: {
    value: Partial<WidgetInitialRequest>;
    isURLProcessed: boolean;
    isWaitingForInitCall: boolean;
  };
  locale: InitState<string>;
  loading: GlobalLoadingState;
  notifications: GlobalIdentifiedNotification[];
  pending: Partial<Record<string, string[]>>;
  vh: number;
}
