import { createAction } from '@reduxjs/toolkit';
import ms from 'ms';

import { createAppAsyncThunk } from '@/app/actions';
import { timeout } from '@/utils/functions';
import { sendMessageToSelf } from '@/utils/messaging';
import { asType } from '@/utils/ts';

import { CallbackStatus, MercuryoCardCommand, MercuryoKYCCommand, NAMESPACE } from './types';

export const storeCallbackStatus = createAction<CallbackStatus>(`${NAMESPACE}/storeStatus`);
const pollTimeout = ms(window.env.KYC_POLL_PERIOD);

export const processKYCCallback = createAppAsyncThunk(
  `${NAMESPACE}/processKYCCallback`,
  async ({ success }: { success: boolean }, { dispatch }) => {
    try {
      sendMessageToSelf(asType<MercuryoKYCCommand>({ type: 'kyc', data: { success } }));
      await timeout(pollTimeout * 3);
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(storeCallbackStatus('error'));
    }
  },
);
export const processCardCallback = createAppAsyncThunk(
  `${NAMESPACE}/processCardCallback`,
  async ({ success }: { success: boolean }, { dispatch }) => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const txId = queryParams.get('merchant_transaction_id') || undefined;
      const msg = queryParams.get('msg') || undefined;
      sendMessageToSelf(asType<MercuryoCardCommand>({ type: 'payment', data: { success, txId, msg } }));
      await timeout(5_000);
    } catch (e) {
      console.error(e);
      dispatch(storeCallbackStatus('error'));
    }
  },
);
