import { createReducer } from '@reduxjs/toolkit';

import {
  markKYCDataDirty,
  markKYCLimitsDirty,
  storeKYCExplicitRequirement,
  storeKYCData,
  storeKYCLimits,
} from '@/features/kyc/actions';
import type { KYCState } from '@/features/kyc/types';
import { storedDirtyData } from '@/utils/model';

const initialState: KYCState = {
  level1: storedDirtyData,
  level2: storedDirtyData,
  limits: storedDirtyData,
  explicitlyRequired: storedDirtyData,
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storeKYCData, (state, { payload: { data } }) => ({
      ...state,
      level1: { ...data, isDirty: false },
    }))
    .addCase(markKYCDataDirty, (state) =>
      !state.level1.isDirty ? { ...state, level1: { ...state.level1, isDirty: true } } : state,
    )
    .addCase(storeKYCLimits, (state, { payload: { data } }) => ({
      ...state,
      limits: { ...data, isDirty: false },
    }))
    .addCase(markKYCLimitsDirty, (state) =>
      !state.limits.isDirty ? { ...state, limits: { ...state.limits, isDirty: true } } : state,
    )
    .addCase(storeKYCExplicitRequirement, (state, { payload }) => ({
      ...state,
      explicitlyRequired: { ...payload, isDirty: false },
    }));
});

export default reducer;
