export interface Value<T> {
  value?: T | undefined;
  hasError: boolean;
}

export const validValue = <T>(value: T | undefined): Value<T> => ({
  value,
  hasError: false,
});

export enum InitStatus {
  NOT_INITIALIZED = 'NOT_INITIALIZED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export interface InitState<T> {
  value: T;
  status: InitStatus;
}

export interface CommonLoadingState<T, E = string> {
  data?: T;
  error?: E;
}

export interface LoadingStateWithDirty<T, E = string> extends CommonLoadingState<T, E> {
  isDirty: boolean;
}

export const storedDirtyData = { isDirty: true };
export const storedDataLoaded = <T, E = string>(data?: T, isDirty?: boolean): LoadingStateWithDirty<T, E> => ({
  data,
  isDirty: isDirty || false,
});
export const storedDataError = <T, E>(error: E, isDirty?: boolean): LoadingStateWithDirty<T, E> => ({
  error,
  isDirty: isDirty || false,
});

export const mapLoadingState = <T, R, E = string>(
  state: CommonLoadingState<T, E>,
  mapper: (data: T) => R,
): CommonLoadingState<R, E> => {
  const { data, ...old } = state;
  return { ...old, data: data !== undefined ? mapper(data) : undefined };
};
export const flatMapLoadingState = <T, R, E = string>(
  state: CommonLoadingState<T, E>,
  mapper: (data: T) => CommonLoadingState<R, E>,
): CommonLoadingState<R, E> => {
  const { data, ...old } = state;
  return { ...old, ...(data !== undefined ? mapper(data) : {}) };
};

export const mapStoredState = <T, R, E = string>(
  state: LoadingStateWithDirty<T, E>,
  mapper: (data: T) => R | undefined,
): LoadingStateWithDirty<R, E> => {
  const { data, ...old } = state;
  return { ...old, data: data ? mapper(data) : undefined };
};
