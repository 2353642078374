/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bBuyMethods200ResponseDataInnerAPIModel
 */
export interface B2bBuyMethods200ResponseDataInnerAPIModel {
    /**
     * Available currencies for payment method
     * @type {Array<string>}
     * @memberof B2bBuyMethods200ResponseDataInnerAPIModel
     */
    currencies?: Array<string>;
    /**
     * Payment method alias
     * @type {string}
     * @memberof B2bBuyMethods200ResponseDataInnerAPIModel
     */
    paymentAlias?: B2bBuyMethods200ResponseDataInnerAPIModelPaymentAliasEnum;
    /**
     * Payment method title
     * @type {string}
     * @memberof B2bBuyMethods200ResponseDataInnerAPIModel
     */
    title?: string;
}


/**
 * @export
 */
export const B2bBuyMethods200ResponseDataInnerAPIModelPaymentAliasEnum = {
    card: 'card',
    iban_exchange: 'iban_exchange',
    iban_invoice: 'iban_invoice'
} as const;
export type B2bBuyMethods200ResponseDataInnerAPIModelPaymentAliasEnum = typeof B2bBuyMethods200ResponseDataInnerAPIModelPaymentAliasEnum[keyof typeof B2bBuyMethods200ResponseDataInnerAPIModelPaymentAliasEnum];


/**
 * Check if a given object implements the B2bBuyMethods200ResponseDataInnerAPIModel interface.
 */
export function instanceOfB2bBuyMethods200ResponseDataInnerAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bBuyMethods200ResponseDataInnerAPIModelFromJSON(json: any): B2bBuyMethods200ResponseDataInnerAPIModel {
    return B2bBuyMethods200ResponseDataInnerAPIModelFromJSONTyped(json, false);
}

export function B2bBuyMethods200ResponseDataInnerAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bBuyMethods200ResponseDataInnerAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currencies': !exists(json, 'currencies') ? undefined : json['currencies'],
        'paymentAlias': !exists(json, 'payment_alias') ? undefined : json['payment_alias'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function B2bBuyMethods200ResponseDataInnerAPIModelToJSON(value?: B2bBuyMethods200ResponseDataInnerAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currencies': value.currencies,
        'payment_alias': value.paymentAlias,
        'title': value.title,
    };
}

