/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bBuy200ResponseDataAPIModel
 */
export interface B2bBuy200ResponseDataAPIModel {
    /**
     * ID for checking transaction status
     * @type {string}
     * @memberof B2bBuy200ResponseDataAPIModel
     */
    merchantTransactionId: string;
    /**
     * Redirect URL to form, to complete buy by Card
     * @type {string}
     * @memberof B2bBuy200ResponseDataAPIModel
     */
    redirectUrl?: string;
}

/**
 * Check if a given object implements the B2bBuy200ResponseDataAPIModel interface.
 */
export function instanceOfB2bBuy200ResponseDataAPIModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "merchantTransactionId" in value;

    return isInstance;
}

export function B2bBuy200ResponseDataAPIModelFromJSON(json: any): B2bBuy200ResponseDataAPIModel {
    return B2bBuy200ResponseDataAPIModelFromJSONTyped(json, false);
}

export function B2bBuy200ResponseDataAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bBuy200ResponseDataAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'merchantTransactionId': json['merchant_transaction_id'],
        'redirectUrl': !exists(json, 'redirect_url') ? undefined : json['redirect_url'],
    };
}

export function B2bBuy200ResponseDataAPIModelToJSON(value?: B2bBuy200ResponseDataAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'merchant_transaction_id': value.merchantTransactionId,
        'redirect_url': value.redirectUrl,
    };
}

