/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bBuyRequestAPIModel
 */
export interface B2bBuyRequestAPIModel {
    /**
     * Buy token returned by [`/b2b/fiat/buy-rates`](#operation/b2bBuyRates) endpoint
     * @type {string}
     * @memberof B2bBuyRequestAPIModel
     */
    trxToken: string;
    /**
     * The address to which the crypto should be transferred
     * @type {string}
     * @memberof B2bBuyRequestAPIModel
     */
    address: string;
    /**
     * Custom ID for checking transaction status. If empty, it will be generated
     * @type {string}
     * @memberof B2bBuyRequestAPIModel
     */
    merchantTransactionId?: string;
    /**
     * ID of the existing card (Get from [`/b2b/user/cards`](#operation/b2bUserCards))
     * @type {string}
     * @memberof B2bBuyRequestAPIModel
     */
    cardId?: string;
    /**
     * Widget display theme
     * @type {string}
     * @memberof B2bBuyRequestAPIModel
     */
    theme?: string;
}

/**
 * Check if a given object implements the B2bBuyRequestAPIModel interface.
 */
export function instanceOfB2bBuyRequestAPIModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "trxToken" in value;
    isInstance = isInstance && "address" in value;

    return isInstance;
}

export function B2bBuyRequestAPIModelFromJSON(json: any): B2bBuyRequestAPIModel {
    return B2bBuyRequestAPIModelFromJSONTyped(json, false);
}

export function B2bBuyRequestAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bBuyRequestAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trxToken': json['trx_token'],
        'address': json['address'],
        'merchantTransactionId': !exists(json, 'merchant_transaction_id') ? undefined : json['merchant_transaction_id'],
        'cardId': !exists(json, 'card_id') ? undefined : json['card_id'],
        'theme': !exists(json, 'theme') ? undefined : json['theme'],
    };
}

export function B2bBuyRequestAPIModelToJSON(value?: B2bBuyRequestAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trx_token': value.trxToken,
        'address': value.address,
        'merchant_transaction_id': value.merchantTransactionId,
        'card_id': value.cardId,
        'theme': value.theme,
    };
}

