import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

import { HeaderTitle, Space, Submittable } from '@/components';
import { BaseContainer, HeaderBox, MainButton } from '@/layout/components';
import { withBackURL } from '@/layout/components/BackButton';

import type { MainLayoutProps } from './types';

const useStyles = makeStyles()((theme: Theme) => {
  const baseContainer: CSSObject = {
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  };
  const formContent: CSSObject = baseContainer;
  const formContainer: CSSObject = {
    ...baseContainer,
    color: theme.colors.primary,
    backgroundColor: theme.colors.primaryBackground,
    margin: 0,
    padding: 0,
  };
  const footer: CSSObject = {
    marginTop: 'auto',
    flexGrow: 0,
    flexShrink: 0,
    overflow: 'visible',
  };
  return {
    header: {
      paddingTop: theme.spacing(),
      marginBottom: theme.spacing(3),
    },
    footer: {
      ...footer,
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    formContainer,
    formContent: {
      ...formContent,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  };
});

const MainLayout: React.FC<MainLayoutProps> = ({
  header,
  content,
  container,
  containerId,
  children,
  buttons,
  footer,
  'data-test': dataTest,
}) => {
  const { cx, classes } = useStyles();
  const isLowHeight = useMediaQuery('@media (max-height: 450px)');
  return (
    <BaseContainer boxClassname={container?.className} containerPrefix={container?.prefix}>
      <HeaderBox
        data-test={dataTest && `${dataTest}-header`}
        variant={header?.titleVariant || 'h2'}
        title={
          // prettier-ignore
          header?.title || header?.postfix ? (
            <HeaderTitle title={header?.title} postfix={header?.titlePostfix} />
          ) : undefined
        }
        buttons={{
          leftTitle: typeof buttons?.back === 'string' ? withBackURL(buttons.back as string) : buttons?.back,
        }}
        className={header?.className}
        extra={header?.postfix}
        logo={undefined}
      />
      <Submittable
        onSubmit={buttons?.main?.submit ? buttons?.main?.onAct : undefined}
        className={cx(content?.className, classes.formContainer)}
      >
        {content?.prefix}
        <Space
          direction="vertical"
          size={isLowHeight ? 1 : 3}
          id={containerId}
          className={cx(content?.contentClassName, classes.formContent)}
        >
          {children}
        </Space>
        {(footer || buttons?.main) && (
          <Space direction="vertical" size={2.5} className={cx(classes.footer, footer?.className)}>
            {footer?.component}
            {buttons?.main && <MainButton data-test={dataTest && `${dataTest}-submit`} {...buttons.main} />}
          </Space>
        )}
      </Submittable>
    </BaseContainer>
  );
};

export default MainLayout;
