export enum YMGoals {
  WIDGET_OPENED = 'WIDGET_OPENED',
  WIDGET_INITIALIZED = 'WIDGET_INITIALIZED',

  KYC_STARTED = 'KYC_STARTED',
  KYC_SUCCESS = 'KYC_SUCCESS',
  KYC_FAILURE = 'KYC_FAILURE',

  PAYMENT_STARTED = 'PAYMENT_STARTED',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_FAILURE = 'PAYMENT_FAILURE',
  PAYMENT_FAILURE_3DS = 'PAYMENT_FAILURE_3DS',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
  PAYMENT_INCORRECT_DATA = 'PAYMENT_INCORRECT_DATA',
  PAYMENT_NOT_ALLOWED_BY_USER = 'PAYMENT_NOT_ALLOWED_BY_USER',

  PAYMENT_KYC_REQUIRED = 'PAYMENT_KYC_REQUIRED',
}

const ymId = window.env.YM_ENABLED === 'true' && window.env.YM_ID ? window.env.YM_ID : undefined;

const goalReachedFunc = (id: number) => (goalName: YMGoals) => {
  try {
    ym(id, 'reachGoal', goalName);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(goalName, e);
  }
};

const initUserFunc = (id: number) => (email?: string) => {
  if (email) {
    try {
      ym(id, 'setUserID', email);
    } catch (e) {
      console.warn('user init failed', e);
    }
  }
};

export const goalReached = ymId
  ? goalReachedFunc(ymId)
  : () => {
      /* noop */
    };

export const ymInitUser = ymId
  ? initUserFunc(ymId)
  : () => {
      /* noop */
    };
