import React from 'react';
import { FormattedMessage as BaseFormattedMessage } from 'react-intl';

import type { FormattedMessageProps } from './types';

const FormattedMessage: React.FC<FormattedMessageProps> = ({ id, ...props }) => (
  <BaseFormattedMessage id={id} {...props} />
);

export default React.memo(FormattedMessage);
