import * as Sentry from '@sentry/react';

import { createAppAsyncThunk } from '@/app/actions';
import { makeSelectUser } from '@/features/auth/selectors';
import { createNotification } from '@/features/global/actions';
import { makeSelectLocale, makeSelectRequest } from '@/features/global/selectors';
import { sendCallback } from '@/features/intercom/actions';
import { saveExplicitKYCRequirement } from '@/features/kyc/actions';
import { makeSelectIsMockEnabled } from '@/features/mock/selectors';
import { markRateDirty } from '@/features/purchase/actions';
import { makeSelectPurchaseKey, makeSelectSelectedCard } from '@/features/purchase/selectors';
import {
  cancelledByCustomer,
  causedBy3dsError,
  incorrectCardData,
  kycRequired,
  referToIssuer,
} from '@/features/transaction/messages';
import { B2bSellPciDssCard200ResponseDataAPIModelStatusEnum } from '@/generated/api/mercuryo/api';
import { I18nFeatureBuyTransaction } from '@/generated/i18n/i18n';
import { ResponseError } from '@/infrastructure/security/auth.provider';
import { goalReached, YMGoals } from '@/infrastructure/ym';
import { CallbackPageRoutes, callbackRoute } from '@/pages/callback/routes';
import { MockPageRoutes, mockRoute } from '@/pages/mock/routes';
import { withOnSuccess } from '@/utils/functions';

import { queryTransactionStatus, requestCreateSavedCardTransaction } from './api';
import { makeSelectCreateTxData } from './selectors';
import { NAMESPACE } from './types';

export const createSavedCardTransaction = createAppAsyncThunk(
  `${NAMESPACE}/createSavedCardTransaction`,
  async (_, { abort, dispatch, getState }) => {
    const card = makeSelectSelectedCard()(getState());
    const { valid, data } = makeSelectCreateTxData()(getState());
    if (!data || !valid) {
      abort('invalid');
      throw new Error(); // to make ts happier
    }
    const isMockEnabled = makeSelectIsMockEnabled()(getState());
    if (isMockEnabled) {
      const init = makeSelectRequest()(getState());
      const locale = makeSelectLocale()(getState());
      return {
        url: `${window.location.origin}${mockRoute(MockPageRoutes.CARD)}?${new URLSearchParams({
          token: init.token || '',
          address: data.address || '',
          network: data.rate.toBuy.network || '',
          amount: data.rate.toBuy ? `${data.rate.toBuy.amount} ${data.rate.toBuy.currency}` : '',
          'tx-id': data.merchantTx,
          lang: locale,
          success: `${window.location.origin}${callbackRoute(CallbackPageRoutes.PAYMENT_SUCCESS)}`,
          failure: `${window.location.origin}${callbackRoute(CallbackPageRoutes.PAYMENT_FAILURE)}`,
          mock: 'true',
        }).toString()}`,
      };
    }

    try {
      return await withOnSuccess(requestCreateSavedCardTransaction, () => goalReached(YMGoals.PAYMENT_STARTED))({
        merchantTx: data.merchantTx,
        trxToken: data.rate.token,
        address: data.address,
        cardId: card?.data?.cardId,
      });
    } catch (e) {
      const errorData = (e as Partial<ResponseError>).data;
      const email = makeSelectUser()(getState()).data?.email;
      if (errorData) {
        if (errorData.data?.code === 400001 && errorData.data?.data?.merchant_transaction_id) {
          await dispatch(sendCallback({ type: 'payment-failed', data: { retry: true } }));
        } else if (
          errorData.data?.code === 400001
          && errorData.data?.data?.trx_token?.includes('Waiting time was expired, exchange rates were changed.')
        ) {
          dispatch(markRateDirty({ purchaseKey: makeSelectPurchaseKey()(getState()) }));
        } else if (errorData.data?.code === 403001) {
          Sentry.captureEvent({
            message: 'NCPS-MERCURYO: kyc-required',
            user: { email },
            level: 'debug',
            extra: {
              txId: data.merchantTx,
              amount: data.rate.toBuy,
              xRequestId: errorData.headers?.get?.('X-Request-Id'),
            },
          });
          goalReached(YMGoals.PAYMENT_KYC_REQUIRED);
          dispatch(saveExplicitKYCRequirement({ isRequired: true }));
          abort('kyc required');
          throw e; // to make ts happier
        }
      }
      Sentry.captureEvent({
        message: 'NCPS-MERCURYO: create tx failed',
        user: { email },
        level: 'error',
        exception:
          e instanceof Error ? { values: [Sentry.exceptionFromError(Sentry.defaultStackParser, e)] } : undefined,
        extra: {
          response: errorData?.data,
          txId: data.merchantTx,
          amount: data.rate.toBuy,
          xRequestId: errorData?.headers?.get?.('X-Request-Id'),
        },
      });
      throw e;
    }
  },
);

export const processCardTransactionCallback = createAppAsyncThunk(
  `${NAMESPACE}/processCardTransactionCallback`,
  async (
    { success, tx, msg }: { success: boolean; tx?: { txId: string; address: string; amount: string }; msg?: string },
    { dispatch, getState },
  ) => {
    let verifiedSuccess = success;
    let failedReason;
    if (tx) {
      const status = await queryTransactionStatus(tx.txId);
      if (status.status === B2bSellPciDssCard200ResponseDataAPIModelStatusEnum.failed) {
        verifiedSuccess = false;
      }
      failedReason = status.failedReason;
    }
    const email = makeSelectUser()(getState()).data?.email;

    if (msg?.includes(kycRequired)) {
      Sentry.captureEvent({
        message: 'NCPS-MERCURYO: kyc-required',
        user: { email },
        level: 'debug',
        extra: {
          txId: tx?.txId,
          amount: tx?.amount,
          msg,
        },
      });
      goalReached(YMGoals.PAYMENT_KYC_REQUIRED);
      await dispatch(saveExplicitKYCRequirement({ isRequired: true }));
      return 'kyc-required';
    }
    if (!verifiedSuccess) {
      Sentry.captureEvent({
        message: 'NCPS-MERCURYO: tx-failed',
        user: { email },
        level: 'error',
        extra: {
          txId: tx?.txId,
          amount: tx?.amount,
          msg,
          failedReason,
        },
      });
    }
    if (verifiedSuccess) {
      goalReached(YMGoals.PAYMENT_SUCCESS);
    } else if (failedReason?.publicMessage?.includes(cancelledByCustomer) || msg?.includes(cancelledByCustomer)) {
      goalReached(YMGoals.PAYMENT_CANCELLED);
    } else if (failedReason?.publicMessage?.includes(incorrectCardData) || msg?.includes(incorrectCardData)) {
      goalReached(YMGoals.PAYMENT_INCORRECT_DATA);
    } else if (failedReason?.publicMessage?.includes(referToIssuer) || msg?.includes(referToIssuer)) {
      goalReached(YMGoals.PAYMENT_NOT_ALLOWED_BY_USER);
    } else if (
      failedReason?.publicMessage?.toLowerCase().includes(causedBy3dsError)
      || msg?.includes(causedBy3dsError)
    ) {
      goalReached(YMGoals.PAYMENT_FAILURE_3DS);
    } else {
      goalReached(YMGoals.PAYMENT_FAILURE);
    }

    if (!verifiedSuccess) {
      await dispatch(
        createNotification({
          titleId: I18nFeatureBuyTransaction.MESSAGES_FAILED_TITLE,
          messageId: I18nFeatureBuyTransaction.MESSAGES_FAILED_DESCRIPTION,
          severity: 'error',
        }),
      );
      await dispatch(sendCallback({ type: 'update-tx-req' }));
    } else {
      await dispatch(
        createNotification({
          titleId: I18nFeatureBuyTransaction.MESSAGES_SUCCESS_TITLE,
          messageId: I18nFeatureBuyTransaction.MESSAGES_SUCCESS_DESCRIPTION,
          severity: 'success',
        }),
      );
      await dispatch(
        sendCallback({
          type: 'payment-succeeded',
          data: tx,
        }),
      );
    }

    return verifiedSuccess ? 'success' : 'failed';
  },
);
