import { flatten } from 'flat';
import moment from 'moment';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import 'moment/locale/ru';
import 'moment/locale/ko';

import * as locales from './i18n';

const tryLocale = (locale: string): [string, Record<string, string>] | undefined => {
  const foundLocale = (locales as Record<string, unknown>)[locale.replace('-', '')];
  return foundLocale ? [locale, flatten<unknown, Record<string, string>>(foundLocale)] : undefined;
};

const parseLocale = ({ locale }: { locale?: string }): [string, Record<string, string>] =>
  (locale && tryLocale(locale)) || tryLocale(window.env.DEFAULT_LOCALE) || [window.env.DEFAULT_LOCALE, {}];

const withI18n =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props) => {
    const [locale, messages] = parseLocale(props);
    useEffect(() => {
      if (locale) {
        moment.locale(locale);
      }
    }, [locale]);
    return (
      <IntlProvider locale={locale} messages={messages}>
        <Component {...props} />
      </IntlProvider>
    );
  };

export default withI18n;
