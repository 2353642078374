import moment from 'moment';
import { useEffect, useState } from 'react';

export default function useTimer(till?: Date): [number | undefined] {
  const [secondsTil, setSecondsTil] = useState<number | undefined>(() => {
    const diff = moment(till).diff(moment(new Date()), 's');
    return diff > 0 ? diff : undefined;
  });
  useEffect(() => {
    if (!till) {
      setSecondsTil(undefined);
      return undefined;
    }
    const diff = moment(till).diff(moment(new Date()), 's');
    if (diff > 0) {
      setSecondsTil(diff);
      const timeout = setTimeout(() => {
        if (secondsTil) {
          setSecondsTil(secondsTil - 1);
        }
      }, 1000);
      return (): void => clearTimeout(timeout);
    }
    setSecondsTil(undefined);
    return undefined;
  }, [till, secondsTil]);
  return [secondsTil];
}
