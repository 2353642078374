/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel } from './PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel';
import {
    PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModelFromJSON,
    PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModelFromJSONTyped,
    PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModelToJSON,
} from './PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel';
import type { PublicCurrencies200ResponseDataConfigDefaultNetworksAPIModel } from './PublicCurrencies200ResponseDataConfigDefaultNetworksAPIModel';
import {
    PublicCurrencies200ResponseDataConfigDefaultNetworksAPIModelFromJSON,
    PublicCurrencies200ResponseDataConfigDefaultNetworksAPIModelFromJSONTyped,
    PublicCurrencies200ResponseDataConfigDefaultNetworksAPIModelToJSON,
} from './PublicCurrencies200ResponseDataConfigDefaultNetworksAPIModel';
import type { PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel } from './PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel';
import {
    PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModelFromJSON,
    PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModelFromJSONTyped,
    PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModelToJSON,
} from './PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel';
import type { PublicCurrencies200ResponseDataConfigHasWithdrawalFeeAPIModel } from './PublicCurrencies200ResponseDataConfigHasWithdrawalFeeAPIModel';
import {
    PublicCurrencies200ResponseDataConfigHasWithdrawalFeeAPIModelFromJSON,
    PublicCurrencies200ResponseDataConfigHasWithdrawalFeeAPIModelFromJSONTyped,
    PublicCurrencies200ResponseDataConfigHasWithdrawalFeeAPIModelToJSON,
} from './PublicCurrencies200ResponseDataConfigHasWithdrawalFeeAPIModel';
import type { PublicCurrencies200ResponseDataConfigNetworksAPIModel } from './PublicCurrencies200ResponseDataConfigNetworksAPIModel';
import {
    PublicCurrencies200ResponseDataConfigNetworksAPIModelFromJSON,
    PublicCurrencies200ResponseDataConfigNetworksAPIModelFromJSONTyped,
    PublicCurrencies200ResponseDataConfigNetworksAPIModelToJSON,
} from './PublicCurrencies200ResponseDataConfigNetworksAPIModel';

/**
 * Currency options
 * @export
 * @interface PublicCurrencies200ResponseDataConfigAPIModel
 */
export interface PublicCurrencies200ResponseDataConfigAPIModel {
    /**
     * 
     * @type {object}
     * @memberof PublicCurrencies200ResponseDataConfigAPIModel
     */
    base?: object;
    /**
     * Crypto currencies list
     * @type {Array<PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel>}
     * @memberof PublicCurrencies200ResponseDataConfigAPIModel
     */
    cryptoCurrencies?: Array<PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel>;
    /**
     * 
     * @type {PublicCurrencies200ResponseDataConfigDefaultNetworksAPIModel}
     * @memberof PublicCurrencies200ResponseDataConfigAPIModel
     */
    defaultNetworks?: PublicCurrencies200ResponseDataConfigDefaultNetworksAPIModel;
    /**
     * 
     * @type {{ [key: string]: PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel; }}
     * @memberof PublicCurrencies200ResponseDataConfigAPIModel
     */
    displayOptions?: { [key: string]: PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel; };
    /**
     * 
     * @type {PublicCurrencies200ResponseDataConfigHasWithdrawalFeeAPIModel}
     * @memberof PublicCurrencies200ResponseDataConfigAPIModel
     */
    hasWithdrawalFee?: PublicCurrencies200ResponseDataConfigHasWithdrawalFeeAPIModel;
    /**
     * Currencies icons paths
     * @type {object}
     * @memberof PublicCurrencies200ResponseDataConfigAPIModel
     */
    icons?: object;
    /**
     * 
     * @type {PublicCurrencies200ResponseDataConfigNetworksAPIModel}
     * @memberof PublicCurrencies200ResponseDataConfigAPIModel
     */
    networks?: PublicCurrencies200ResponseDataConfigNetworksAPIModel;
}

/**
 * Check if a given object implements the PublicCurrencies200ResponseDataConfigAPIModel interface.
 */
export function instanceOfPublicCurrencies200ResponseDataConfigAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PublicCurrencies200ResponseDataConfigAPIModelFromJSON(json: any): PublicCurrencies200ResponseDataConfigAPIModel {
    return PublicCurrencies200ResponseDataConfigAPIModelFromJSONTyped(json, false);
}

export function PublicCurrencies200ResponseDataConfigAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicCurrencies200ResponseDataConfigAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'base': !exists(json, 'base') ? undefined : json['base'],
        'cryptoCurrencies': !exists(json, 'crypto_currencies') ? undefined : ((json['crypto_currencies'] as Array<any>).map(PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModelFromJSON)),
        'defaultNetworks': !exists(json, 'default_networks') ? undefined : PublicCurrencies200ResponseDataConfigDefaultNetworksAPIModelFromJSON(json['default_networks']),
        'displayOptions': !exists(json, 'display_options') ? undefined : (mapValues(json['display_options'], PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModelFromJSON)),
        'hasWithdrawalFee': !exists(json, 'has_withdrawal_fee') ? undefined : PublicCurrencies200ResponseDataConfigHasWithdrawalFeeAPIModelFromJSON(json['has_withdrawal_fee']),
        'icons': !exists(json, 'icons') ? undefined : json['icons'],
        'networks': !exists(json, 'networks') ? undefined : PublicCurrencies200ResponseDataConfigNetworksAPIModelFromJSON(json['networks']),
    };
}

export function PublicCurrencies200ResponseDataConfigAPIModelToJSON(value?: PublicCurrencies200ResponseDataConfigAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base': value.base,
        'crypto_currencies': value.cryptoCurrencies === undefined ? undefined : ((value.cryptoCurrencies as Array<any>).map(PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModelToJSON)),
        'default_networks': PublicCurrencies200ResponseDataConfigDefaultNetworksAPIModelToJSON(value.defaultNetworks),
        'display_options': value.displayOptions === undefined ? undefined : (mapValues(value.displayOptions, PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModelToJSON)),
        'has_withdrawal_fee': PublicCurrencies200ResponseDataConfigHasWithdrawalFeeAPIModelToJSON(value.hasWithdrawalFee),
        'icons': value.icons,
        'networks': PublicCurrencies200ResponseDataConfigNetworksAPIModelToJSON(value.networks),
    };
}

