import { createAction } from '@reduxjs/toolkit';
import moment from 'moment';
import ms from 'ms';
import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import {
  B2bUserCardLimitsUnverified200ResponseDataAPIModel,
  B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel,
  B2bUserKycAccessTokenKycLevelEnum,
} from '@/generated/api/mercuryo/api';
import { withAPICall } from '@/utils/api';
import { CommonLoadingState, flatMapLoadingState, storedDataError, storedDataLoaded } from '@/utils/model';
import { asType } from '@/utils/ts';

import {
  deleteKYCExplicitRequirement,
  postKYCExplicitRequirement,
  queryKYCExplicitRequirement,
  queryKYCLimits,
  queryKYCToken,
} from './api';
import {
  makeSelectKYCData,
  makeSelectKYCDirtiness,
  makeSelectKYCLimitsDirtiness,
  makeSelectKYCRequiredExplicitly,
} from './selectors';
import { KYCIFrameData, KYCLimits, NAMESPACE } from './types';

export const storeKYCData = createAction<{
  data: CommonLoadingState<KYCIFrameData>;
}>(`${NAMESPACE}/storeKYCData`);
export const markKYCDataDirty = createAction(`${NAMESPACE}/storeKYCDirty`);

export const storeKYCLimits = createAction<{
  data: CommonLoadingState<KYCLimits>;
}>(`${NAMESPACE}/storeKYCLimits`);
export const markKYCLimitsDirty = createAction(`${NAMESPACE}/markKYCLimitsDirty`);

export const storeKYCExplicitRequirement = createAction<CommonLoadingState<boolean>>(
  `${NAMESPACE}/storeKYCExplicitRequirement`,
);

export const fetchKYCData = createAppAsyncThunk(
  `${NAMESPACE}/fetchKYCData`,
  async ({ force }: { force: boolean }, { dispatch, getState }) => {
    if (!force) {
      const isDirty = makeSelectKYCDirtiness()(getState());
      if (!isDirty) {
        return;
      }
    }
    try {
      const response = await withAPICall(queryKYCToken)(B2bUserKycAccessTokenKycLevelEnum.NUMBER_1);
      const msToExpire = ms(window.env.KYC_EXPIRATION_PERIOD);
      const data = flatMapLoadingState(response, ({ applicantId, kycAccessToken }) =>
        applicantId && kycAccessToken
          ? {
              data: {
                kycToken: kycAccessToken,
                applicantId,
                expiresAt: moment().add(msToExpire, 'ms').toISOString(),
              },
            }
          : { error: 'No applicantId or kycAccessToken' },
      );
      dispatch(storeKYCData({ data }));
      if (data.data) {
        setTimeout(() => {
          const newRates = makeSelectKYCData()(getState());
          if (moment(newRates?.data?.expiresAt).subtract(1, 'second').isAfter()) {
            return;
          }
          dispatch(markKYCDataDirty());
        }, msToExpire);
      }
    } catch (e) {
      console.error(e);
      dispatch(storeKYCData({ data: storedDataError(`${e}`) }));
    }
  },
);

export const fetchKYCLimits = createAppAsyncThunk(
  `${NAMESPACE}/fetchKYCLimits`,
  async ({ force }: { force: boolean }, { dispatch, getState }) => {
    if (!force) {
      const isDirty = makeSelectKYCLimitsDirtiness()(getState());
      if (!isDirty) {
        return;
      }
    }
    try {
      const response = await withAPICall(queryKYCLimits)();
      const data: CommonLoadingState<KYCLimits> = flatMapLoadingState(
        response,
        ({ currenciesLimits }: B2bUserCardLimitsUnverified200ResponseDataAPIModel) =>
          currenciesLimits
            ? {
                data: Object.entries(currenciesLimits).reduce(
                  (
                    result,
                    [currency, { total, remain }]: [
                      string,
                      B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel,
                    ],
                  ) => ({
                    ...result,
                    [currency]: { total: total || '0', remain: remain || '0' },
                  }),
                  asType<KYCLimits>({}),
                ),
              }
            : { error: 'No Limits' },
      );
      dispatch(storeKYCLimits({ data }));
    } catch (e) {
      console.error(e);
      dispatch(storeKYCLimits({ data: storedDataError(`${e}`) }));
    }
  },
);

const limit = pLimit(1);
export const fetchExplicitKYCRequirement = createAppAsyncThunk(
  `${NAMESPACE}/fetchExplicitKYCRequirement`,
  async ({ force }: { force: boolean }, { dispatch, getState }) =>
    limit(async () => {
      if (!force) {
        const { isDirty } = makeSelectKYCRequiredExplicitly()(getState());
        if (!isDirty) {
          return;
        }
      }
      dispatch(storeKYCExplicitRequirement(await withAPICall(queryKYCExplicitRequirement)()));
    }),
);

export const saveExplicitKYCRequirement = createAppAsyncThunk(
  `${NAMESPACE}/saveExplicitKYCRequirement`,
  async ({ isRequired }: { isRequired: boolean }, { dispatch }) => {
    if (isRequired) {
      await withAPICall(postKYCExplicitRequirement)();
    } else {
      await withAPICall(deleteKYCExplicitRequirement)();
    }

    dispatch(storeKYCExplicitRequirement(storedDataLoaded(isRequired)));
  },
);
