/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel } from './B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel';
import {
    B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModelFromJSON,
    B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModelFromJSONTyped,
    B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModelToJSON,
} from './B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel';

/**
 * 
 * @export
 * @interface B2bUserCardLimitsUnverified200ResponseDataAPIModel
 */
export interface B2bUserCardLimitsUnverified200ResponseDataAPIModel {
    /**
     * 
     * @type {{ [key: string]: B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel; }}
     * @memberof B2bUserCardLimitsUnverified200ResponseDataAPIModel
     */
    currenciesLimits?: { [key: string]: B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel; };
}

/**
 * Check if a given object implements the B2bUserCardLimitsUnverified200ResponseDataAPIModel interface.
 */
export function instanceOfB2bUserCardLimitsUnverified200ResponseDataAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bUserCardLimitsUnverified200ResponseDataAPIModelFromJSON(json: any): B2bUserCardLimitsUnverified200ResponseDataAPIModel {
    return B2bUserCardLimitsUnverified200ResponseDataAPIModelFromJSONTyped(json, false);
}

export function B2bUserCardLimitsUnverified200ResponseDataAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserCardLimitsUnverified200ResponseDataAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currenciesLimits': !exists(json, 'currenciesLimits') ? undefined : (mapValues(json['currenciesLimits'], B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModelFromJSON)),
    };
}

export function B2bUserCardLimitsUnverified200ResponseDataAPIModelToJSON(value?: B2bUserCardLimitsUnverified200ResponseDataAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currenciesLimits': value.currenciesLimits === undefined ? undefined : (mapValues(value.currenciesLimits, B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModelToJSON)),
    };
}

