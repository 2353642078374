/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * NCPS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  KYCReqAPIModel,
  KYCRespAPIModel,
  StoreMockMercuryoOnRampReqAPIModel,
  StoreMockMercuryoOnRampRespAPIModel,
} from '../models';
import {
    KYCReqAPIModelFromJSON,
    KYCReqAPIModelToJSON,
    KYCRespAPIModelFromJSON,
    KYCRespAPIModelToJSON,
    StoreMockMercuryoOnRampReqAPIModelFromJSON,
    StoreMockMercuryoOnRampReqAPIModelToJSON,
    StoreMockMercuryoOnRampRespAPIModelFromJSON,
    StoreMockMercuryoOnRampRespAPIModelToJSON,
} from '../models';

export interface StoreMockMercuryoKYCRequest {
    kYCReqAPIModel: KYCReqAPIModel;
}

export interface StoreMockOnRampTransactionRequest {
    storeMockMercuryoOnRampReqAPIModel: StoreMockMercuryoOnRampReqAPIModel;
}

/**
 * MercuryoMockApi - interface
 * 
 * @export
 * @interface MercuryoMockApiInterface
 */
export interface MercuryoMockApiInterface {
    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MercuryoMockApiInterface
     */
    getMockMercuryoKYCRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KYCRespAPIModel>>;

    /**
     * 
     */
    getMockMercuryoKYC(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KYCRespAPIModel>;

    /**
     * 
     * @summary 
     * @param {KYCReqAPIModel} kYCReqAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MercuryoMockApiInterface
     */
    storeMockMercuryoKYCRaw(requestParameters: StoreMockMercuryoKYCRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KYCRespAPIModel>>;

    /**
     * 
     */
    storeMockMercuryoKYC(kYCReqAPIModel: KYCReqAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KYCRespAPIModel>;

    /**
     * 
     * @summary 
     * @param {StoreMockMercuryoOnRampReqAPIModel} storeMockMercuryoOnRampReqAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MercuryoMockApiInterface
     */
    storeMockOnRampTransactionRaw(requestParameters: StoreMockOnRampTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoreMockMercuryoOnRampRespAPIModel>>;

    /**
     * 
     */
    storeMockOnRampTransaction(storeMockMercuryoOnRampReqAPIModel: StoreMockMercuryoOnRampReqAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoreMockMercuryoOnRampRespAPIModel>;

}

/**
 * 
 */
export class MercuryoMockApi extends runtime.BaseAPI implements MercuryoMockApiInterface {

    /**
     * 
     */
    async getMockMercuryoKYCRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KYCRespAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("mercuryo-partner-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mercuryo-mock/kyc`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KYCRespAPIModelFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getMockMercuryoKYC(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KYCRespAPIModel> {
        const response = await this.getMockMercuryoKYCRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async storeMockMercuryoKYCRaw(requestParameters: StoreMockMercuryoKYCRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KYCRespAPIModel>> {
        if (requestParameters.kYCReqAPIModel === null || requestParameters.kYCReqAPIModel === undefined) {
            throw new runtime.RequiredError('kYCReqAPIModel','Required parameter requestParameters.kYCReqAPIModel was null or undefined when calling storeMockMercuryoKYC.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("mercuryo-partner-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mercuryo-mock/kyc`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KYCReqAPIModelToJSON(requestParameters.kYCReqAPIModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KYCRespAPIModelFromJSON(jsonValue));
    }

    /**
     * 
     */
    async storeMockMercuryoKYC(kYCReqAPIModel: KYCReqAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KYCRespAPIModel> {
        const response = await this.storeMockMercuryoKYCRaw({ kYCReqAPIModel: kYCReqAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async storeMockOnRampTransactionRaw(requestParameters: StoreMockOnRampTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoreMockMercuryoOnRampRespAPIModel>> {
        if (requestParameters.storeMockMercuryoOnRampReqAPIModel === null || requestParameters.storeMockMercuryoOnRampReqAPIModel === undefined) {
            throw new runtime.RequiredError('storeMockMercuryoOnRampReqAPIModel','Required parameter requestParameters.storeMockMercuryoOnRampReqAPIModel was null or undefined when calling storeMockOnRampTransaction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("mercuryo-partner-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mercuryo-mock/on-ramp-tx`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StoreMockMercuryoOnRampReqAPIModelToJSON(requestParameters.storeMockMercuryoOnRampReqAPIModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoreMockMercuryoOnRampRespAPIModelFromJSON(jsonValue));
    }

    /**
     * 
     */
    async storeMockOnRampTransaction(storeMockMercuryoOnRampReqAPIModel: StoreMockMercuryoOnRampReqAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoreMockMercuryoOnRampRespAPIModel> {
        const response = await this.storeMockOnRampTransactionRaw({ storeMockMercuryoOnRampReqAPIModel: storeMockMercuryoOnRampReqAPIModel }, initOverrides);
        return await response.value();
    }

}
