import { createReducer } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { globalInit } from '@/features/global/actions';
import { startListeningParentMessages } from '@/utils/messaging';

import { receiveCommand } from './actions';
import { isMercuryoCommand } from './types';

import type { IntercomState } from './types';

const initialState: IntercomState = {};

export const reducer = createReducer(initialState, () => {
  // Initialization listeners here for laziness
  listenerMiddleware.startListening({
    type: globalInit.type,
    effect: async (_, { dispatch }) => {
      startListeningParentMessages(async (message) => {
        if (isMercuryoCommand(message)) {
          await dispatch(receiveCommand(message));
        }
      });
    },
  });
});

export default reducer;
