import { createReducer, isAnyOf, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { isNull } from 'lodash';

import listenerMiddleware from '@/app/listenerMiddleware';
import {
  addNotificationToStore,
  removeNotificationFromStore,
  storeLoading,
  storeLocale,
  storeRequestState,
  storeViewHeight,
} from '@/features/global/actions';
import type { GlobalState } from '@/features/global/types';
import { receiveCommand, sendCallback } from '@/features/intercom/actions';
import { goalReached, YMGoals } from '@/infrastructure/ym';
import { InitStatus } from '@/utils/model';

const initialState: GlobalState = {
  notifications: [],
  locale: {
    status: InitStatus.NOT_INITIALIZED,
    value: window.env.DEFAULT_LOCALE,
  },
  initializing: {
    value: {},
    isURLProcessed: false,
    isWaitingForInitCall: false,
  },
  loading: { isLoading: false },
  vh: 100,
  pending: {},
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addNotificationToStore, (state, { payload: notification }) => ({
      ...state,
      notifications: [notification, ...state.notifications],
    }))
    .addCase(removeNotificationFromStore, (state, { payload: id }) => ({
      ...state,
      notifications: [...state.notifications.filter((saved) => saved.id !== id)],
    }))
    .addCase(storeLocale, (state, { payload }) => ({
      ...state,
      locale: {
        value: payload,
        status: InitStatus.FINISHED,
      },
    }))
    .addCase(storeLoading, (state, { payload }) => ({
      ...state,
      loading: payload,
    }))
    .addCase(storeViewHeight, (state, { payload }) => ({
      ...state,
      vh: payload,
    }))
    .addCase(storeRequestState, (state, { payload }) => {
      const { initByMessagingRequired, initialized, ...data } = payload;
      return {
        ...state,
        initializing: {
          value: { ...state.initializing.value, ...data },
          isWaitingForInitCall:
            // eslint-disable-next-line no-nested-ternary
            initialized === 'by-message'
              ? false
              : initByMessagingRequired
              ? true
              : state.initializing.isWaitingForInitCall,
          isURLProcessed: initialized === 'by-url' || state.initializing.isURLProcessed,
        },
        locale: data.locale ? { ...state.locale, value: data.locale } : state.locale,
      };
    })
    .addMatcher(isAnyOf(isPending, isRejected, isFulfilled), (state, action) => {
      const type = action.type.substring(0, action.type.lastIndexOf('/'));
      const actionPendingRequests = state.pending[type] || [];
      if (isPending(action)) {
        return {
          ...state,
          pending: { ...state.pending, [type]: [...actionPendingRequests, action.meta.requestId] },
        };
      }
      return {
        ...state,
        pending: {
          ...state.pending,
          [type]: actionPendingRequests.filter((requestId) => requestId !== action.meta.requestId),
        },
      };
    });

  // Initialization listeners here for laziness
  listenerMiddleware.startListening({
    actionCreator: receiveCommand,
    effect: async ({ payload: command }, { dispatch }) => {
      if (command.type === 'init') {
        dispatch(
          storeRequestState({
            ...(command.data.destination ? { address: command.data.destination } : {}),
            ...(command.data.buy.amount ? { buyAmount: command.data.buy.amount } : {}),
            ...(command.data.buy.currency ? { buyCurrency: command.data.buy.currency } : {}),
            ...(command.data.buy.network ? { buyNetwork: command.data.buy.network } : {}),
            ...(command.data.fiat.currency ? { fiatCurrency: command.data.fiat.currency } : {}),
            ...(command.data.token ? { token: command.data.token } : {}),
            ...(command.data.txId ? { merchantTx: command.data.txId } : {}),
            ...(!isNull(command.data.isMock) ? { isMockEnabled: !!command.data.isMock } : {}),
            ...(command.data.locale ? { locale: command.data.locale } : {}),
            initialized: 'by-message',
          }),
        );
        goalReached(YMGoals.WIDGET_INITIALIZED);
        await dispatch(sendCallback({ type: 'loaded' }));
      } else if (command.type === 'update-tx') {
        dispatch(storeRequestState({ merchantTx: command.data.txId }));
      }
    },
  });
});

export default reducer;
