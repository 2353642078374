/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel
 */
export interface PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel {
    /**
     * Display max digits decimal places
     * @type {number}
     * @memberof PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel
     */
    displayDigits?: number;
    /**
     * Full name of currency
     * @type {string}
     * @memberof PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel
     */
    fullname?: string;
    /**
     * Max decimal places
     * @type {number}
     * @memberof PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel
     */
    totalDigits?: number;
}

/**
 * Check if a given object implements the PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel interface.
 */
export function instanceOfPublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModelFromJSON(json: any): PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel {
    return PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModelFromJSONTyped(json, false);
}

export function PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayDigits': !exists(json, 'display_digits') ? undefined : json['display_digits'],
        'fullname': !exists(json, 'fullname') ? undefined : json['fullname'],
        'totalDigits': !exists(json, 'total_digits') ? undefined : json['total_digits'],
    };
}

export function PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModelToJSON(value?: PublicCurrencies200ResponseDataConfigDisplayOptionsValueAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'display_digits': value.displayDigits,
        'fullname': value.fullname,
        'total_digits': value.totalDigits,
    };
}

