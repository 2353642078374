/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel
 */
export interface B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel {
    /**
     * Remaining limit
     * @type {string}
     * @memberof B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel
     */
    remain?: string;
    /**
     * Total limit
     * @type {string}
     * @memberof B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel
     */
    total?: string;
}

/**
 * Check if a given object implements the B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel interface.
 */
export function instanceOfB2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModelFromJSON(json: any): B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel {
    return B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModelFromJSONTyped(json, false);
}

export function B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'remain': !exists(json, 'remain') ? undefined : json['remain'],
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModelToJSON(value?: B2bUserCardLimitsUnverified200ResponseDataCurrenciesLimitsValueAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'remain': value.remain,
        'total': value.total,
    };
}

