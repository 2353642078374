import React, { PropsWithChildren, ReactNode } from 'react';

import Space from '@/components/layout/Space';
import TruncatedText from '@/components/output/TruncatedText';
import BodyDetails from '@/components/typography/BodyDetails';
import Label from '@/components/typography/Label';

interface ReadonlyItemProps extends PropsWithChildren {
  'data-test'?: string;
  label?: ReactNode;
  noTruncate?: boolean;
  textClassName?: string;
}

const ReadonlyItem: React.FC<ReadonlyItemProps> = ({
  children,
  textClassName,
  label,
  noTruncate,
  'data-test': dataTest,
}) => (
  <Space direction="vertical" size={0} style={{ flexShrink: 0 }}>
    {label && <Label data-test={dataTest && `${dataTest}-label`}>{label}</Label>}
    <BodyDetails className={textClassName} data-test={dataTest && `${dataTest}-body`}>
      {noTruncate ? children : <TruncatedText>{children}</TruncatedText>}
    </BodyDetails>
  </Space>
);

export default React.memo(ReadonlyItem);
