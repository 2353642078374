import { CssBaseline, Theme } from '@mui/material';
import * as locales from '@mui/material/locale';
import { ThemeProvider as MuiThemeProvider, createTheme as createMuiTheme } from '@mui/material/styles';
import React, { PropsWithChildren, useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { makeSelectLocale } from '@/features/global/selectors';

const selectLocale = makeSelectLocale();

const tryLocale = (locale: string): [string, Record<string, string>] | undefined => {
  const foundLocale = (locales as unknown as Record<string, Record<string, string>>)[locale.replace('-', '')];
  return foundLocale && [locale, foundLocale];
};

const parseLocale = (locale: string): [string, Record<string, string>] =>
  tryLocale(locale) as [string, Record<string, string>];

interface ThemeColors {
  primary: string;
  primaryBackground: string;
  secondaryContrast: string;
  secondary: string;
  lightContrast: string;
  light: string;
  lightBackground: string;
  accent: string;
}

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    colors: ThemeColors;
  }

  // allows configuration using `createTheme`
  // noinspection JSUnusedGlobalSymbols
  interface ThemeOptions {
    colors: ThemeColors;
  }
}

const crateTheme = (locale: string): Theme => {
  const colors: ThemeColors = {
    primary: '#141233',
    primaryBackground: '#FFFFFF',
    secondaryContrast: '#999BBF',
    secondary: '#C3C3D9',
    lightContrast: '#E9E9F2',
    lightBackground: '#F9F9FC',
    light: '#F2F2F7',
    accent: '#141233',
  };

  return createMuiTheme(
    {
      colors,
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              '&.Mui-disabled': {
                '@media (hover: none)': {
                  color: colors.secondaryContrast,
                  backgroundColor: colors.lightContrast,
                  borderColor: colors.lightContrast,
                },
                '&:hover': {
                  '&.Mui-disabled': {
                    color: colors.secondaryContrast,
                    backgroundColor: colors.lightContrast,
                    borderColor: colors.lightContrast,
                  },
                },
                color: colors.secondaryContrast,
                backgroundColor: colors.lightContrast,
                borderColor: colors.lightContrast,
              },
            },
            outlinedPrimary: {
              backgroundColor: colors.accent,
              borderColor: colors.accent,
              borderRadius: '12px',
              padding: 0,
              fontWeight: 500,
              fontSize: '17px',
              lineHeight: '56px',
              color: colors.primaryBackground,
              '&:hover': {
                '@media (hover: none)': {
                  color: colors.primaryBackground,
                  backgroundColor: colors.accent,
                  borderColor: colors.accent,
                },
                color: colors.primaryBackground,
                backgroundColor: colors.accent,
                borderColor: colors.accent,
              },
            },
            outlinedSecondary: {
              backgroundColor: colors.lightContrast,
              borderColor: colors.lightContrast,
              borderRadius: '12px',
              padding: 0,
              fontSize: '17px',
              lineHeight: '56px',
              color: colors.primary,
              '&:hover': {
                '@media (hover: none)': {
                  color: colors.primary,
                  backgroundColor: colors.lightContrast,
                  borderColor: colors.lightContrast,
                },
                color: colors.primary,
                backgroundColor: colors.lightContrast,
                borderColor: colors.lightContrast,
              },
            },
            outlined: {
              '&$disabled': {
                borderColor: colors.lightContrast,
              },
            },
          },
        },
      },
      typography: {
        fontFamily: 'Roboto',
        h1: {
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '20px',
          lineHeight: '26px',
          textAlign: 'left',
        },
        h2: {
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '18px',
          lineHeight: '24px',
          textAlign: 'left',
        },
        h3: {
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '22px',
          textAlign: 'left',
        },
        h4: {
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
        },
        body1: {
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '20px',
        },
        body2: {
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '20px',
        },
        button: {
          width: '100%',
          fontStyle: 'normal',
          fontWeight: 'normal',
          textTransform: 'inherit',
        },
        overline: {
          color: colors.accent,
        },
      },
    },
    parseLocale(locale),
  );
};

const withTheme =
  <P extends PropsWithChildren>(Component: React.FC<P>): React.FC<P> =>
  (props: P & { locale?: string }) => {
    const storedLocale = useAppSelector(selectLocale);
    const locale = props.locale || storedLocale || window.env.DEFAULT_LOCALE;
    const theme = useMemo(() => crateTheme(locale), [locale]);
    return (
      // MuiThemeProvider makes the theme available down the React tree
      // thanks to React context.
      <MuiThemeProvider theme={theme}>
        {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  };

export default withTheme;
