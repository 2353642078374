import { useCallback } from 'react';

import useStateMountSafe from '@/hooks/useStateMountSafe';
import type { Func } from '@/utils/ts';

export default function useSubmitting(
  initialValue: boolean,
): [boolean, <V extends unknown[], R>(func: Func<V, R>) => Func<V, R>, () => void, () => void, () => void] {
  const [submitting, setSubmitting] = useStateMountSafe(initialValue);
  const withSubmitting = useCallback(
    <V extends unknown[], R>(func: Func<V, R>) =>
      async (...params: V) => {
        setSubmitting(true);
        try {
          return await func(...params);
        } finally {
          setSubmitting(false);
        }
      },
    [setSubmitting],
  );
  const reset = useCallback(() => setSubmitting(initialValue), [initialValue, setSubmitting]);
  const markSubmitting = useCallback(() => setSubmitting(true), [setSubmitting]);
  const clearSubmitting = useCallback(() => setSubmitting(false), [setSubmitting]);

  return [submitting, withSubmitting, reset, markSubmitting, clearSubmitting];
}
