/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { B2bUserGetKycDocuments200ResponseDataReviewResultAPIModel } from './B2bUserGetKycDocuments200ResponseDataReviewResultAPIModel';
import {
    B2bUserGetKycDocuments200ResponseDataReviewResultAPIModelFromJSON,
    B2bUserGetKycDocuments200ResponseDataReviewResultAPIModelFromJSONTyped,
    B2bUserGetKycDocuments200ResponseDataReviewResultAPIModelToJSON,
} from './B2bUserGetKycDocuments200ResponseDataReviewResultAPIModel';

/**
 * 
 * @export
 * @interface B2bUserGetKycDocuments200ResponseDataAPIModel
 */
export interface B2bUserGetKycDocuments200ResponseDataAPIModel {
    /**
     * Status of KYC1
     * @type {string}
     * @memberof B2bUserGetKycDocuments200ResponseDataAPIModel
     */
    kyc1Status?: B2bUserGetKycDocuments200ResponseDataAPIModelKyc1StatusEnum;
    /**
     * Status of KYC2
     * @type {string}
     * @memberof B2bUserGetKycDocuments200ResponseDataAPIModel
     */
    kyc2Status?: B2bUserGetKycDocuments200ResponseDataAPIModelKyc2StatusEnum;
    /**
     * 
     * @type {B2bUserGetKycDocuments200ResponseDataReviewResultAPIModel}
     * @memberof B2bUserGetKycDocuments200ResponseDataAPIModel
     */
    reviewResult?: B2bUserGetKycDocuments200ResponseDataReviewResultAPIModel;
}


/**
 * @export
 */
export const B2bUserGetKycDocuments200ResponseDataAPIModelKyc1StatusEnum = {
    complete: 'complete',
    incomplete: 'incomplete',
    failed: 'failed',
    under_review: 'under_review'
} as const;
export type B2bUserGetKycDocuments200ResponseDataAPIModelKyc1StatusEnum = typeof B2bUserGetKycDocuments200ResponseDataAPIModelKyc1StatusEnum[keyof typeof B2bUserGetKycDocuments200ResponseDataAPIModelKyc1StatusEnum];

/**
 * @export
 */
export const B2bUserGetKycDocuments200ResponseDataAPIModelKyc2StatusEnum = {
    complete: 'complete',
    incomplete: 'incomplete',
    failed: 'failed',
    under_review: 'under_review'
} as const;
export type B2bUserGetKycDocuments200ResponseDataAPIModelKyc2StatusEnum = typeof B2bUserGetKycDocuments200ResponseDataAPIModelKyc2StatusEnum[keyof typeof B2bUserGetKycDocuments200ResponseDataAPIModelKyc2StatusEnum];


/**
 * Check if a given object implements the B2bUserGetKycDocuments200ResponseDataAPIModel interface.
 */
export function instanceOfB2bUserGetKycDocuments200ResponseDataAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bUserGetKycDocuments200ResponseDataAPIModelFromJSON(json: any): B2bUserGetKycDocuments200ResponseDataAPIModel {
    return B2bUserGetKycDocuments200ResponseDataAPIModelFromJSONTyped(json, false);
}

export function B2bUserGetKycDocuments200ResponseDataAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserGetKycDocuments200ResponseDataAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kyc1Status': !exists(json, 'kyc1_status') ? undefined : json['kyc1_status'],
        'kyc2Status': !exists(json, 'kyc2_status') ? undefined : json['kyc2_status'],
        'reviewResult': !exists(json, 'review_result') ? undefined : B2bUserGetKycDocuments200ResponseDataReviewResultAPIModelFromJSON(json['review_result']),
    };
}

export function B2bUserGetKycDocuments200ResponseDataAPIModelToJSON(value?: B2bUserGetKycDocuments200ResponseDataAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kyc1_status': value.kyc1Status,
        'kyc2_status': value.kyc2Status,
        'review_result': B2bUserGetKycDocuments200ResponseDataReviewResultAPIModelToJSON(value.reviewResult),
    };
}

