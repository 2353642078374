import { createSelector } from '@reduxjs/toolkit';

import type { AppRootState } from '@/app/store';
import { NAMESPACE, FeatureFlags } from '@/features/feature-toggle/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const makeSelectIsToggleEnabled = (flag: FeatureFlags) =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE],
    () => true,
  );

export const makeSelectIsToggleInitialized = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE],
    (state) => state.initialized,
  );
