import { useCallback, useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { useUserData } from '@/features/auth/hooks';
import { makeSelectKYCStatus } from '@/features/kyc/selectors';
import { useMockKYCData } from '@/features/mock/hooks';

const statusSelector = makeSelectKYCStatus();

const useKYCStatus = () => {
  const { forceRefresh: refreshUserData, loading: loadingUserData } = useUserData();
  const { forceRefresh: refreshMockKYCData, loading: loadingMockKYCData } = useMockKYCData();
  const status = useAppSelector(statusSelector);
  const forceRefresh = useCallback(
    () => Promise.all([refreshMockKYCData, refreshUserData]),
    [refreshMockKYCData, refreshUserData],
  );
  const loading = loadingUserData || loadingMockKYCData;
  return useMemo(() => ({ data: status, forceRefresh, loading }), [forceRefresh, loading, status]);
};

export default useKYCStatus;
