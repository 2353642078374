import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { usePurchaseKYCLimits } from '@/features/purchase/hooks';
import { createSavedCardTransaction } from '@/features/transaction/actions';
import { makeSelectCreateTxData } from '@/features/transaction/selectors';
import { BuyPageRoutes, buyRoute } from '@/pages/buy/routes';
import type { Func } from '@/utils/ts';

export interface UseSubmitPurchaseType {
  disabled?: boolean;
  onSubmit: Func;
}

const selectCreateTxData = makeSelectCreateTxData();

export default function useSubmitPurchase(isBlocked?: boolean): UseSubmitPurchaseType {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isKYCRequired } = usePurchaseKYCLimits();
  const createTxData = useAppSelector(selectCreateTxData);
  const disabled = !createTxData.valid || !!isBlocked;
  const onSubmit = useCallback(async () => {
    if (disabled) {
      return;
    }
    if (isKYCRequired) {
      navigate(`${buyRoute(BuyPageRoutes.KYC)}`);
    } else {
      await dispatch(createSavedCardTransaction());
      navigate(`${buyRoute(BuyPageRoutes.TRANSACTION)}`);
    }
  }, [disabled, dispatch, isKYCRequired, navigate]);
  return {
    onSubmit,
    disabled,
  };
}
