import { CircularProgress } from '@mui/material';
import React from 'react';

import type { Rate } from '@/features/purchase/types';
import type { LoadingStateWithDirty } from '@/utils/model';
import type { TestableProps } from '@/utils/react';

const withRatesLoading =
  <
    P extends TestableProps & {
      isLoading: boolean;
      rates: LoadingStateWithDirty<Rate>;
    },
  >(
    Component: React.FC<P>,
  ): React.FC<P> =>
  (props: P) =>
    props.isLoading || props.rates.isDirty ? (
      <CircularProgress color="inherit" size={14} data-test={props['data-test']} />
    ) : (
      <Component {...props} />
    );

export default withRatesLoading;
