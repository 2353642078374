import { createSelector } from '@reduxjs/toolkit';

import type { AppRootState } from '@/app/store';
import { NAMESPACE } from '@/features/dictionary/types';
import { mapStoredState } from '@/utils/model';

export const makeSelectCurrencies = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE],
    (state) => state.currencies,
  );

export const makeSelectCurrency = (code: string) =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE],
    (state) => mapStoredState(state.currencies, (all) => all.find((currency) => currency.code === code)),
  );
