/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { B2bRates200ResponseDataBuyAPIModel } from './B2bRates200ResponseDataBuyAPIModel';
import {
    B2bRates200ResponseDataBuyAPIModelFromJSON,
    B2bRates200ResponseDataBuyAPIModelFromJSONTyped,
    B2bRates200ResponseDataBuyAPIModelToJSON,
} from './B2bRates200ResponseDataBuyAPIModel';
import type { B2bRates200ResponseDataSellAPIModel } from './B2bRates200ResponseDataSellAPIModel';
import {
    B2bRates200ResponseDataSellAPIModelFromJSON,
    B2bRates200ResponseDataSellAPIModelFromJSONTyped,
    B2bRates200ResponseDataSellAPIModelToJSON,
} from './B2bRates200ResponseDataSellAPIModel';

/**
 * 
 * @export
 * @interface B2bRates200ResponseDataAPIModel
 */
export interface B2bRates200ResponseDataAPIModel {
    /**
     * 
     * @type {B2bRates200ResponseDataBuyAPIModel}
     * @memberof B2bRates200ResponseDataAPIModel
     */
    buy?: B2bRates200ResponseDataBuyAPIModel;
    /**
     * 
     * @type {B2bRates200ResponseDataSellAPIModel}
     * @memberof B2bRates200ResponseDataAPIModel
     */
    sell?: B2bRates200ResponseDataSellAPIModel;
}

/**
 * Check if a given object implements the B2bRates200ResponseDataAPIModel interface.
 */
export function instanceOfB2bRates200ResponseDataAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bRates200ResponseDataAPIModelFromJSON(json: any): B2bRates200ResponseDataAPIModel {
    return B2bRates200ResponseDataAPIModelFromJSONTyped(json, false);
}

export function B2bRates200ResponseDataAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bRates200ResponseDataAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buy': !exists(json, 'buy') ? undefined : B2bRates200ResponseDataBuyAPIModelFromJSON(json['buy']),
        'sell': !exists(json, 'sell') ? undefined : B2bRates200ResponseDataSellAPIModelFromJSON(json['sell']),
    };
}

export function B2bRates200ResponseDataAPIModelToJSON(value?: B2bRates200ResponseDataAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buy': B2bRates200ResponseDataBuyAPIModelToJSON(value.buy),
        'sell': B2bRates200ResponseDataSellAPIModelToJSON(value.sell),
    };
}

