import { InputLabel } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  label: {
    fontSize: '13px',
    lineHeight: '17px',
  },
}));

interface LabelProps extends PropsWithChildren {
  'data-test'?: string;
  className?: string;
  style?: React.CSSProperties;
}

const Label: React.FC<LabelProps> = ({ className, children, style, 'data-test': dataTest }) => {
  const { cx, classes } = useStyles();
  return (
    <InputLabel filled className={cx(classes.label, className)} style={style} data-test={dataTest}>
      {children}
    </InputLabel>
  );
};

export default React.memo(Label);
