import React from 'react';

import { NotificationMessage } from '@/components';
import { BaseContainer } from '@/layout/components';
import type { MessageLayoutProps } from '@/layout/MessageLayout/types';

export const MessageLayout: React.FC<MessageLayoutProps> = ({
  height,
  'data-test': dataTest,
  message,
  severity = 'error',
  description,
  ...props
}) => (
  <BaseContainer height={height || 'unset'} {...props}>
    <NotificationMessage data-test={dataTest} severity={severity} title={message}>
      {description}
    </NotificationMessage>
  </BaseContainer>
);

export default MessageLayout;
