import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { FormattedMessage } from '@/components';
import { MoneyAmountValue } from '@/features/dictionary/components';
import { withRatesError, withRatesLoading } from '@/features/purchase/components/RatesSummary/hocs';
import { I18nFeatureBuyPurchase } from '@/generated/i18n/i18n';

import RatesUnavailable from '../RatesUnavailable';

import type { RatesTotalProps } from './types';

const useStyles = makeStyles()(() => ({
  currency: {
    fontWeight: 'bold',
  },
}));

const RatesTotal: React.FC<RatesTotalProps> = ({ 'data-test': dataTest, rates }) => {
  const { classes } = useStyles();
  return rates.data ? (
    <FormattedMessage
      id={I18nFeatureBuyPurchase.COMPONENTS_RATES_SUMMARY_TOTAL}
      values={{
        crypto: (
          <MoneyAmountValue
            value={rates?.data?.toBuy.amount}
            currency={rates.data?.toBuy.currency}
            withCurrency
            currencyClassName={classes.currency}
            data-test={dataTest && `${dataTest}-crypto`}
          />
        ),
        fiat: (
          <MoneyAmountValue
            value={rates?.data?.price.total.amount}
            currency={rates.data?.price.total.currency}
            withCurrency
            currencyClassName={classes.currency}
            data-test={dataTest && `${dataTest}-fiat`}
          />
        ),
      }}
      tagName="span"
    />
  ) : (
    <RatesUnavailable data-test={dataTest} />
  );
};

export default React.memo(withRatesLoading(withRatesError(RatesTotal)));
