import React from 'react';

import { FormattedMessage, NotificationMessage } from '@/components';
import { I18nFeatureMock } from '@/generated/i18n/i18n';

import type { MockBannerProps } from './types';

const MockBanner: React.FC<MockBannerProps> = ({ 'data-test': dataTest }) => (
  <NotificationMessage
    data-test={dataTest}
    severity="warning"
    title={<FormattedMessage id={I18nFeatureMock.COMPONENTS_BANNER_TITLE} />}
  >
    <FormattedMessage id={I18nFeatureMock.COMPONENTS_BANNER_DESCRIPTION} />
  </NotificationMessage>
);

export default React.memo(MockBanner);
