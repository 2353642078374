import React from 'react';

import { MoneyAmountValue } from '@/features/dictionary/components';
import { withRatesError, withRatesLoading } from '@/features/purchase/components/RatesSummary/hocs';

import RatesUnavailable from '../RatesUnavailable';

import type { RatesFeeProps } from './types';

const RatesFee: React.FC<RatesFeeProps> = ({ 'data-test': dataTest, rates }) =>
  rates?.data?.price.fee ? (
    <MoneyAmountValue
      value={rates.data.price.fee.amount}
      currency={rates.data.price.fee.currency}
      withCurrency
      data-test={dataTest}
    />
  ) : (
    <RatesUnavailable data-test={dataTest} />
  );

export default React.memo(withRatesLoading(withRatesError(RatesFee)));
