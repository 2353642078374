import { createReducer } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { addGlobalLockListener } from '@/features/global/utils';
import { createSavedCardTransaction } from '@/features/transaction/actions';
import type { TransactionState } from '@/features/transaction/types';
import { I18nFeatureBuyTransaction } from '@/generated/i18n/i18n';
import { withIgnoreAbortion } from '@/utils/redux';

const initialState: TransactionState = {
  redirectCard: { isDirty: true },
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createSavedCardTransaction.fulfilled, (state, action) => ({
      ...state,
      redirectCard: { data: action.payload, isDirty: false },
    }))
    .addCase(
      createSavedCardTransaction.rejected,
      withIgnoreAbortion((state, action) => ({
        ...state,
        redirectCard: {
          error: action.error?.code || `${action.error}`,
          isDirty: false,
        },
      })),
    );

  // Initialization listeners here for laziness
  listenerMiddleware.startListening(
    addGlobalLockListener(createSavedCardTransaction, I18nFeatureBuyTransaction.PAGE_PREPARE),
  );
});

export default reducer;
