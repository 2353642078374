/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bUserSignIn200ResponseDataAPIModel
 */
export interface B2bUserSignIn200ResponseDataAPIModel {
    /**
     * Bearer token for the following requests
     * @type {string}
     * @memberof B2bUserSignIn200ResponseDataAPIModel
     */
    bearerToken?: string;
    /**
     * User UUID
     * @type {string}
     * @memberof B2bUserSignIn200ResponseDataAPIModel
     */
    userUuid4?: string;
}

/**
 * Check if a given object implements the B2bUserSignIn200ResponseDataAPIModel interface.
 */
export function instanceOfB2bUserSignIn200ResponseDataAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bUserSignIn200ResponseDataAPIModelFromJSON(json: any): B2bUserSignIn200ResponseDataAPIModel {
    return B2bUserSignIn200ResponseDataAPIModelFromJSONTyped(json, false);
}

export function B2bUserSignIn200ResponseDataAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserSignIn200ResponseDataAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bearerToken': !exists(json, 'bearer_token') ? undefined : json['bearer_token'],
        'userUuid4': !exists(json, 'user_uuid4') ? undefined : json['user_uuid4'],
    };
}

export function B2bUserSignIn200ResponseDataAPIModelToJSON(value?: B2bUserSignIn200ResponseDataAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bearer_token': value.bearerToken,
        'user_uuid4': value.userUuid4,
    };
}

