import { Func } from '@/utils/ts';

let errorLogged = false;

const getParentOrigin = () => {
  const origin = document.referrer?.replace(/\/$/, '');
  if (!origin || !window.parent || window.parent === window) {
    if (!errorLogged) {
      console.error('No parent window, unable to interact with parent');
      errorLogged = true;
    }
    return undefined;
  }
  return origin;
};

export const sendMessageToParent = (message: unknown): void => {
  const origin = getParentOrigin();
  if (origin) {
    window.parent.postMessage(message, origin);
  }
};

export const sendMessageToSelf = (message: unknown): void => {
  const origin = window.origin?.replace(/\/$/, '');
  window.parent.postMessage(message, origin);
};

export const startListeningParentMessages = (listener: Func<[unknown]>): void => {
  const origin = getParentOrigin();
  if (origin) {
    const eventListener = async (event: MessageEvent) => {
      if (!event.origin.includes(origin)) {
        return;
      }

      await listener(event.data);
    };
    window.addEventListener('message', eventListener);
  }
};
