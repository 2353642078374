import React from 'react';

import { BaseContainer } from '@/layout/components';
import type { BaseContainerProps } from '@/layout/components';

export type BlankLayoutProps = BaseContainerProps;

export const BlankLayout: React.FC<BlankLayoutProps> = ({ height, ...props }) => (
  <BaseContainer height={height || 'unset'} {...props} />
);

export default BlankLayout;
