/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bSellPciDssCard200ResponseDataAPIModel
 */
export interface B2bSellPciDssCard200ResponseDataAPIModel {
    /**
     * Deposit crypto address
     * @type {string}
     * @memberof B2bSellPciDssCard200ResponseDataAPIModel
     */
    address: string;
    /**
     * Merchant transaction id
     * @type {string}
     * @memberof B2bSellPciDssCard200ResponseDataAPIModel
     */
    merchantTransactionId: string;
    /**
     * Merchant transaction status
     * @type {string}
     * @memberof B2bSellPciDssCard200ResponseDataAPIModel
     */
    status: B2bSellPciDssCard200ResponseDataAPIModelStatusEnum;
}


/**
 * @export
 */
export const B2bSellPciDssCard200ResponseDataAPIModelStatusEnum = {
    pending: 'pending',
    failed: 'failed',
    cancelled: 'cancelled',
    succeeded: 'succeeded'
} as const;
export type B2bSellPciDssCard200ResponseDataAPIModelStatusEnum = typeof B2bSellPciDssCard200ResponseDataAPIModelStatusEnum[keyof typeof B2bSellPciDssCard200ResponseDataAPIModelStatusEnum];


/**
 * Check if a given object implements the B2bSellPciDssCard200ResponseDataAPIModel interface.
 */
export function instanceOfB2bSellPciDssCard200ResponseDataAPIModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "merchantTransactionId" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function B2bSellPciDssCard200ResponseDataAPIModelFromJSON(json: any): B2bSellPciDssCard200ResponseDataAPIModel {
    return B2bSellPciDssCard200ResponseDataAPIModelFromJSONTyped(json, false);
}

export function B2bSellPciDssCard200ResponseDataAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bSellPciDssCard200ResponseDataAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'merchantTransactionId': json['merchant_transaction_id'],
        'status': json['status'],
    };
}

export function B2bSellPciDssCard200ResponseDataAPIModelToJSON(value?: B2bSellPciDssCard200ResponseDataAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'merchant_transaction_id': value.merchantTransactionId,
        'status': value.status,
    };
}

