import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useMemo, useCallback } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { FormattedMessage, PrimaryButton } from '@/components';
import { redirectFromMockMercuryoWidget } from '@/features/mock/actions';
import { kycRequired } from '@/features/transaction/messages';
import { I18nFeatureMock } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';

export default function useMockKYCRequired({ 'data-test': dataTest }: { 'data-test'?: string }) {
  const dispatch = useAppDispatch();
  const [isKYCRequired, setKYCIsRequired] = useStateMountSafe(false);
  const checkCardNumber = useCallback(
    (card: string) => {
      if (card.startsWith('9999')) {
        setKYCIsRequired(true);
        return false;
      }
      return true;
    },
    [setKYCIsRequired],
  );
  const dialog = useMemo(
    () =>
      isKYCRequired ? (
        <Dialog open data-test={dataTest && `${dataTest}-container`} disableEscapeKeyDown>
          <DialogTitle>
            <FormattedMessage id={I18nFeatureMock.PAGES_CARD_KYC_FAILED_TITLE} />
          </DialogTitle>
          <DialogContent dividers>
            <FormattedMessage id={I18nFeatureMock.PAGES_CARD_KYC_FAILED_DESCRIPTION} />
          </DialogContent>
          <DialogActions>
            <PrimaryButton
              data-test={dataTest && `${dataTest}-button`}
              title={<FormattedMessage id={I18nFeatureMock.PAGES_CARD_KYC_BUTTON_TITLE} />}
              onClick={() =>
                dispatch(
                  redirectFromMockMercuryoWidget({
                    success: true,
                    msg: kycRequired,
                  }),
                )
              }
            />
          </DialogActions>
        </Dialog>
      ) : null,
    [dataTest, dispatch, isKYCRequired],
  );

  return { dialog, checkCardNumber };
}
