import { useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { useExplicitKYCRequirement, useKYCLimits, useKYCStatus } from '@/features/kyc/hooks';
import useRates from '@/features/purchase/hooks/useRates';
import { makeSelectPurchase } from '@/features/purchase/selectors';

const selectPurchase = makeSelectPurchase();

const usePurchaseKYCLimits = () => {
  const {
    data: { data: rates },
  } = useRates();
  const { data: kyc } = useKYCStatus();
  const {
    data: { data: isKYCRequiredExplicitly },
  } = useExplicitKYCRequirement();
  const { fiatToPay, cryptoToBuy } = useAppSelector(selectPurchase);
  const fiatLimits = useKYCLimits(fiatToPay);
  const cryptoLimits = useKYCLimits(cryptoToBuy.currency);
  const limits = useMemo(
    () =>
      fiatLimits.data.data || cryptoLimits.data.data
        ? {
            fiat: { ...fiatLimits.data.data, currency: fiatToPay },
            crypto: { ...cryptoLimits.data.data, currency: cryptoToBuy.currency },
          }
        : undefined,
    [fiatLimits.data, cryptoLimits.data, fiatToPay, cryptoToBuy.currency],
  );
  const isFiatLimitHit = useMemo(
    () => !!rates && !!(limits?.fiat?.remain || limits?.fiat?.total)?.lte(rates?.price.total.amount),
    [rates, limits?.fiat],
  );
  const isCryptoLimitHit = useMemo(
    () => !!(limits?.crypto?.remain || limits?.crypto?.total)?.lte(cryptoToBuy.amount),
    [cryptoToBuy.amount, limits?.crypto?.remain, limits?.crypto?.total],
  );
  return useMemo(
    () => ({
      loading: fiatLimits.loading || cryptoLimits.loading,
      forceRefresh: async () => Promise.all([fiatLimits.forceRefresh(), cryptoLimits.forceRefresh()]),
      data: limits,
      isKYCRequired: kyc !== 'complete' && (isKYCRequiredExplicitly || isFiatLimitHit || isCryptoLimitHit),
    }),
    [fiatLimits, cryptoLimits, limits, kyc, isKYCRequiredExplicitly, isFiatLimitHit, isCryptoLimitHit],
  );
};

export default usePurchaseKYCLimits;
