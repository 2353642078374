import React, { useEffect, useState } from 'react';

import * as SentryInitializer from '@/infrastructure/sentry/utils';

const withSentryInitialization =
  <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) => {
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
      if (!initialized) {
        try {
          SentryInitializer.init();
        } finally {
          setInitialized(true);
        }
      }
    }, [initialized]);
    return initialized ? <Component {...props} /> : null;
  };

export default withSentryInitialization;
