/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * NCPS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MercuryoKYCStatusAPIModel = {
    complete: 'complete',
    incomplete: 'incomplete',
    failed: 'failed',
    under_review: 'under_review'
} as const;
export type MercuryoKYCStatusAPIModel = typeof MercuryoKYCStatusAPIModel[keyof typeof MercuryoKYCStatusAPIModel];


export function MercuryoKYCStatusAPIModelFromJSON(json: any): MercuryoKYCStatusAPIModel {
    return MercuryoKYCStatusAPIModelFromJSONTyped(json, false);
}

export function MercuryoKYCStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MercuryoKYCStatusAPIModel {
    return json as MercuryoKYCStatusAPIModel;
}

export function MercuryoKYCStatusAPIModelToJSON(value?: MercuryoKYCStatusAPIModel | null): any {
    return value as any;
}

