import { Divider, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '@/app/hooks';
import { FormattedMessage } from '@/components';
import { withAuthorizationInit } from '@/features/auth/hocs';
import { KYCResultDescription, KYCWidget } from '@/features/kyc/components';
import { useKYCStatus, useKYCStatusPoll } from '@/features/kyc/hooks';
import { makeSelectKYCStatusIsFinal } from '@/features/kyc/selectors';
import { makeSelectIsMockEnabled } from '@/features/mock/selectors';
import { MercuryoKYCStatusAPIModel } from '@/generated/api/ncps/api';
import { I18nFeatureBuyKyc } from '@/generated/i18n/i18n';
import { MainLayout } from '@/layout';
import { BuyPageRoutes, buyRoute } from '@/pages/buy/routes';

const mockEnabledSelector = makeSelectIsMockEnabled();
const isFinalStatusSelector = makeSelectKYCStatusIsFinal();

const KYCPage: React.FC = () => {
  const navigate = useNavigate();
  const mockIsEnabled = useAppSelector(mockEnabledSelector);
  const isFinalStatus = useAppSelector(isFinalStatusSelector);
  const { data: status } = useKYCStatus();
  useKYCStatusPoll();
  useEffect(() => {
    if (status === MercuryoKYCStatusAPIModel.complete) {
      navigate(buyRoute(BuyPageRoutes.PURCHASE));
    }
  }, [navigate, status]);
  const isLowHeight = useMediaQuery('@media (max-height: 450px)');
  return (
    <MainLayout
      header={{
        title: <FormattedMessage id={I18nFeatureBuyKyc.PAGE_TITLE} />,
      }}
      buttons={{
        back: buyRoute(BuyPageRoutes.PURCHASE),
      }}
    >
      {!isLowHeight && <Divider orientation="horizontal" />}
      <KYCResultDescription data-test="kyc-description" />
      {(!isFinalStatus || mockIsEnabled) && (
        <>
          {!isLowHeight && <Divider orientation="horizontal" />}
          <KYCWidget data-test="kyc-frame" />
        </>
      )}
    </MainLayout>
  );
};

export default withAuthorizationInit(KYCPage);
