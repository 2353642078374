import { createSelector } from '@reduxjs/toolkit';

import type { AnyAppAsyncThunk, AppRootState } from '@/app/store';
import { NAMESPACE } from '@/features/global/types';

export const makeSelectNotifications = () => (state: AppRootState) => state[NAMESPACE].notifications;

export const makeSelectGlobalLoading = () => (state: AppRootState) => state[NAMESPACE].loading;

export const makeSelectRequestURLProcessed = () => (state: AppRootState) =>
  state[NAMESPACE].initializing.isURLProcessed;

export const makeSelectWaitingForInitCall = () => (state: AppRootState) =>
  state[NAMESPACE].initializing.isWaitingForInitCall;

export const makeSelectRequestInitialized = () =>
  createSelector(
    makeSelectRequestURLProcessed(),
    makeSelectWaitingForInitCall(),
    (isURLProcessed, isWaitingForInitCall) => isURLProcessed && !isWaitingForInitCall,
  );

export const makeSelectRequest = () => (state: AppRootState) => state[NAMESPACE].initializing.value;

export const makeSelectLocaleInitializing = () => (state: AppRootState) => state[NAMESPACE].locale.status;
export const makeSelectLocale = () => (state: AppRootState) => state[NAMESPACE].locale.value;
export const makeSelectViewHeight = () => (state: AppRootState) => state[NAMESPACE].vh;

export const makeSelectPending =
  <Returned, ThunkArg>(action: AnyAppAsyncThunk<Returned, ThunkArg>) =>
  (state: AppRootState) =>
    !!state[NAMESPACE].pending[action.typePrefix]?.length;
