import { Backdrop, Theme } from '@mui/material';
import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import { FormattedMessage, ProgressBox } from '@/components';
import { I18nCommon, I18nMessages } from '@/generated/i18n/i18n';

const useStyles = makeStyles()((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#ffffff',
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  progress: {
    width: '320px',
    height: '224px',
    borderRadius: '12px',
    padding: theme.spacing(2),
  },
}));

const noop = (e: Event): void => {
  e.preventDefault();
  e.stopPropagation();
};

const GlobalBackdrop: React.FC<{ isLoading: boolean; messageId?: I18nMessages }> = ({ isLoading, messageId }) => {
  const { classes } = useStyles();
  useEffect(() => {
    if (isLoading) {
      window.addEventListener('keyup', noop, false);
      window.addEventListener('keydown', noop, false);
    }
    return (): void => {
      window.removeEventListener('keyup', noop, false);
      window.removeEventListener('keydown', noop, false);
    };
  }, [isLoading]);

  return isLoading ? (
    <Backdrop className={classes.backdrop} open={isLoading} data-test="backdrop-container">
      <ProgressBox
        data-test="backdrop-content"
        title={<FormattedMessage id={I18nCommon.BACKDROP_TITLE} />}
        details={messageId && <FormattedMessage id={messageId} />}
        className={classes.progress}
      />
    </Backdrop>
  ) : null;
};

export default React.memo(GlobalBackdrop);
