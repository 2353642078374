import { Button } from '@mui/material';
import React, { ReactNode } from 'react';

import type { Func } from '@/utils/ts';

export interface SecondaryButtonProps {
  'data-test'?: string;
  className?: string;
  title: ReactNode;
  onClick?: Func;
  disabled?: boolean;
  submit?: boolean;
}

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  title,
  className,
  onClick,
  disabled,
  submit,
  'data-test': dataTest,
}) => (
  <Button
    data-test={dataTest}
    className={className}
    variant="outlined"
    color="secondary"
    onClick={onClick}
    disabled={disabled}
    {...(submit ? { type: 'submit' } : {})}
  >
    {title}
  </Button>
);

export default React.memo(SecondaryButton);
