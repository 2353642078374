import React from 'react';

import RatesUnavailable from '@/features/purchase/components/RatesSummary/components/RatesUnavailable';
import type { Rate } from '@/features/purchase/types';
import type { LoadingStateWithDirty } from '@/utils/model';
import type { TestableProps } from '@/utils/react';

const withRatesError =
  <P extends TestableProps & { rates: LoadingStateWithDirty<Rate> }>(Component: React.FC<P>): React.FC<P> =>
  (props: P) =>
    props.rates.error ? <RatesUnavailable data-test={props['data-test']} /> : <Component {...props} />;

export default withRatesError;
