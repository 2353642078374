import ms from 'ms';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useUserData } from '@/features/auth/hooks';
import { useMockKYCData } from '@/features/mock/hooks';
import { makeSelectIsMockEnabled } from '@/features/mock/selectors';

const mockEnabledSelector = makeSelectIsMockEnabled();
const pollTimeout = ms(window.env.KYC_POLL_PERIOD);

const useKYCStatusPoll = () => {
  const { forceRefresh: refreshUserData } = useUserData();
  const { forceRefresh: refreshMockKYCData } = useMockKYCData();
  const mockIsEnabled = useSelector(mockEnabledSelector);
  useEffect(() => {
    const timer = setInterval(async () => {
      if (mockIsEnabled) {
        await refreshMockKYCData();
      } else {
        await refreshUserData();
      }
    }, pollTimeout);
    return () => clearInterval(timer);
  });
};

export default useKYCStatusPoll;
