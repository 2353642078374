import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { storeViewHeight } from '@/features/global/actions';

const withMobileDetection =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props: P) => {
    const dispatch = useDispatch();

    const vhSaved = useRef<number | undefined>();
    const updateVHState = useCallback((): void => {
      // mobile address bar trick: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
      const vh = window.innerHeight * 0.01;
      if (vh !== vhSaved.current) {
        vhSaved.current = vh;
        dispatch(storeViewHeight(vh));
      }
    }, [dispatch]);
    useEffect(() => {
      if (vhSaved.current === undefined) {
        updateVHState();
      }
      window.addEventListener('resize', updateVHState);
      return (): void => window.removeEventListener('resize', updateVHState);
    });

    return <Component {...props} />;
  };

export default withMobileDetection;
