export interface JsonCache<T> {
  save: (data: T) => void;
  read: () => T | undefined;
  clear: () => void;
}

const localCache: Record<string, unknown> = {};

const modeHolder: { mode: 'ls' | 'mem' } = { mode: 'ls' };

export const setCacheMode = (mode: 'ls' | 'mem'): void => {
  modeHolder.mode = mode;
};

const createCache = <T>(key: string): JsonCache<T> => ({
  save: (data: T): void => {
    if (modeHolder.mode === 'ls') {
      localStorage.setItem(key, JSON.stringify(data));
    } else {
      localCache[key] = data;
    }
  },
  read: (): T | undefined => {
    if (modeHolder.mode === 'ls') {
      const lsItem = localStorage.getItem(key);
      return lsItem ? (JSON.parse(lsItem) as T) : undefined;
    }

    return localCache[key] ? (localCache[key] as T) : undefined;
  },
  clear: (): void => {
    if (modeHolder.mode === 'ls') {
      localStorage.removeItem(key);
    } else {
      delete localCache[key];
    }
  },
});

export default createCache;
