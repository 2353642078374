import React from 'react';

import ErrorBoundary from './components/ErrorBoundary';

const withErrorBoundary =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props: P) =>
    (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    );

export default withErrorBoundary;
