import { useEffect } from 'react';

import type { Func } from '@/utils/ts';

export default function useFirstRenderEffect(func: Func): void {
  useEffect(() => {
    func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
