import type { LoadingStateWithDirty } from '@/utils/model';

export const NAMESPACE = 'kyc';

export interface KYCIFrameData {
  applicantId: string;
  kycToken: string;
  expiresAt: string; // Date
}

export type CurrencyKYCLimits = Partial<{
  remain: string; // "393.23"
  total: string; // "699.00"
}>;

export type KYCLimits = Partial<Record<string, CurrencyKYCLimits>>;

export interface KYCState {
  level1: LoadingStateWithDirty<KYCIFrameData>;
  level2: LoadingStateWithDirty<KYCIFrameData>;
  limits: LoadingStateWithDirty<KYCLimits>;
  explicitlyRequired: LoadingStateWithDirty<boolean>; // KYC has been requested explicitly, i.e. by fraud check
}
