import { Link as MuiLink, styled, Theme } from '@mui/material';
import React, { MouseEvent, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { noop } from '@/utils/functions';

import type { ButtonLinkProps } from './types';

const Link: typeof MuiLink & { disabled?: boolean } = styled(MuiLink)(({ disabled }: { disabled?: boolean }) =>
  disabled
    ? {
        color: 'grey',
        cursor: 'not-allowed',
        '&:hover': {
          textDecoration: 'none',
        },
      }
    : {},
);

const useStyles = makeStyles()((theme: Theme) => ({
  secondary: {
    color: theme.colors.primary,
  },
}));

const ButtonLink: React.FC<ButtonLinkProps> = ({
  className,
  style,
  disabled,
  onClick: baseOnClick,
  type = 'primary',
  'data-test': dataTest,
  children,
}) => {
  const { cx, classes } = useStyles();
  const onClick = useMemo(
    () =>
      !disabled
        ? async (e: MouseEvent): Promise<void> => {
            e.preventDefault();
            baseOnClick();
          }
        : noop,
    [baseOnClick, disabled],
  );

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      data-test={dataTest}
      variant="body1"
      component="button"
      className={cx(className, type === 'secondary' && classes.secondary)}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {children}
    </Link>
  );
};

export default React.memo(ButtonLink);
