import { fetchUser } from '@/features/auth/actions';
import { makeSelectUser } from '@/features/auth/selectors';
import { useLoadableData } from '@/hooks';

const fetch = (force: boolean) => fetchUser({ force });
const selector = makeSelectUser();

const useUserData = () => useLoadableData(fetch, selector);

export default useUserData;
