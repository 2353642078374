import { CircularProgress, Stack, Theme } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { Label, SecondaryTitle } from '@/components/typography';

import { ProgressBoxProps } from './types';

const useStyles = makeStyles()((theme: Theme) => ({
  mainContainer: {
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    margin: 'auto',
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.primary,
    textAlign: 'center',
  },
  circular: {
    color: theme.colors.secondaryContrast,
  },
  messages: {
    alignItems: 'center',
  },
}));

const ProgressBox: React.FC<ProgressBoxProps> = ({ className, title, details, 'data-test': dataTest }) => {
  const { classes, cx } = useStyles();

  return (
    <Stack direction="column" spacing={3} className={cx(classes.mainContainer, className)}>
      <CircularProgress color="inherit" className={classes.circular} />
      <Stack direction="column" className={classes.messages}>
        <SecondaryTitle data-test={dataTest && `${dataTest}-title`}>{title}</SecondaryTitle>
        {details && <Label data-test={dataTest && `${dataTest}-details`}>{details}</Label>}
      </Stack>
    </Stack>
  );
};

export default React.memo(ProgressBox);
