import ReplayIcon from '@mui/icons-material/Replay';
import { IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useMemo } from 'react';

import { FormattedMessage, LeftRightPositioning, SecondaryTitle } from '@/components';
import { useRates } from '@/features/purchase/hooks';
import { I18nFeatureBuyPurchase } from '@/generated/i18n/i18n';
import { useTimer } from '@/hooks';

import type { RatesSummaryTitleProps } from './types';

const RatesSummaryTitle: React.FC<RatesSummaryTitleProps> = ({ 'data-test': dataTest }) => {
  const {
    data: { data: rates, isDirty, error },
    loading,
    forceRefresh,
  } = useRates();
  const expiresAt = useMemo(() => (rates?.expiresAt ? new Date(rates?.expiresAt) : undefined), [rates?.expiresAt]);
  const [secondsToExpire] = useTimer(expiresAt);
  const toExpire = useMemo(
    () => moment.utc(moment.duration(secondsToExpire, 's').asMilliseconds()).format('mm:ss'),
    [secondsToExpire],
  );
  return (
    <LeftRightPositioning
      left={
        <>
          <SecondaryTitle data-test={dataTest && `${dataTest}-title`}>
            <FormattedMessage id={I18nFeatureBuyPurchase.COMPONENTS_SUMMARY_TITLE} tagName="span" />
          </SecondaryTitle>
          {!isDirty && !loading && !error && (
            <Tooltip
              title={
                <FormattedMessage
                  id={I18nFeatureBuyPurchase.COMPONENTS_SUMMARY_REFRESH_TITLE}
                  values={{ toExpire }}
                  tagName="span"
                />
              }
            >
              <span>({toExpire})</span>
            </Tooltip>
          )}
        </>
      }
      right={
        error && (
          <Tooltip
            title={<FormattedMessage id={I18nFeatureBuyPurchase.COMPONENTS_SUMMARY_RETRY_DESCRIPTION} tagName="span" />}
          >
            <IconButton
              data-test={dataTest && `${dataTest}-retry`}
              onClick={forceRefresh}
              disabled={loading || isDirty}
            >
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        )
      }
    />
  );
};

export default React.memo(RatesSummaryTitle);
