/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel
 */
export interface B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel {
    /**
     * Recipient country
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel
     */
    beneficiaryCountry?: string;
    /**
     * Recipient IBAN
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel
     */
    beneficiaryIban?: string;
    /**
     * Recipient name
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel
     */
    beneficiaryName?: string;
    /**
     * Recipient swift
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel
     */
    beneficiarySwift?: string;
    /**
     * Date of creation IBAN recipient data
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel
     */
    createdAt?: string;
    /**
     * Sender IBAN
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel
     */
    senderIban?: string;
    /**
     * Status of recipient data
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel
     */
    status?: string;
    /**
     * Date of update IBAN recipient data
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel
     */
    updatedAt?: string;
}

/**
 * Check if a given object implements the B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel interface.
 */
export function instanceOfB2bTransactions200ResponseDataInnerRecipientsInnerAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bTransactions200ResponseDataInnerRecipientsInnerAPIModelFromJSON(json: any): B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel {
    return B2bTransactions200ResponseDataInnerRecipientsInnerAPIModelFromJSONTyped(json, false);
}

export function B2bTransactions200ResponseDataInnerRecipientsInnerAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'beneficiaryCountry': !exists(json, 'beneficiary_country') ? undefined : json['beneficiary_country'],
        'beneficiaryIban': !exists(json, 'beneficiary_iban') ? undefined : json['beneficiary_iban'],
        'beneficiaryName': !exists(json, 'beneficiary_name') ? undefined : json['beneficiary_name'],
        'beneficiarySwift': !exists(json, 'beneficiary_swift') ? undefined : json['beneficiary_swift'],
        'createdAt': !exists(json, 'created_at') ? undefined : json['created_at'],
        'senderIban': !exists(json, 'sender_iban') ? undefined : json['sender_iban'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updated_at') ? undefined : json['updated_at'],
    };
}

export function B2bTransactions200ResponseDataInnerRecipientsInnerAPIModelToJSON(value?: B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'beneficiary_country': value.beneficiaryCountry,
        'beneficiary_iban': value.beneficiaryIban,
        'beneficiary_name': value.beneficiaryName,
        'beneficiary_swift': value.beneficiarySwift,
        'created_at': value.createdAt,
        'sender_iban': value.senderIban,
        'status': value.status,
        'updated_at': value.updatedAt,
    };
}

