import { Divider } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormattedMessage } from '@/components';
import { withAuthorizationInit } from '@/features/auth/hocs';
import type { NewCard } from '@/features/cards/types';
import { MockBanner, NewCardInput } from '@/features/mock/components';
import { withMockInitialization, withMockSupported, withMockTxData } from '@/features/mock/hocs';
import { I18nFeatureMock } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { MainLayout } from '@/layout';
import { MockPageRoutes, mockRoute } from '@/pages/mock/routes';
import type { Value } from '@/utils/model';

import { useMockKYCRequired } from './hooks';

const MockKYCPage: React.FC = () => {
  const navigate = useNavigate();
  const [newCard, setNewCard] = useStateMountSafe<Value<NewCard & { cvv: string }>>({ hasError: false });
  const { dialog, checkCardNumber } = useMockKYCRequired({ 'data-test': 'dialog' });
  const doAct = useCallback(async () => {
    if (newCard.value?.number && checkCardNumber(newCard.value?.number)) {
      navigate(mockRoute(MockPageRoutes.CARD_3DS));
    }
  }, [checkCardNumber, navigate, newCard.value?.number]);
  return (
    <MainLayout
      header={{
        title: <FormattedMessage id={I18nFeatureMock.PAGES_CARD_TITLE} />,
      }}
      buttons={{
        main: {
          title: <FormattedMessage id={I18nFeatureMock.PAGES_CARD_SUBMIT} />,
          disabled: newCard.hasError || !newCard.value,
          submit: false,
          onAct: doAct,
        },
      }}
    >
      {dialog}
      <MockBanner data-test="mock-banner" />
      <FormattedMessage id={I18nFeatureMock.PAGES_CARD_DESCRIPTION} />
      <Divider orientation="horizontal" />
      <NewCardInput onChange={setNewCard} />
    </MainLayout>
  );
};

export default withAuthorizationInit(withMockInitialization(withMockSupported(withMockTxData(MockKYCPage))));
