import { Popper, styled, Tooltip as BaseTooltip, TooltipProps as BaseTooltipProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';

export type TooltipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

const LightPopper = styled(Popper)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.primary,
    boxShadow: '0px 0.5px 1px rgba(20, 18, 51, 0.1), 0px 4px 8px rgba(20, 18, 51, 0.2)',
    borderRadius: 6,
    fontWeight: 400,
    fontSize: 13,
    lineHeight: 17,
    padding: 12,
    '& .MuiTooltip-arrow': {
      color: theme.colors.primaryBackground,
    },
  },
}));

const DarkPopper = styled(Popper)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: `${theme.colors.primary}D9`,
    color: theme.colors.primaryBackground,
    boxShadow: '0px 0.5px 1px rgba(20, 18, 51, 0.1), 0px 4px 8px rgba(20, 18, 51, 0.2)',
    borderRadius: 6,
    fontWeight: 400,
    fontWize: 13,
    lineHeight: 17,
    padding: 12,
    '& .MuiTooltip-arrow': {
      color: `${theme.colors.primary}D9`,
    },
  },
}));

interface TooltipProps extends PropsWithChildren<BaseTooltipProps> {
  type?: 'light' | 'dark';
}

const Tooltip: React.FC<TooltipProps> = ({ children, type = 'dark', ...props }: TooltipProps) => (
  <BaseTooltip leaveTouchDelay={5000} arrow PopperComponent={type === 'light' ? LightPopper : DarkPopper} {...props}>
    {children}
  </BaseTooltip>
);

export default React.memo(Tooltip);
