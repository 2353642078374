import React from 'react';

import { useAppSelector } from '@/app/hooks';
import { FormattedMessage, NotificationMessage } from '@/components';
import { makeSelectCallbackStatus } from '@/features/callback/selectors';
import { I18nFeatureCallback } from '@/generated/i18n/i18n';

import type { MockBannerProps } from './types';

const selectCallbackStatus = makeSelectCallbackStatus();

const CallbackBanner: React.FC<MockBannerProps> = ({ 'data-test': dataTest }) => {
  const status = useAppSelector(selectCallbackStatus);
  return (
    <NotificationMessage
      data-test={dataTest}
      severity={status === 'processing' ? 'info' : 'error'}
      title={
        <FormattedMessage
          id={
            status === 'processing'
              ? I18nFeatureCallback.COMPONENTS_BANNER_INPROGRESS_TITLE
              : I18nFeatureCallback.COMPONENTS_BANNER_ERROR_TITLE
          }
        />
      }
    >
      <FormattedMessage
        id={
          status === 'processing'
            ? I18nFeatureCallback.COMPONENTS_BANNER_INPROGRESS_DESCRIPTION
            : I18nFeatureCallback.COMPONENTS_BANNER_ERROR_DESCRIPTION
        }
      />
    </NotificationMessage>
  );
};

export default React.memo(CallbackBanner);
