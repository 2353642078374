import { createSavedCardTransaction } from '@/features/transaction/actions';
import { makeSelectRedirectCard } from '@/features/transaction/selectors';
import { useLoadableData } from '@/hooks';

const fetch = () => createSavedCardTransaction();
const selector = makeSelectRedirectCard();

const useTransactionDataURL = () => useLoadableData(fetch, selector);

export default useTransactionDataURL;
