import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/app/hooks';
import { FormattedMessage } from '@/components';
import { GlobalBackdrop } from '@/features/global/components';
import { initialize } from '@/features/mock/actions';
import { makeSelectMockInit } from '@/features/mock/selectors';
import { I18nFeatureMock } from '@/generated/i18n/i18n';
import { useFetching } from '@/hooks';
import { MessageLayout } from '@/layout';
import { InitStatus } from '@/utils/model';

const selectInitialization = makeSelectMockInit();

const withMockInitialization =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props: P) => {
    const dispatch = useAppDispatch();
    const initState = useSelector(selectInitialization);
    const init = useCallback(async () => dispatch(initialize()), [dispatch]);
    useFetching(init, initState.status === InitStatus.NOT_INITIALIZED);
    // eslint-disable-next-line no-nested-ternary
    return initState.status === InitStatus.FINISHED ? (
      initState.value ? (
        <Component {...props} />
      ) : (
        <MessageLayout
          message={<FormattedMessage id={I18nFeatureMock.ERROR_TITLE} />}
          description={<FormattedMessage id={I18nFeatureMock.ERROR_DESCRIPTION} />}
        />
      )
    ) : (
      <GlobalBackdrop isLoading />
    );
  };

export default withMockInitialization;
