import * as Sentry from '@sentry/browser';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import redact from 'redact-object';

import type { BrowserOptions } from '@sentry/browser';
import type { Integration } from '@sentry/types';

export const isEnabled = window.env.SENTRY_USE === 'true';

export const sensitiveKeys = [
  'url',
  'key',
  'token',
  'secret',
  'accessToken',
  'access_token',
  'access-token',
  'refreshToken',
  'refresh_token',
  'refresh-token',
  // 'code',
  'authorization_code',
  'password',
  'bearer-token',
  'B2b-Bearer-Token',
  'mercuryo-partner',
  // 'user',
  // 'email',
  'card',
  'cvv',
  'cvc',
];

const replaceSensitiveDataFromPath = (path?: unknown): any =>
  typeof path === 'string'
    ? path
        .replace(/(.*)([?|&]userToken=)([^&]*)(&*)/g, '$1$2<sensitive>$4')
        .replace(/(.*)([?|&]token=)([^&]*)(&*)/g, '$1$2<sensitive>$4')
    : path;

let baseConfig: BrowserOptions | undefined;

export const init = (): void => {
  if (!isEnabled || !!baseConfig) {
    return;
  }

  const dsn = window.env.SENTRY_DSN;
  if (!dsn) {
    console.warn('Sentry initialization failed: DSN is not set.');
    return;
  }

  const release = window.env.SENTRY_RELEASE;
  if (!release) {
    console.warn('Sentry initialization failed: release is not set.');
    return;
  }

  const environment = window.env.ENVIRONMENT;
  if (!environment) {
    console.warn('Sentry initialization failed: environment is not set.');
    return;
  }

  baseConfig = {
    release,
    dsn,
    environment,
    integrations: [new CaptureConsoleIntegration({ levels: ['error'] })],
    normalizeDepth: 10,
    beforeBreadcrumb: (breadcrumb) => {
      if (breadcrumb.category === 'navigation') {
        if (typeof breadcrumb.data === 'object') {
          const from = replaceSensitiveDataFromPath(breadcrumb.data?.from);
          const to = replaceSensitiveDataFromPath(breadcrumb.data?.to);
          return {
            ...breadcrumb,
            data: {
              ...breadcrumb.data,
              from,
              to,
            },
          };
        }
      } else if (breadcrumb.category === 'xhr') {
        return {
          ...breadcrumb,
          data: {
            ...breadcrumb.data,
            url: replaceSensitiveDataFromPath(breadcrumb.data?.url),
          },
        };
      }
      return breadcrumb;
    },
    beforeSend: async (event) =>
      redact(event, sensitiveKeys, (value, key) =>
        key === 'url' && typeof value === 'string' ? replaceSensitiveDataFromPath(value) : '<sensitive>',
      ),
  };
  Sentry.init(baseConfig);
};

export const addIntegration = (integrations: Integration[]): void => {
  if (!baseConfig) {
    return;
  }
  integrations.map((integration) => Sentry.getCurrentHub().getClient()?.addIntegration?.(integration));
};
