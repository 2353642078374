import React, { PropsWithChildren } from 'react';

import { Notifications } from '../../components';

const withGlobalNotifications =
  <P extends PropsWithChildren>(
    Component: React.FC<
      Omit<P, 'children'> & {
        children?: PropsWithChildren['children'];
      }
    >,
  ): React.FC<P> =>
  ({ children, ...props }) =>
    (
      <Component {...props}>
        <Notifications />
        {children}
      </Component>
    );

export default withGlobalNotifications;
