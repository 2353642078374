/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * NCPS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EmptyRespAPIModel,
  KYCRequiredRespAPIModel,
} from '../models';
import {
    EmptyRespAPIModelFromJSON,
    EmptyRespAPIModelToJSON,
    KYCRequiredRespAPIModelFromJSON,
    KYCRequiredRespAPIModelToJSON,
} from '../models';

/**
 * MercuryoWidgetApi - interface
 * 
 * @export
 * @interface MercuryoWidgetApiInterface
 */
export interface MercuryoWidgetApiInterface {
    /**
     * Return the mark the user KYC is implicitly required.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MercuryoWidgetApiInterface
     */
    getKYCRequiredRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KYCRequiredRespAPIModel>>;

    /**
     * Return the mark the user KYC is implicitly required.
     * 
     */
    getKYCRequired(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KYCRequiredRespAPIModel>;

    /**
     * Marks user KYC is not explicitly required anymore.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MercuryoWidgetApiInterface
     */
    markKYCOptionalRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyRespAPIModel>>;

    /**
     * Marks user KYC is not explicitly required anymore.
     * 
     */
    markKYCOptional(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyRespAPIModel>;

    /**
     * Marks user KYC is explicitly required.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MercuryoWidgetApiInterface
     */
    markKYCRequiredRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KYCRequiredRespAPIModel>>;

    /**
     * Marks user KYC is explicitly required.
     * 
     */
    markKYCRequired(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KYCRequiredRespAPIModel>;

}

/**
 * 
 */
export class MercuryoWidgetApi extends runtime.BaseAPI implements MercuryoWidgetApiInterface {

    /**
     * Return the mark the user KYC is implicitly required.
     * 
     */
    async getKYCRequiredRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KYCRequiredRespAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("mercuryo-partner-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mercuryo/widget/kyc`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KYCRequiredRespAPIModelFromJSON(jsonValue));
    }

    /**
     * Return the mark the user KYC is implicitly required.
     * 
     */
    async getKYCRequired(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KYCRequiredRespAPIModel> {
        const response = await this.getKYCRequiredRaw(initOverrides);
        return await response.value();
    }

    /**
     * Marks user KYC is not explicitly required anymore.
     * 
     */
    async markKYCOptionalRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmptyRespAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("mercuryo-partner-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mercuryo/widget/kyc`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmptyRespAPIModelFromJSON(jsonValue));
    }

    /**
     * Marks user KYC is not explicitly required anymore.
     * 
     */
    async markKYCOptional(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmptyRespAPIModel> {
        const response = await this.markKYCOptionalRaw(initOverrides);
        return await response.value();
    }

    /**
     * Marks user KYC is explicitly required.
     * 
     */
    async markKYCRequiredRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KYCRequiredRespAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("mercuryo-partner-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mercuryo/widget/kyc`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KYCRequiredRespAPIModelFromJSON(jsonValue));
    }

    /**
     * Marks user KYC is explicitly required.
     * 
     */
    async markKYCRequired(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KYCRequiredRespAPIModel> {
        const response = await this.markKYCRequiredRaw(initOverrides);
        return await response.value();
    }

}
