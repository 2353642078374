import React from 'react';

import { FormattedMessage, SupportEmail } from '@/components';
import { useKYCStatus } from '@/features/kyc/hooks';
import { MercuryoKYCStatusAPIModel } from '@/generated/api/ncps/api';
import { I18nFeatureBuyKyc } from '@/generated/i18n/i18n';

import type { KYCResultDescriptionProps } from './types';

const KYCResultDescription: React.FC<KYCResultDescriptionProps> = () => {
  const { data: status } = useKYCStatus();
  if (status) {
    switch (status) {
      case MercuryoKYCStatusAPIModel.failed:
        return (
          <FormattedMessage
            id={I18nFeatureBuyKyc.COMPONENTS_RESULT_STATUSES_FAILED_MESSAGE}
            values={{ email: <SupportEmail /> }}
          />
        );
      case MercuryoKYCStatusAPIModel.under_review:
        return <FormattedMessage id={I18nFeatureBuyKyc.COMPONENTS_RESULT_STATUSES_UNDERREVIEW_MESSAGE} />;
      case MercuryoKYCStatusAPIModel.complete:
        return <FormattedMessage id={I18nFeatureBuyKyc.COMPONENTS_RESULT_STATUSES_COMPLETE_MESSAGE} />;
      default:
        break;
    }
  }

  return <FormattedMessage id={I18nFeatureBuyKyc.COMPONENTS_RESULT_STATUSES_INCOMPLETE_MESSAGE} />;
};

export default React.memo(KYCResultDescription);
