import React from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';

import { AppPageRoutes } from '@/pages/routes';

import PagesKYC from './kyc';
import PagesPurchase from './purchase';
import { BuyPageRoutes, buyRoute } from './routes';
import PagesTransaction from './transaction';

export const BuyDefaultRoute: React.FC = () => {
  const location = useLocation();
  console.warn(`URL not found: ${location?.pathname}`);
  return <Navigate to={buyRoute(BuyPageRoutes.PURCHASE)} />;
};

export default [
  <Route path={AppPageRoutes.BUY} key={AppPageRoutes.BUY}>
    <Route path={BuyPageRoutes.KYC} Component={PagesKYC} />
    <Route path={BuyPageRoutes.PURCHASE} Component={PagesPurchase} />
    <Route path={BuyPageRoutes.TRANSACTION} Component={PagesTransaction} />
    <Route path="*" Component={BuyDefaultRoute} />
    <Route path="" Component={BuyDefaultRoute} />
  </Route>,
];
