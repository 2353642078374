import { createAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import ms from 'ms';

import { createAppAsyncThunk } from '@/app/actions';
import { fetchCurrencies } from '@/features/dictionary/actions';
import { makeSelectCurrency } from '@/features/dictionary/selectors';
import { makeSelectRequest } from '@/features/global/selectors';
import { withAPICall } from '@/utils/api';
import { CommonLoadingState, flatMapLoadingState, mapLoadingState, storedDataError } from '@/utils/model';
import { asType } from '@/utils/ts';

import { queryMinAmount, queryPurchaseLimits, queryRates } from './api';
import {
  makeSelectCardLimitsDirtiness,
  makeSelectPurchase,
  makeSelectRates,
  makeSelectPurchaseKey,
  makeSelectRatePending,
  makeSelectRateDirtiness,
} from './selectors';
import { Crypto, Rate, NAMESPACE, PurchaseLimits } from './types';
import { createLimitsPairKey } from './utils';

export const markRateDirty = createAction<{ purchaseKey: string }>(`${NAMESPACE}/markRateDirty`);

export const storeCryptoToBuy = createAction<Crypto>(`${NAMESPACE}/storeCryptoToBuy`);
export const storeSelectedCard = createAction<string | undefined>(`${NAMESPACE}/storeSelectedCard`);

export const storeCardLimits = createAction<{
  pair: string;
  data: CommonLoadingState<PurchaseLimits>;
}>(`${NAMESPACE}/storeCardLimits`);
export const markCardLimitsDirty = createAction<{ pair: string }>(`${NAMESPACE}/markCardLimitsDirty`);

export const initPurchase = createAppAsyncThunk(`${NAMESPACE}/initPurchase`, async (_, { dispatch, getState }) => {
  const widgetInitState = makeSelectRequest()(getState());
  const buy: Crypto | undefined =
    widgetInitState.buyAmount && widgetInitState.buyCurrency
      ? {
          currency: widgetInitState.buyCurrency,
          amount: widgetInitState.buyAmount,
          network: widgetInitState.buyNetwork,
        }
      : undefined;
  const { fiatCurrency, address } = widgetInitState;
  if (!buy || !fiatCurrency || !address) {
    console.error('Required data is not defined', buy, fiatCurrency, address);
    throw new Error('no-req-data');
  }
  await dispatch(fetchCurrencies({}));
  const cryptoCurrency = makeSelectCurrency(buy.currency)(getState());
  const minAmount = mapLoadingState(await withAPICall(queryMinAmount)(fiatCurrency, buy), (amount) =>
    cryptoCurrency.data
      ? {
          ...amount,
          amount: new BigNumber(amount.amount)
            .toFixed(cryptoCurrency.data.digitsToDisplay, BigNumber.ROUND_CEIL)
            .toString(),
        }
      : amount,
  );

  return {
    buy: minAmount.data || buy,
    fiatCurrency,
  };
});

export const fetchRates = createAppAsyncThunk(
  `${NAMESPACE}/fetchRates`,
  async ({ purchaseKey }: { force: boolean; purchaseKey: string }, { dispatch, getState }) => {
    const purchase = makeSelectPurchase()(getState());
    const fetchedRates = await queryRates(purchase.fiatToPay, purchase.cryptoToBuy);

    const msToExpire = ms(window.env.RATE_EXPIRATION_PERIOD);
    const rate: Rate = {
      toBuy: {
        amount: fetchedRates.amount || '0',
        currency: fetchedRates.currency || purchase.cryptoToBuy.currency,
        network: purchase.cryptoToBuy.network,
      },
      token: fetchedRates.trxToken || '',
      expiresAt: moment().add(msToExpire, 'ms').toDate().toISOString(),
      price: {
        fee: { amount: fetchedRates.fee?.[purchase.fiatToPay] || '0', currency: purchase.fiatToPay },
        subtotal: {
          amount: fetchedRates.subtotal?.[purchase.fiatToPay] || '0',
          currency: purchase.fiatToPay,
        },
        total: { amount: fetchedRates.total?.[purchase.fiatToPay] || '0', currency: purchase.fiatToPay },
      },
    };
    setTimeout(() => {
      const newRates = makeSelectRates()(getState());
      if (newRates.data?.token !== rate.token) {
        return;
      }
      dispatch(markRateDirty({ purchaseKey }));
    }, msToExpire);
    return { rate, purchaseKey };
  },
  {
    idGenerator: ({ purchaseKey }) => purchaseKey,
    condition: ({ force, purchaseKey }, { getState }) => {
      // Is the requested rate for the active purchase?
      const storedPurchaseKey = makeSelectPurchaseKey()(getState());
      if (purchaseKey !== storedPurchaseKey) {
        return false;
      }
      // Is the requested rate not pending?
      const isRatePending = makeSelectRatePending(purchaseKey)(getState());
      if (isRatePending) {
        return false;
      }
      // Is the requested rate is already calculated and not dirty?
      const isDirty = makeSelectRateDirtiness()(getState());
      return isDirty || force;
    },
  },
);

export const fetchCardLimits = createAppAsyncThunk(
  `${NAMESPACE}/fetchCardLimits`,
  async (
    { force, fiatToPay, cryptoToBuy }: { force: boolean; fiatToPay: string; cryptoToBuy: string },
    { dispatch, getState },
  ) => {
    const pair = createLimitsPairKey(fiatToPay, cryptoToBuy);
    if (!force) {
      const isDirty = makeSelectCardLimitsDirtiness(pair)(getState());
      if (!isDirty) {
        return;
      }
    }
    try {
      const response = await withAPICall(queryPurchaseLimits)(fiatToPay, cryptoToBuy);
      const data: CommonLoadingState<PurchaseLimits> = flatMapLoadingState(response, ({ currenciesLimits }) => ({
        data: currenciesLimits
          ? Object.entries(currenciesLimits).reduce(
              (result, [currency, v]) => ({
                ...result,
                [currency]: v,
              }),
              asType<PurchaseLimits>({}),
            )
          : {},
      }));
      dispatch(storeCardLimits({ pair, data }));
    } catch (e) {
      console.error(e);
      dispatch(storeCardLimits({ pair, data: storedDataError(`${e}`) }));
    }
  },
);
