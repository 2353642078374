/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bOorBuyRates200ResponseDataAPIModel
 */
export interface B2bOorBuyRates200ResponseDataAPIModel {
    /**
     * Crypto amount
     * @type {string}
     * @memberof B2bOorBuyRates200ResponseDataAPIModel
     */
    amount?: string;
    /**
     * Crypto currency
     * @type {string}
     * @memberof B2bOorBuyRates200ResponseDataAPIModel
     */
    currency?: string;
    /**
     * The total fee includes Mercuryo’s fees and merchant’s fees
     * @type {{ [key: string]: string; }}
     * @memberof B2bOorBuyRates200ResponseDataAPIModel
     */
    fee?: { [key: string]: string; };
    /**
     * Fiat amount
     * @type {string}
     * @memberof B2bOorBuyRates200ResponseDataAPIModel
     */
    fiatAmount?: string;
    /**
     * Fiat currency
     * @type {string}
     * @memberof B2bOorBuyRates200ResponseDataAPIModel
     */
    fiatCurrency?: string;
    /**
     * Fiat currency
     * @type {string}
     * @memberof B2bOorBuyRates200ResponseDataAPIModel
     */
    rate?: string;
    /**
     * Subtotal amount in currencies
     * @type {{ [key: string]: string; }}
     * @memberof B2bOorBuyRates200ResponseDataAPIModel
     */
    subtotal?: { [key: string]: string; };
    /**
     * Total amount in currencies
     * @type {{ [key: string]: string; }}
     * @memberof B2bOorBuyRates200ResponseDataAPIModel
     */
    total?: { [key: string]: string; };
    /**
     * Transaction token
     * @type {string}
     * @memberof B2bOorBuyRates200ResponseDataAPIModel
     */
    trxToken?: string;
}

/**
 * Check if a given object implements the B2bOorBuyRates200ResponseDataAPIModel interface.
 */
export function instanceOfB2bOorBuyRates200ResponseDataAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bOorBuyRates200ResponseDataAPIModelFromJSON(json: any): B2bOorBuyRates200ResponseDataAPIModel {
    return B2bOorBuyRates200ResponseDataAPIModelFromJSONTyped(json, false);
}

export function B2bOorBuyRates200ResponseDataAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bOorBuyRates200ResponseDataAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
        'fiatAmount': !exists(json, 'fiat_amount') ? undefined : json['fiat_amount'],
        'fiatCurrency': !exists(json, 'fiat_currency') ? undefined : json['fiat_currency'],
        'rate': !exists(json, 'rate') ? undefined : json['rate'],
        'subtotal': !exists(json, 'subtotal') ? undefined : json['subtotal'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'trxToken': !exists(json, 'trx_token') ? undefined : json['trx_token'],
    };
}

export function B2bOorBuyRates200ResponseDataAPIModelToJSON(value?: B2bOorBuyRates200ResponseDataAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'currency': value.currency,
        'fee': value.fee,
        'fiat_amount': value.fiatAmount,
        'fiat_currency': value.fiatCurrency,
        'rate': value.rate,
        'subtotal': value.subtotal,
        'total': value.total,
        'trx_token': value.trxToken,
    };
}

