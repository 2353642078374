import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum CallbackPageRoutes {
  KYC_SUCCESS = 'kyc-success',
  KYC_FAILURE = 'kyc-failure',
  PAYMENT_SUCCESS = 'payment-success',
  PAYMENT_FAILURE = 'payment-failure',
}

export const callbackRoute = (route: CallbackPageRoutes) => `${appRoute(AppPageRoutes.CALLBACK)}/${route}`;
