import { InputBaseComponentProps, TextField } from '@mui/material';
import BigNumber from 'bignumber.js';
import React, { FocusEventHandler, ForwardedRef, useCallback, useMemo } from 'react';
import { NumericFormat } from 'react-number-format';

import { ReadonlyItem } from '@/components';
import { useCurrency, useMoneyFormat } from '@/features/dictionary/hooks';
import { useLocaleSettings } from '@/hooks';

import MoneyAmountValue from '../MoneyAmountValue';

import type { MoneyAmountItemProps } from './types';

interface MoneyMaskInputProps {
  inputRef?: ForwardedRef<unknown> | null;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  fraction: number;
}

const MoneyMaskInput: React.FC<MoneyMaskInputProps> = React.memo(({ onFocus, inputRef, fraction, ...other }) => {
  const { decimalSeparator, groupSeparator } = useLocaleSettings();
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onFocus={(event): void => {
        onFocus?.(event);
        setTimeout(() => event.target.select(), 0);
      }}
      fixedDecimalScale
      allowNegative={false}
      decimalScale={fraction}
      decimalSeparator={decimalSeparator}
      thousandSeparator={groupSeparator}
      inputMode="numeric"
    />
  );
});

const MoneyAmountItem: React.FC<MoneyAmountItemProps> = ({
  value,
  currency,
  readonly,
  disabled,
  onChange,
  messages,
  'data-test': dataTest,
  mode = 'edit',
}) => {
  const { formatNumber } = useMoneyFormat(currency);
  const { data: displayOptions } = useCurrency(currency);
  const formattedValue = useMemo(() => formatNumber(value?.value), [formatNumber, value?.value]);

  const handleChange = useCallback(
    (maskedValue: string): void => {
      if (!onChange || !displayOptions.data) {
        return;
      }
      const newValue = maskedValue.replace(/[^\d]/g, ''); // exclude all non digits
      try {
        const parsedValue = new BigNumber(newValue || 0).div(10 ** displayOptions.data.digitsToDisplay);
        if (!value?.value || !parsedValue.isEqualTo(value.value)) {
          onChange({ value: parsedValue, hasError: false });
        }
      } catch (e) {
        onChange({ hasError: true });
      }
    },
    [displayOptions.data, onChange, value?.value],
  );
  const FormattedMoneyMaskInput = useMemo(
    () =>
      // eslint-disable-next-line react/no-unstable-nested-components
      React.forwardRef((props: InputBaseComponentProps, ref) =>
        displayOptions.data ? (
          <MoneyMaskInput {...props} fraction={displayOptions.data.digitsToDisplay} inputRef={ref} />
        ) : null,
      ),
    [displayOptions.data],
  );
  return mode === 'view' ? (
    <ReadonlyItem data-test={dataTest} label={messages?.label}>
      <MoneyAmountValue
        value={value?.value}
        currency={currency}
        withCurrency
        data-test={dataTest && `${dataTest}-value`}
      />
    </ReadonlyItem>
  ) : (
    <TextField
      variant="filled"
      InputLabelProps={{
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        // https://github.com/mui/material-ui/issues/33175#issuecomment-1469725522
        'data-test': dataTest && `${dataTest}-label`,
        shrink: true,
      }}
      InputProps={{
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        // https://github.com/mui/material-ui/issues/33175#issuecomment-1469725522
        'data-test': dataTest && `${dataTest}-input`,
        className: 'ym-hide-content',
        disabled,
        readOnly: readonly,
        disableUnderline: true,
        inputComponent: FormattedMoneyMaskInput,
      }}
      label={currency}
      value={formattedValue}
      onChange={async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (!onChange) {
          return;
        }
        const inputValue = event.target.value;
        await handleChange(inputValue);
      }}
      {...(value?.hasError
        ? {
            helperText: messages?.error,
            error: true,
          }
        : {})}
      // FIXME: best place to trim, but onBlur handler stopping event propagation, so i.e submit does't work at all
      // onBlur={async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
      //   const inputValue = event.target.value?.trim();
      //   await onChange(inputValue);
      // }}
    />
  );
};

export default React.memo(MoneyAmountItem);
