/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModel } from './B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModel';
import {
    B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModelFromJSON,
    B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModelFromJSONTyped,
    B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModelToJSON,
} from './B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModel';
import type { B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyDayAPIModel } from './B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyDayAPIModel';
import {
    B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyDayAPIModelFromJSON,
    B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyDayAPIModelFromJSONTyped,
    B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyDayAPIModelToJSON,
} from './B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyDayAPIModel';

/**
 * 
 * @export
 * @interface B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModel
 */
export interface B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModel {
    /**
     * 
     * @type {B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyDayAPIModel}
     * @memberof B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModel
     */
    day?: B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyDayAPIModel;
    /**
     * Maximum
     * @type {string}
     * @memberof B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModel
     */
    max?: string;
    /**
     * Minimum
     * @type {string}
     * @memberof B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModel
     */
    min?: string;
    /**
     * 
     * @type {B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModel}
     * @memberof B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModel
     */
    month?: B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModel;
}

/**
 * Check if a given object implements the B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModel interface.
 */
export function instanceOfB2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModelFromJSON(json: any): B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModel {
    return B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModelFromJSONTyped(json, false);
}

export function B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'day': !exists(json, 'day') ? undefined : B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyDayAPIModelFromJSON(json['day']),
        'max': !exists(json, 'max') ? undefined : json['max'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'month': !exists(json, 'month') ? undefined : B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModelFromJSON(json['month']),
    };
}

export function B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModelToJSON(value?: B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'day': B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsCurrencyDayAPIModelToJSON(value.day),
        'max': value.max,
        'min': value.min,
        'month': B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModelToJSON(value.month),
    };
}

