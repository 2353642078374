import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import useIsMounted from '@/hooks/useIsMounted';
import type { Func } from '@/utils/ts';

export type UseStateMountSafeType<S> = [
  state: S,
  update: Dispatch<SetStateAction<S>>,
  withReset: <V extends unknown[] = unknown[], R = unknown>(func: Func<V, R>) => Func<V, R>,
  reset: () => void,
];

function useStateMountSafe<S>(initialState: S | (() => S)): UseStateMountSafeType<S> {
  const isMounted = useIsMounted();
  const [state, changeState] = useState<S>(initialState);
  const changeStateMountSafe = useCallback(
    (newState: SetStateAction<S>) => {
      if (isMounted()) {
        changeState(newState);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted],
  );
  const reset = useCallback(() => changeStateMountSafe(initialState), [changeStateMountSafe, initialState]);
  const withReset = useCallback(
    <V extends unknown[], R>(func: Func<V, R>) =>
      async (...params: V) => {
        const value = await func(...params);
        changeStateMountSafe(initialState);
        return value;
      },
    [changeStateMountSafe, initialState],
  );
  return [state, changeStateMountSafe, withReset, reset];
}

export type Result = (<S>(initialState: S | (() => S)) => UseStateMountSafeType<S>) &
  (<S = undefined>() => UseStateMountSafeType<S>);

export default useStateMountSafe as Result;
