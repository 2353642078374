/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { B2bUserSendKycDocumentsRequestDocumentInnerAPIModel } from './B2bUserSendKycDocumentsRequestDocumentInnerAPIModel';
import {
    B2bUserSendKycDocumentsRequestDocumentInnerAPIModelFromJSON,
    B2bUserSendKycDocumentsRequestDocumentInnerAPIModelFromJSONTyped,
    B2bUserSendKycDocumentsRequestDocumentInnerAPIModelToJSON,
} from './B2bUserSendKycDocumentsRequestDocumentInnerAPIModel';

/**
 * 
 * @export
 * @interface B2bUserSendKycDocumentsRequestAPIModel
 */
export interface B2bUserSendKycDocumentsRequestAPIModel {
    /**
     * Address 1 line (required, only for US kyc level1)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    address1?: string;
    /**
     * Address 2 line (required, only for US kyc level1)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    address2?: string;
    /**
     * Birthday. Format '1995-05-25'. (required, only for kyc level1)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    birthday?: string;
    /**
     * user's country (required, only for kyc level2 without verification)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    citizenshipCountryCode?: string;
    /**
     * City (required, only for US kyc level1)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    city?: string;
    /**
     * User's coresponcence address line (required, only for kyc level2 without verification)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    correspondenceAddressLine?: string;
    /**
     * User's coresponcence city (required, only for kyc level2 without verification)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    correspondenceCity?: string;
    /**
     * User's coresponcence country code (required, only for kyc level2 without verification)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    correspondenceCountryCode?: string;
    /**
     * User's coresponcence postal code (required, only for kyc level2 without verification)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    correspondencePostalCode?: string;
    /**
     * User's coresponcence state (required, only for kyc level2 without verification)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    correspondenceState?: string;
    /**
     * Country code (required, only for kyc level1)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    countryCode?: string;
    /**
     * 
     * @type {Array<B2bUserSendKycDocumentsRequestDocumentInnerAPIModel>}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    document: Array<B2bUserSendKycDocumentsRequestDocumentInnerAPIModel>;
    /**
     * First name (required, only for kyc level1)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    firstName?: string;
    /**
     * First name (required, only for kyc level1)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    firstNameEn?: string;
    /**
     * US ID number (required, only for US kyc level1)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    idNumber?: string;
    /**
     * Level of kyc documents
     * @type {number}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    kycLevel?: B2bUserSendKycDocumentsRequestAPIModelKycLevelEnum;
    /**
     * Last name (required, only for kyc level1)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    lastName?: string;
    /**
     * Last name (required, only for kyc level1)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    lastNameEn?: string;
    /**
     * user's address line (required, only for kyc level2 without verification)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    residenceAddressLine?: string;
    /**
     * user's city (required, only for kyc level2 without verification)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    residenceCity?: string;
    /**
     * user's country (required, only for kyc level2 without verification)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    residenceCountryCode?: string;
    /**
     * User's residence postal code (required, only for kyc level2 without verification)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    residencePostalCode?: string;
    /**
     * User's residence country code (required, only for kyc level2 without verification)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    residenceState?: string;
    /**
     * State code (required, only for US kyc level1)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    state?: string;
    /**
     * ZIP code (required, only for US kyc level1)
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestAPIModel
     */
    zip?: string;
}


/**
 * @export
 */
export const B2bUserSendKycDocumentsRequestAPIModelKycLevelEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type B2bUserSendKycDocumentsRequestAPIModelKycLevelEnum = typeof B2bUserSendKycDocumentsRequestAPIModelKycLevelEnum[keyof typeof B2bUserSendKycDocumentsRequestAPIModelKycLevelEnum];


/**
 * Check if a given object implements the B2bUserSendKycDocumentsRequestAPIModel interface.
 */
export function instanceOfB2bUserSendKycDocumentsRequestAPIModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "document" in value;

    return isInstance;
}

export function B2bUserSendKycDocumentsRequestAPIModelFromJSON(json: any): B2bUserSendKycDocumentsRequestAPIModel {
    return B2bUserSendKycDocumentsRequestAPIModelFromJSONTyped(json, false);
}

export function B2bUserSendKycDocumentsRequestAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserSendKycDocumentsRequestAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address1': !exists(json, 'address_1') ? undefined : json['address_1'],
        'address2': !exists(json, 'address_2') ? undefined : json['address_2'],
        'birthday': !exists(json, 'birthday') ? undefined : json['birthday'],
        'citizenshipCountryCode': !exists(json, 'citizenship_country_code') ? undefined : json['citizenship_country_code'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'correspondenceAddressLine': !exists(json, 'correspondence_address_line') ? undefined : json['correspondence_address_line'],
        'correspondenceCity': !exists(json, 'correspondence_city') ? undefined : json['correspondence_city'],
        'correspondenceCountryCode': !exists(json, 'correspondence_country_code') ? undefined : json['correspondence_country_code'],
        'correspondencePostalCode': !exists(json, 'correspondence_postal_code') ? undefined : json['correspondence_postal_code'],
        'correspondenceState': !exists(json, 'correspondence_state') ? undefined : json['correspondence_state'],
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
        'document': ((json['document'] as Array<any>).map(B2bUserSendKycDocumentsRequestDocumentInnerAPIModelFromJSON)),
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'firstNameEn': !exists(json, 'first_name_en') ? undefined : json['first_name_en'],
        'idNumber': !exists(json, 'id_number') ? undefined : json['id_number'],
        'kycLevel': !exists(json, 'kyc_level') ? undefined : json['kyc_level'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'lastNameEn': !exists(json, 'last_name_en') ? undefined : json['last_name_en'],
        'residenceAddressLine': !exists(json, 'residence_address_line') ? undefined : json['residence_address_line'],
        'residenceCity': !exists(json, 'residence_city') ? undefined : json['residence_city'],
        'residenceCountryCode': !exists(json, 'residence_country_code') ? undefined : json['residence_country_code'],
        'residencePostalCode': !exists(json, 'residence_postal_code') ? undefined : json['residence_postal_code'],
        'residenceState': !exists(json, 'residence_state') ? undefined : json['residence_state'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
    };
}

export function B2bUserSendKycDocumentsRequestAPIModelToJSON(value?: B2bUserSendKycDocumentsRequestAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address_1': value.address1,
        'address_2': value.address2,
        'birthday': value.birthday,
        'citizenship_country_code': value.citizenshipCountryCode,
        'city': value.city,
        'correspondence_address_line': value.correspondenceAddressLine,
        'correspondence_city': value.correspondenceCity,
        'correspondence_country_code': value.correspondenceCountryCode,
        'correspondence_postal_code': value.correspondencePostalCode,
        'correspondence_state': value.correspondenceState,
        'country_code': value.countryCode,
        'document': ((value.document as Array<any>).map(B2bUserSendKycDocumentsRequestDocumentInnerAPIModelToJSON)),
        'first_name': value.firstName,
        'first_name_en': value.firstNameEn,
        'id_number': value.idNumber,
        'kyc_level': value.kycLevel,
        'last_name': value.lastName,
        'last_name_en': value.lastNameEn,
        'residence_address_line': value.residenceAddressLine,
        'residence_city': value.residenceCity,
        'residence_country_code': value.residenceCountryCode,
        'residence_postal_code': value.residencePostalCode,
        'residence_state': value.residenceState,
        'state': value.state,
        'zip': value.zip,
    };
}

