import { MercuryoKYCStatusAPIModel, StoreMockMercuryoOnRampReqAPIModel } from '@/generated/api/ncps/api';
import type { LoadingStateWithDirty } from '@/utils/model';
import { InitState } from '@/utils/model';

export const NAMESPACE = 'mock';

export interface MockTransaction extends Omit<StoreMockMercuryoOnRampReqAPIModel, 'delay'> {}

export interface InitMockState {
  success: string;
  failure: string;
  tx?: MockTransaction;
}

export interface MockState {
  status: LoadingStateWithDirty<MercuryoKYCStatusAPIModel>;
  init: InitState<InitMockState | undefined>;
  error?: string;
}
