import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/app/hooks';
import { FormattedMessage, NotificationMessage } from '@/components';
import { initAuth } from '@/features/auth/actions';
import { makeSelectAuthTokenInitStatus, makeSelectAuthTokenStatus } from '@/features/auth/selectors';
import { AuthTokenStatus } from '@/features/auth/types';
import { GlobalBackdrop } from '@/features/global/components';
import { I18nCommon } from '@/generated/i18n/i18n';
import { useFetching } from '@/hooks';
import { BlankLayout } from '@/layout';
import { InitStatus } from '@/utils/model';

const selectAuthStatus = makeSelectAuthTokenStatus();
const selectAuthInitStatus = makeSelectAuthTokenInitStatus();

const withAuthorizationInit =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props: P) => {
    const authTokenState = useSelector(selectAuthStatus);
    const dispatch = useAppDispatch();
    const initState = useSelector(selectAuthInitStatus);
    const init = useCallback(async () => dispatch(initAuth()), [dispatch]);
    useFetching(init, initState === InitStatus.NOT_INITIALIZED);
    if (initState === InitStatus.NOT_INITIALIZED || authTokenState === AuthTokenStatus.EMPTY) {
      return <GlobalBackdrop isLoading />;
    }

    if (authTokenState === AuthTokenStatus.FORBIDDEN) {
      return (
        <BlankLayout>
          <NotificationMessage
            data-test="authorization-forbidden"
            severity="error"
            title={<FormattedMessage id={I18nCommon.MESSAGES_FORBIDDEN_TITLE} />}
          >
            <FormattedMessage id={I18nCommon.MESSAGES_FORBIDDEN_CONTENT} />
          </NotificationMessage>
        </BlankLayout>
      );
    }

    return authTokenState === AuthTokenStatus.AUTHORIZED ? (
      <Component {...props} />
    ) : (
      <BlankLayout>
        <NotificationMessage
          data-test="authorization-error"
          title={<FormattedMessage id={I18nCommon.ERRORS_UNAUTHORIZED_TITLE} />}
        >
          <FormattedMessage id={I18nCommon.ERRORS_UNAUTHORIZED_CONTENT} />
        </NotificationMessage>
      </BlankLayout>
    );
  };

export default withAuthorizationInit;
