/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bUserSetPhoneRequestAPIModel
 */
export interface B2bUserSetPhoneRequestAPIModel {
    /**
     * New user phone
     * @type {string}
     * @memberof B2bUserSetPhoneRequestAPIModel
     */
    phone: string;
}

/**
 * Check if a given object implements the B2bUserSetPhoneRequestAPIModel interface.
 */
export function instanceOfB2bUserSetPhoneRequestAPIModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "phone" in value;

    return isInstance;
}

export function B2bUserSetPhoneRequestAPIModelFromJSON(json: any): B2bUserSetPhoneRequestAPIModel {
    return B2bUserSetPhoneRequestAPIModelFromJSONTyped(json, false);
}

export function B2bUserSetPhoneRequestAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserSetPhoneRequestAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phone': json['phone'],
    };
}

export function B2bUserSetPhoneRequestAPIModelToJSON(value?: B2bUserSetPhoneRequestAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone': value.phone,
    };
}

