import { CircularProgress, Divider } from '@mui/material';
import React from 'react';

import { FormattedMessage, SecondaryTitle } from '@/components';
import { useCards } from '@/features/cards/hooks';
import { PurchaseCardsSelect } from '@/features/purchase/components';
import { I18nFeatureBuyPurchase } from '@/generated/i18n/i18n';

import type { CardsBlockProps } from './types';

const CardsBlock: React.FC<CardsBlockProps> = ({ 'data-test': dataTest }) => {
  const cards = useCards();
  if (cards.loading || cards.data.isDirty) {
    return <CircularProgress color="inherit" size={16} />;
  }
  return cards.data.data?.length ? (
    <>
      <Divider orientation="horizontal" />
      <SecondaryTitle data-test={dataTest && `${dataTest}-title`}>
        <FormattedMessage id={I18nFeatureBuyPurchase.COMPONENTS_CARDS_BLOCK_SELECT} />
      </SecondaryTitle>
      <PurchaseCardsSelect data-test={dataTest && `${dataTest}-select`} />
    </>
  ) : null;
};

export default React.memo(CardsBlock);
