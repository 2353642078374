
import type { AppRootState } from '@/app/store';
import { NAMESPACE } from '@/features/auth/types';

export const makeSelectAuthTokenStatus = () => (state: AppRootState) => state[NAMESPACE].tokenStatus;
export const makeSelectAuthTokenInitStatus = () => (state: AppRootState) => state[NAMESPACE].initStatus;

export const makeSelectAuthToken = () => (state: AppRootState) => state[NAMESPACE].token;

export const makeSelectUser = () => (state: AppRootState) => state[NAMESPACE].user;
