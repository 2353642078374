import { Typography, TypographyProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { TestableProps } from '@/utils/react';

interface BodyDetailsProps extends PropsWithChildren<TestableProps> {
  className?: string;
  style?: React.CSSProperties;
}

const BodyDetails = React.forwardRef(
  ({ className, children, style, 'data-test': dataTest }: BodyDetailsProps, ref: TypographyProps['ref']) => (
    <Typography component="span" className={className} style={style} variant="body2" data-test={dataTest} ref={ref}>
      {children}
    </Typography>
  ),
);

export default React.memo(BodyDetails);
