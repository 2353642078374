/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Network common info (name, icons)
 * @export
 * @interface PublicCurrencies200ResponseDataConfigNetworksNetworkAPIModel
 */
export interface PublicCurrencies200ResponseDataConfigNetworksNetworkAPIModel {
    /**
     * Paths for network icons
     * @type {object}
     * @memberof PublicCurrencies200ResponseDataConfigNetworksNetworkAPIModel
     */
    icons?: object;
    /**
     * Network name
     * @type {string}
     * @memberof PublicCurrencies200ResponseDataConfigNetworksNetworkAPIModel
     */
    name?: string;
}

/**
 * Check if a given object implements the PublicCurrencies200ResponseDataConfigNetworksNetworkAPIModel interface.
 */
export function instanceOfPublicCurrencies200ResponseDataConfigNetworksNetworkAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PublicCurrencies200ResponseDataConfigNetworksNetworkAPIModelFromJSON(json: any): PublicCurrencies200ResponseDataConfigNetworksNetworkAPIModel {
    return PublicCurrencies200ResponseDataConfigNetworksNetworkAPIModelFromJSONTyped(json, false);
}

export function PublicCurrencies200ResponseDataConfigNetworksNetworkAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicCurrencies200ResponseDataConfigNetworksNetworkAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'icons': !exists(json, 'icons') ? undefined : json['icons'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function PublicCurrencies200ResponseDataConfigNetworksNetworkAPIModelToJSON(value?: PublicCurrencies200ResponseDataConfigNetworksNetworkAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'icons': value.icons,
        'name': value.name,
    };
}

