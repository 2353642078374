import { configureStore } from '@reduxjs/toolkit';

import authReducer from '@/features/auth/reducer';
import { NAMESPACE as authName } from '@/features/auth/types';
import callbackReducer from '@/features/callback/reducer';
import { NAMESPACE as callbackName } from '@/features/callback/types';
import cardReducer from '@/features/cards/reducer';
import { NAMESPACE as cardName } from '@/features/cards/types';
import dictionaryReducer from '@/features/dictionary/reducer';
import { NAMESPACE as dictionaryName } from '@/features/dictionary/types';
import featureToggleReducer from '@/features/feature-toggle/reducer';
import { NAMESPACE as featureToggleName } from '@/features/feature-toggle/types';
import { globalInit } from '@/features/global/actions';
import globalReducer from '@/features/global/reducer';
import { NAMESPACE as globalName } from '@/features/global/types';
import intercomReducer from '@/features/intercom/reducer';
import { NAMESPACE as intercomName } from '@/features/intercom/types';
import kycReducer from '@/features/kyc/reducer';
import { NAMESPACE as kycName } from '@/features/kyc/types';
import mockReducer from '@/features/mock/reducer';
import { NAMESPACE as mockName } from '@/features/mock/types';
import purchaseReducer from '@/features/purchase/reducer';
import { NAMESPACE as purchaseName } from '@/features/purchase/types';
import transactionReducer from '@/features/transaction/reducer';
import { NAMESPACE as transactionName } from '@/features/transaction/types';

import listenerMiddleware from './listenerMiddleware';

export default () => {
  const store = configureStore({
    devTools: window.env.DEV_TOOLS_ENABLED === 'true',
    reducer: {
      [authName]: authReducer,
      [callbackName]: callbackReducer,
      [cardName]: cardReducer,
      [dictionaryName]: dictionaryReducer,
      [featureToggleName]: featureToggleReducer,
      [globalName]: globalReducer,
      [intercomName]: intercomReducer,
      [kycName]: kycReducer,
      [mockName]: mockReducer,
      [purchaseName]: purchaseReducer,
      [transactionName]: transactionReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  });
  store.dispatch(globalInit());
  return store;
};
