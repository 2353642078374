import { createAction } from '@reduxjs/toolkit';

import { createAppAsyncThunk } from '@/app/actions';
import { MercuryoKYCStatusAPIModel } from '@/generated/api/ncps/api';
import { withAPICall } from '@/utils/api';
import { CommonLoadingState, mapLoadingState } from '@/utils/model';

import { queryMockKYC, storeMockKYC, storeMockOnRampTransaction } from './api';
import { makeSelectIsMockEnabled, makeSelectMockInit, makeSelectMockKYCStatus } from './selectors';
import { InitMockState, MockTransaction, NAMESPACE } from './types';

export const storeMockKYCStatus = createAction<CommonLoadingState<MercuryoKYCStatusAPIModel | undefined>>(
  `${NAMESPACE}/storeMockKYCStatus`,
);
export const markKYCStatusDirty = createAction(`${NAMESPACE}/markKYCStatusDirty`);
export const storeMockTransaction = createAction<MockTransaction>(`${NAMESPACE}/storeMockTransaction`);

export const fetchMockKYC = createAppAsyncThunk(
  `${NAMESPACE}/fetchMockKYC`,
  async ({ force }: { force?: boolean }, { dispatch, getState }) => {
    const { isDirty } = makeSelectMockKYCStatus()(getState());
    if (!isDirty && !force) {
      return;
    }
    const isMockEnabled = makeSelectIsMockEnabled()(getState());
    const data = isMockEnabled ? await withAPICall(queryMockKYC)() : {};
    dispatch(storeMockKYCStatus(data));
  },
);

export const storeInitialized = createAction<InitMockState | undefined>(`${NAMESPACE}/storeInitialized`);
export const initialize = createAppAsyncThunk(`${NAMESPACE}/initialize`, async (_, { dispatch }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const amount = queryParams.get('amount') || undefined;
  const txId = queryParams.get('tx-id') || undefined;
  const address = queryParams.get('address') || undefined;
  const success = queryParams.get('success') || undefined;
  const failure = queryParams.get('failure') || undefined;

  dispatch(
    storeInitialized(
      success && failure
        ? { failure, success, tx: amount && txId && address ? { amount, address, txId } : undefined }
        : undefined,
    ),
  );
});

export const proceedMockKYC = createAppAsyncThunk(
  `${NAMESPACE}/saveMockKYC`,
  async ({ status }: { status: MercuryoKYCStatusAPIModel }, { dispatch, getState }) => {
    const init = makeSelectMockInit()(getState());
    if (!init.value) {
      return;
    }
    const result = await withAPICall(storeMockKYC)(status);
    dispatch(storeMockKYCStatus(mapLoadingState(result, () => status)));
    window.location.replace(
      !result.error && status === MercuryoKYCStatusAPIModel.complete ? init.value.success : init.value.failure,
    );
  },
);

export const redirectFromMockMercuryoWidget = createAppAsyncThunk(
  `${NAMESPACE}/redirectFromMockMercuryoWidget`,
  async ({ success, msg = '' }: { success: boolean; msg?: string }, { getState }) => {
    const init = makeSelectMockInit()(getState());
    if (!init.value?.tx) {
      return;
    }
    const params = new URLSearchParams({ merchant_transaction_id: init.value.tx.txId, msg });
    const url = success ? init.value.success : init.value.failure;
    window.location.replace(url.includes('?') ? `${url}&${params.toString()}` : `${url}?${params.toString()}`);
  },
);

export const proceedMockTransaction = createAppAsyncThunk(
  `${NAMESPACE}/saveMockTransaction`,
  async ({ delay, code }: { code: string; delay: string }, { dispatch, getState }) => {
    let success = false;
    if (code === 'hint') {
      const init = makeSelectMockInit()(getState());
      if (init.value?.tx) {
        const result = await withAPICall(storeMockOnRampTransaction)(init.value.tx, delay);
        success = !result.error;
      }
    }
    await dispatch(redirectFromMockMercuryoWidget({ success }));
  },
);
