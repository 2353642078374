import type { InitState, LoadingStateWithDirty } from '@/utils/model';
import { DeepPartial } from '@/utils/ts';

export const NAMESPACE = 'purchase';

export interface Money {
  amount: string;
  currency: string;
}

export interface Crypto extends Money {
  network?: string;
}

export interface RequestedPurchase {
  buy: Crypto;
  fiatCurrency: string;
}

export type CurrencyPurchaseLimits = DeepPartial<{
  day: {
    remain: string; // '0.277700638711500000';
    total: string; // '0.277700638711500000';
  };
  max: string; // '2.007223972028780000';
  min: string; // '0.007223972028780000';
  month: {
    remain: string; // '2.404734880366000000';
    total: string; // '2.404734880366000000';
  };
}>;

// key: currency for selected pair, e.g.: USDT, ETH
export type PurchaseLimits = Partial<Record<string, CurrencyPurchaseLimits>>;

export type Rate = {
  toBuy: Crypto;
  price: {
    subtotal: Money;
    fee: Money;
    total: Money;
  };
  token: string;
  expiresAt: string; // Date
};

export interface PurchaseState {
  requested: InitState<RequestedPurchase | undefined>;
  selectedCardId?: string;
  purchase: {
    cryptoToBuy: Crypto;
    fiatToPay: string;
    purchaseKey: string;
  };
  rate: { value: LoadingStateWithDirty<Rate>; purchaseKey?: string };
  pendingRates: string[]; // purchaseKeys
  // key: <FROM>_<TO>, e.g. USDT_EUR,
  limits: Partial<Record<string, LoadingStateWithDirty<PurchaseLimits>>>;
}
