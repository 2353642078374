import ms from 'ms';
import pDebounce from 'p-debounce';
import pLimit from 'p-limit';

import type { MockTransaction } from '@/features/mock/types';
import { MercuryoKYCStatusAPIModel, MercuryoMockApi } from '@/generated/api/ncps/api';
import { ncpsConfigurationFactory } from '@/infrastructure/security/auth.provider';

const mercuryoApi = new MercuryoMockApi(ncpsConfigurationFactory('auth'));

const limit = pLimit(1);

export const queryMockKYC = pDebounce(
  async () => limit(async () => (await mercuryoApi.getMockMercuryoKYC()).status),
  0,
);

export const storeMockKYC = async (status: MercuryoKYCStatusAPIModel) =>
  limit(async () => {
    await mercuryoApi.storeMockMercuryoKYC({ status });
  });

export const storeMockOnRampTransaction = async (tx: MockTransaction, delay?: string) =>
  limit(async () => {
    await mercuryoApi.storeMockOnRampTransaction({ ...tx, delay: delay ? ms(delay) : null });
  });
