import BigNumber from 'bignumber.js';
import { useCallback } from 'react';

import { useLocaleSettings } from '@/hooks';

import useCurrency from '../useCurrency';

const toBigNumber = (value?: string | BigNumber) => {
  if (typeof value !== 'string') {
    return value;
  }
  try {
    return new BigNumber(value);
  } catch (e) {
    return undefined;
  }
};

export default function useMoneyFormat(currency: string) {
  const { formatBigNumber } = useLocaleSettings();
  const displayOptions = useCurrency(currency);
  const formatNumber = useCallback(
    (value?: BigNumber | string) => {
      const fraction = displayOptions.data.data?.digitsToDisplay;
      if (!fraction) {
        return '';
      }
      const numValue = toBigNumber(value);
      return formatBigNumber(fraction, numValue);
    },
    [displayOptions.data.data?.digitsToDisplay, formatBigNumber],
  );
  return {
    toBigNumber,
    formatNumber,
  };
}
