import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureMock } from '@/generated/i18n/i18n';
import { MessageLayout } from '@/layout';

const withMockEnabled =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props: P) =>
    window.env.MOCK_MODE_SUPPORTED === 'true' ? (
      <Component {...props} />
    ) : (
      <MessageLayout
        message={<FormattedMessage id={I18nFeatureMock.DISABLED_TITLE} />}
        description={<FormattedMessage id={I18nFeatureMock.DISABLED_DESCRIPTION} />}
      />
    );

export default withMockEnabled;
