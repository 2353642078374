/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bUserSignUpRequestAPIModel
 */
export interface B2bUserSignUpRequestAPIModel {
    /**
     * accept
     * @type {boolean}
     * @memberof B2bUserSignUpRequestAPIModel
     */
    accept: boolean;
    /**
     * email
     * @type {string}
     * @memberof B2bUserSignUpRequestAPIModel
     */
    email: string;
}

/**
 * Check if a given object implements the B2bUserSignUpRequestAPIModel interface.
 */
export function instanceOfB2bUserSignUpRequestAPIModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accept" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function B2bUserSignUpRequestAPIModelFromJSON(json: any): B2bUserSignUpRequestAPIModel {
    return B2bUserSignUpRequestAPIModelFromJSONTyped(json, false);
}

export function B2bUserSignUpRequestAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserSignUpRequestAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accept': json['accept'],
        'email': json['email'],
    };
}

export function B2bUserSignUpRequestAPIModelToJSON(value?: B2bUserSignUpRequestAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accept': value.accept,
        'email': value.email,
    };
}

