import { Box, Container } from '@mui/material';
import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useAppSelector } from '@/app/hooks';
import { withGlobalNotifications } from '@/features/global/hocs';
import { makeSelectViewHeight } from '@/features/global/selectors';

const useStyles = makeStyles()(() => ({
  mainBoxContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    marginTop: 0,
    marginBottom: 0,
    padding: 10,
    position: 'relative',
    borderRadius: '12px',
    height: '100%',
    '@media (max-height: 450px)': {
      padding: 0,
    },
  },
}));

export interface BaseContainerProps extends PropsWithChildren {
  height?: number | 'unset';
  minHeight?: number | 'unset';
  width?: number | 'unset';
  containerClassname?: string;
  boxClassname?: string;
  containerPrefix?: ReactNode;
}

const selectViewHeight = makeSelectViewHeight();

export const BaseContainer: React.FC<BaseContainerProps> = ({
  containerPrefix,
  containerClassname,
  boxClassname,
  children,
  height,
  minHeight,
  width,
}) => {
  const { cx, classes } = useStyles();
  const vh = useAppSelector(selectViewHeight);
  const viewHeight = useMemo(() => `${vh * 100}px`, [vh]);

  return (
    <Box
      {...(width ? { style: { width } } : {})}
      minHeight={{ minHeight }}
      height={viewHeight}
      className={cx(classes.mainBoxContainer, containerClassname)}
    >
      {containerPrefix}
      <Container
        fixed
        className={cx(classes.mainContainer, boxClassname)}
        {...(height ? { style: { height } } : {})}
        disableGutters
      >
        {children}
      </Container>
    </Box>
  );
};

export default React.memo(withGlobalNotifications<BaseContainerProps>(BaseContainer));
