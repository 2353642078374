/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { B2bIntercomLink200ResponseDataAPIModel } from './B2bIntercomLink200ResponseDataAPIModel';
import {
    B2bIntercomLink200ResponseDataAPIModelFromJSON,
    B2bIntercomLink200ResponseDataAPIModelFromJSONTyped,
    B2bIntercomLink200ResponseDataAPIModelToJSON,
} from './B2bIntercomLink200ResponseDataAPIModel';

/**
 * 
 * @export
 * @interface B2bIntercomLink200ResponseAPIModel
 */
export interface B2bIntercomLink200ResponseAPIModel {
    /**
     * 
     * @type {B2bIntercomLink200ResponseDataAPIModel}
     * @memberof B2bIntercomLink200ResponseAPIModel
     */
    data?: B2bIntercomLink200ResponseDataAPIModel;
    /**
     * HTTP status code
     * @type {number}
     * @memberof B2bIntercomLink200ResponseAPIModel
     */
    status?: number;
}

/**
 * Check if a given object implements the B2bIntercomLink200ResponseAPIModel interface.
 */
export function instanceOfB2bIntercomLink200ResponseAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bIntercomLink200ResponseAPIModelFromJSON(json: any): B2bIntercomLink200ResponseAPIModel {
    return B2bIntercomLink200ResponseAPIModelFromJSONTyped(json, false);
}

export function B2bIntercomLink200ResponseAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bIntercomLink200ResponseAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : B2bIntercomLink200ResponseDataAPIModelFromJSON(json['data']),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function B2bIntercomLink200ResponseAPIModelToJSON(value?: B2bIntercomLink200ResponseAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': B2bIntercomLink200ResponseDataAPIModelToJSON(value.data),
        'status': value.status,
    };
}

