/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { B2bRates200ResponseDataBuyCryptoCurrencyAPIModel } from './B2bRates200ResponseDataBuyCryptoCurrencyAPIModel';
import {
    B2bRates200ResponseDataBuyCryptoCurrencyAPIModelFromJSON,
    B2bRates200ResponseDataBuyCryptoCurrencyAPIModelFromJSONTyped,
    B2bRates200ResponseDataBuyCryptoCurrencyAPIModelToJSON,
} from './B2bRates200ResponseDataBuyCryptoCurrencyAPIModel';

/**
 * Sell rates
 * @export
 * @interface B2bRates200ResponseDataSellAPIModel
 */
export interface B2bRates200ResponseDataSellAPIModel {
    /**
     * 
     * @type {B2bRates200ResponseDataBuyCryptoCurrencyAPIModel}
     * @memberof B2bRates200ResponseDataSellAPIModel
     */
    cryptoCurrency?: B2bRates200ResponseDataBuyCryptoCurrencyAPIModel;
}

/**
 * Check if a given object implements the B2bRates200ResponseDataSellAPIModel interface.
 */
export function instanceOfB2bRates200ResponseDataSellAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bRates200ResponseDataSellAPIModelFromJSON(json: any): B2bRates200ResponseDataSellAPIModel {
    return B2bRates200ResponseDataSellAPIModelFromJSONTyped(json, false);
}

export function B2bRates200ResponseDataSellAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bRates200ResponseDataSellAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cryptoCurrency': !exists(json, '&lt;crypto_currency&gt;') ? undefined : B2bRates200ResponseDataBuyCryptoCurrencyAPIModelFromJSON(json['&lt;crypto_currency&gt;']),
    };
}

export function B2bRates200ResponseDataSellAPIModelToJSON(value?: B2bRates200ResponseDataSellAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '&lt;crypto_currency&gt;': B2bRates200ResponseDataBuyCryptoCurrencyAPIModelToJSON(value.cryptoCurrency),
    };
}

