/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  B2bBuy200ResponseAPIModel,
  B2bBuyIbanInvoice200ResponseAPIModel,
  B2bBuyMethods200ResponseAPIModel,
  B2bBuyRates200ResponseAPIModel,
  B2bBuyRequestAPIModel,
  B2bFiatBuyCard200ResponseAPIModel,
  B2bFiatBuyCardRequestAPIModel,
  B2bOorBuyRates200ResponseAPIModel,
  WebappError400001APIModel,
  WebappError400064APIModel,
  WebappError401000APIModel,
  WebappError403007APIModel,
  WebappError403030APIModel,
  WebappError405000APIModel,
  WebappError500001APIModel,
} from '../models';
import {
    B2bBuy200ResponseAPIModelFromJSON,
    B2bBuy200ResponseAPIModelToJSON,
    B2bBuyIbanInvoice200ResponseAPIModelFromJSON,
    B2bBuyIbanInvoice200ResponseAPIModelToJSON,
    B2bBuyMethods200ResponseAPIModelFromJSON,
    B2bBuyMethods200ResponseAPIModelToJSON,
    B2bBuyRates200ResponseAPIModelFromJSON,
    B2bBuyRates200ResponseAPIModelToJSON,
    B2bBuyRequestAPIModelFromJSON,
    B2bBuyRequestAPIModelToJSON,
    B2bFiatBuyCard200ResponseAPIModelFromJSON,
    B2bFiatBuyCard200ResponseAPIModelToJSON,
    B2bFiatBuyCardRequestAPIModelFromJSON,
    B2bFiatBuyCardRequestAPIModelToJSON,
    B2bOorBuyRates200ResponseAPIModelFromJSON,
    B2bOorBuyRates200ResponseAPIModelToJSON,
    WebappError400001APIModelFromJSON,
    WebappError400001APIModelToJSON,
    WebappError400064APIModelFromJSON,
    WebappError400064APIModelToJSON,
    WebappError401000APIModelFromJSON,
    WebappError401000APIModelToJSON,
    WebappError403007APIModelFromJSON,
    WebappError403007APIModelToJSON,
    WebappError403030APIModelFromJSON,
    WebappError403030APIModelToJSON,
    WebappError405000APIModelFromJSON,
    WebappError405000APIModelToJSON,
    WebappError500001APIModelFromJSON,
    WebappError500001APIModelToJSON,
} from '../models';

export interface B2bBuyRequest {
    b2bBuyRequestAPIModel?: B2bBuyRequestAPIModel;
}

export interface B2bBuyIbanInvoiceRequest {
    trxToken: string;
    address: string;
    merchantTransactionId?: string;
}

export interface B2bBuyRatesRequest {
    from: string;
    to: string;
    amountFrom?: string;
    amountTo?: string;
    network?: string;
    paymentAlias?: B2bBuyRatesPaymentAliasEnum;
}

export interface B2bFiatBuyCardRequest {
    b2bFiatBuyCardRequestAPIModel: B2bFiatBuyCardRequestAPIModel;
}

export interface B2bOorBuyRatesRequest {
    from: string;
    to: string;
    amountFrom?: string;
    amountTo?: string;
    network?: string;
    paymentAlias?: B2bOorBuyRatesPaymentAliasEnum;
}

/**
 * BuyApi - interface
 * 
 * @export
 * @interface BuyApiInterface
 */
export interface BuyApiInterface {
    /**
     * POST /b2b/fiat/buy
     * @summary Create on-ramp transaction using \'card\' method
     * @param {B2bBuyRequestAPIModel} [b2bBuyRequestAPIModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyApiInterface
     */
    b2bBuyRaw(requestParameters: B2bBuyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bBuy200ResponseAPIModel>>;

    /**
     * POST /b2b/fiat/buy
     * Create on-ramp transaction using \'card\' method
     */
    b2bBuy(b2bBuyRequestAPIModel?: B2bBuyRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bBuy200ResponseAPIModel>;

    /**
     * POST /b2b/fiat/buy/iban-invoice
     * @summary Create on-ramp transaction using \'iban_invoice\' method
     * @param {string} trxToken Buy token returned by [&#x60;/b2b/fiat/buy-rates&#x60;](#operation/b2bBuyRates) endpoint
     * @param {string} address The address to which the crypto should be transferred
     * @param {string} [merchantTransactionId] Custom ID for checking transaction status. If empty, it will be generated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyApiInterface
     */
    b2bBuyIbanInvoiceRaw(requestParameters: B2bBuyIbanInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bBuyIbanInvoice200ResponseAPIModel>>;

    /**
     * POST /b2b/fiat/buy/iban-invoice
     * Create on-ramp transaction using \'iban_invoice\' method
     */
    b2bBuyIbanInvoice(trxToken: string, address: string, merchantTransactionId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bBuyIbanInvoice200ResponseAPIModel>;

    /**
     * GET /b2b/fiat/buy-methods
     * @summary Get methods for on-ramp transaction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyApiInterface
     */
    b2bBuyMethodsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bBuyMethods200ResponseAPIModel>>;

    /**
     * GET /b2b/fiat/buy-methods
     * Get methods for on-ramp transaction
     */
    b2bBuyMethods(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bBuyMethods200ResponseAPIModel>;

    /**
     * GET /b2b/fiat/buy-rates
     * @summary Get rates for on-ramp transaction
     * @param {string} from Fiat currency code for sale
     * @param {string} to Cryptocurrency code for buy
     * @param {string} [amountFrom] The total amount, including fees, to be converted into cryptocurrency, required if \&#39;amount_to\&#39; empty
     * @param {string} [amountTo] The total amount, including fees, to be received in cryptocurrency, required if \&#39;amount_from\&#39; empty
     * @param {string} [network] Crypotocurency network, if empty default used (default and allowed networks described in /lib/currencies)
     * @param {'card' | 'iban_exchange' | 'iban_invoice'} [paymentAlias] Payment method alias
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BuyApiInterface
     */
    b2bBuyRatesRaw(requestParameters: B2bBuyRatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bBuyRates200ResponseAPIModel>>;

    /**
     * GET /b2b/fiat/buy-rates
     * Get rates for on-ramp transaction
     */
    b2bBuyRates(from: string, to: string, amountFrom?: string, amountTo?: string, network?: string, paymentAlias?: B2bBuyRatesPaymentAliasEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bBuyRates200ResponseAPIModel>;

    /**
     * POST /b2b/fiat/buy/card
     * @summary On-ramp transaction with card attributes
     * @param {B2bFiatBuyCardRequestAPIModel} b2bFiatBuyCardRequestAPIModel Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyApiInterface
     */
    b2bFiatBuyCardRaw(requestParameters: B2bFiatBuyCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bFiatBuyCard200ResponseAPIModel>>;

    /**
     * POST /b2b/fiat/buy/card
     * On-ramp transaction with card attributes
     */
    b2bFiatBuyCard(b2bFiatBuyCardRequestAPIModel: B2bFiatBuyCardRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bFiatBuyCard200ResponseAPIModel>;

    /**
     * GET /b2b/oor/buy-rates
     * @summary Get rates for on-ramp transaction
     * @param {string} from Fiat currency code for sale
     * @param {string} to Cryptocurrency code for buy
     * @param {string} [amountFrom] The total amount, including fees, to be converted into cryptocurrency, required if \&#39;amount_to\&#39; empty
     * @param {string} [amountTo] The total amount, including fees, to be received in cryptocurrency, required if \&#39;amount_from\&#39; empty
     * @param {string} [network] Crypotocurency network, if empty default used (default and allowed networks described in /lib/currencies)
     * @param {'card' | 'iban_exchange' | 'iban_invoice'} [paymentAlias] Payment method alias
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyApiInterface
     */
    b2bOorBuyRatesRaw(requestParameters: B2bOorBuyRatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bOorBuyRates200ResponseAPIModel>>;

    /**
     * GET /b2b/oor/buy-rates
     * Get rates for on-ramp transaction
     */
    b2bOorBuyRates(from: string, to: string, amountFrom?: string, amountTo?: string, network?: string, paymentAlias?: B2bOorBuyRatesPaymentAliasEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bOorBuyRates200ResponseAPIModel>;

}

/**
 * 
 */
export class BuyApi extends runtime.BaseAPI implements BuyApiInterface {

    /**
     * POST /b2b/fiat/buy
     * Create on-ramp transaction using \'card\' method
     */
    async b2bBuyRaw(requestParameters: B2bBuyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bBuy200ResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/fiat/buy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: B2bBuyRequestAPIModelToJSON(requestParameters.b2bBuyRequestAPIModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bBuy200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * POST /b2b/fiat/buy
     * Create on-ramp transaction using \'card\' method
     */
    async b2bBuy(b2bBuyRequestAPIModel?: B2bBuyRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bBuy200ResponseAPIModel> {
        const response = await this.b2bBuyRaw({ b2bBuyRequestAPIModel: b2bBuyRequestAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * POST /b2b/fiat/buy/iban-invoice
     * Create on-ramp transaction using \'iban_invoice\' method
     */
    async b2bBuyIbanInvoiceRaw(requestParameters: B2bBuyIbanInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bBuyIbanInvoice200ResponseAPIModel>> {
        if (requestParameters.trxToken === null || requestParameters.trxToken === undefined) {
            throw new runtime.RequiredError('trxToken','Required parameter requestParameters.trxToken was null or undefined when calling b2bBuyIbanInvoice.');
        }

        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling b2bBuyIbanInvoice.');
        }

        const queryParameters: any = {};

        if (requestParameters.trxToken !== undefined) {
            queryParameters['trx_token'] = requestParameters.trxToken;
        }

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        if (requestParameters.merchantTransactionId !== undefined) {
            queryParameters['merchant_transaction_id'] = requestParameters.merchantTransactionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/fiat/buy/iban-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bBuyIbanInvoice200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * POST /b2b/fiat/buy/iban-invoice
     * Create on-ramp transaction using \'iban_invoice\' method
     */
    async b2bBuyIbanInvoice(trxToken: string, address: string, merchantTransactionId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bBuyIbanInvoice200ResponseAPIModel> {
        const response = await this.b2bBuyIbanInvoiceRaw({ trxToken: trxToken, address: address, merchantTransactionId: merchantTransactionId }, initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/fiat/buy-methods
     * Get methods for on-ramp transaction
     */
    async b2bBuyMethodsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bBuyMethods200ResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/fiat/buy-methods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bBuyMethods200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/fiat/buy-methods
     * Get methods for on-ramp transaction
     */
    async b2bBuyMethods(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bBuyMethods200ResponseAPIModel> {
        const response = await this.b2bBuyMethodsRaw(initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/fiat/buy-rates
     * Get rates for on-ramp transaction
     */
    async b2bBuyRatesRaw(requestParameters: B2bBuyRatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bBuyRates200ResponseAPIModel>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling b2bBuyRates.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling b2bBuyRates.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.amountFrom !== undefined) {
            queryParameters['amount_from'] = requestParameters.amountFrom;
        }

        if (requestParameters.amountTo !== undefined) {
            queryParameters['amount_to'] = requestParameters.amountTo;
        }

        if (requestParameters.network !== undefined) {
            queryParameters['network'] = requestParameters.network;
        }

        if (requestParameters.paymentAlias !== undefined) {
            queryParameters['payment_alias'] = requestParameters.paymentAlias;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/fiat/buy-rates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bBuyRates200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/fiat/buy-rates
     * Get rates for on-ramp transaction
     */
    async b2bBuyRates(from: string, to: string, amountFrom?: string, amountTo?: string, network?: string, paymentAlias?: B2bBuyRatesPaymentAliasEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bBuyRates200ResponseAPIModel> {
        const response = await this.b2bBuyRatesRaw({ from: from, to: to, amountFrom: amountFrom, amountTo: amountTo, network: network, paymentAlias: paymentAlias }, initOverrides);
        return await response.value();
    }

    /**
     * POST /b2b/fiat/buy/card
     * On-ramp transaction with card attributes
     */
    async b2bFiatBuyCardRaw(requestParameters: B2bFiatBuyCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bFiatBuyCard200ResponseAPIModel>> {
        if (requestParameters.b2bFiatBuyCardRequestAPIModel === null || requestParameters.b2bFiatBuyCardRequestAPIModel === undefined) {
            throw new runtime.RequiredError('b2bFiatBuyCardRequestAPIModel','Required parameter requestParameters.b2bFiatBuyCardRequestAPIModel was null or undefined when calling b2bFiatBuyCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/fiat/buy/card`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: B2bFiatBuyCardRequestAPIModelToJSON(requestParameters.b2bFiatBuyCardRequestAPIModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bFiatBuyCard200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * POST /b2b/fiat/buy/card
     * On-ramp transaction with card attributes
     */
    async b2bFiatBuyCard(b2bFiatBuyCardRequestAPIModel: B2bFiatBuyCardRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bFiatBuyCard200ResponseAPIModel> {
        const response = await this.b2bFiatBuyCardRaw({ b2bFiatBuyCardRequestAPIModel: b2bFiatBuyCardRequestAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * GET /b2b/oor/buy-rates
     * Get rates for on-ramp transaction
     */
    async b2bOorBuyRatesRaw(requestParameters: B2bOorBuyRatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B2bOorBuyRates200ResponseAPIModel>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling b2bOorBuyRates.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling b2bOorBuyRates.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.amountFrom !== undefined) {
            queryParameters['amount_from'] = requestParameters.amountFrom;
        }

        if (requestParameters.amountTo !== undefined) {
            queryParameters['amount_to'] = requestParameters.amountTo;
        }

        if (requestParameters.network !== undefined) {
            queryParameters['network'] = requestParameters.network;
        }

        if (requestParameters.paymentAlias !== undefined) {
            queryParameters['payment_alias'] = requestParameters.paymentAlias;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["B2B-Bearer-Token"] = this.configuration.apiKey("B2B-Bearer-Token"); // b2b-bearer-token authentication
        }

        const response = await this.request({
            path: `/b2b/oor/buy-rates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B2bOorBuyRates200ResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * GET /b2b/oor/buy-rates
     * Get rates for on-ramp transaction
     */
    async b2bOorBuyRates(from: string, to: string, amountFrom?: string, amountTo?: string, network?: string, paymentAlias?: B2bOorBuyRatesPaymentAliasEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B2bOorBuyRates200ResponseAPIModel> {
        const response = await this.b2bOorBuyRatesRaw({ from: from, to: to, amountFrom: amountFrom, amountTo: amountTo, network: network, paymentAlias: paymentAlias }, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const B2bBuyRatesPaymentAliasEnum = {
    card: 'card',
    iban_exchange: 'iban_exchange',
    iban_invoice: 'iban_invoice'
} as const;
export type B2bBuyRatesPaymentAliasEnum = typeof B2bBuyRatesPaymentAliasEnum[keyof typeof B2bBuyRatesPaymentAliasEnum];
/**
 * @export
 */
export const B2bOorBuyRatesPaymentAliasEnum = {
    card: 'card',
    iban_exchange: 'iban_exchange',
    iban_invoice: 'iban_invoice'
} as const;
export type B2bOorBuyRatesPaymentAliasEnum = typeof B2bOorBuyRatesPaymentAliasEnum[keyof typeof B2bOorBuyRatesPaymentAliasEnum];
