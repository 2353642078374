/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { B2bTransactions200ResponseDataInnerFailedReasonAPIModel } from './B2bTransactions200ResponseDataInnerFailedReasonAPIModel';
import {
    B2bTransactions200ResponseDataInnerFailedReasonAPIModelFromJSON,
    B2bTransactions200ResponseDataInnerFailedReasonAPIModelFromJSONTyped,
    B2bTransactions200ResponseDataInnerFailedReasonAPIModelToJSON,
} from './B2bTransactions200ResponseDataInnerFailedReasonAPIModel';
import type { B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel } from './B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel';
import {
    B2bTransactions200ResponseDataInnerRecipientsInnerAPIModelFromJSON,
    B2bTransactions200ResponseDataInnerRecipientsInnerAPIModelFromJSONTyped,
    B2bTransactions200ResponseDataInnerRecipientsInnerAPIModelToJSON,
} from './B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel';

/**
 * 
 * @export
 * @interface B2bTransactions200ResponseDataInnerAPIModel
 */
export interface B2bTransactions200ResponseDataInnerAPIModel {
    /**
     * Crypto wallet address
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    address?: string;
    /**
     * Recipient’s country if suitable for transaction type. Field is missing otherwise.
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    beneficiaryCountry?: string;
    /**
     * Recipient’s IBAN if suitable for transaction type. Field is missing otherwise.
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    beneficiaryIban?: string;
    /**
     * Recipient’s full name if suitable for transaction type. Field is missing otherwise.
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    beneficiaryName?: string;
    /**
     * Recipient’s SWIFT if suitable for transaction type. Field is missing otherwise.
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    beneficiarySwift?: string;
    /**
     * Blockchain transaction hash
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    blockchainHash?: string;
    /**
     * Transaction created at
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    createdAt?: string;
    /**
     * Crypto amount
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    cryptoAmount?: string;
    /**
     * Crypto currency
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    cryptoCurrency?: string;
    /**
     * Transaction's fee
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    fee?: string;
    /**
     * Fiat amount
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    fiatAmount?: string;
    /**
     * Fiat currency
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    fiatCurrency?: string;
    /**
     * Off-chain transaction
     * @type {boolean}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    isOffchain?: boolean;
    /**
     * Merchant transaction id
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    merchantTransactionId?: string;
    /**
     * Blockchain network if suitable for transaction type. Field is missing otherwise.
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    network?: string;
    /**
     * Purpose for payment if suitable for transaction type. Field is missing otherwise.
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    purpose?: string;
    /**
     * List of recipients IBAN data
     * @type {Array<B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel>}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    recipients?: Array<B2bTransactions200ResponseDataInnerRecipientsInnerAPIModel>;
    /**
     * Merchant transaction id of related iban_charge transaction if suitable for current transaction type
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    relatedCharge?: string;
    /**
     * Merchant transaction id of related *_refund transaction if suitable for current transaction type
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    relatedRefund?: string;
    /**
     * Merchant transaction id of related source transaction if suitable for current transaction type
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    relatedTransaction?: string;
    /**
     * Sender’s country if suitable for transaction type. Field is missing otherwise.
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    senderCountry?: string;
    /**
     * Sender’s full name if suitable for transaction type. Field is missing otherwise.
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    senderName?: string;
    /**
     * Sender’s SWIFT if suitable for transaction type. Field is missing otherwise.
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    senderSwift?: string;
    /**
     * Transaction status
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    status?: B2bTransactions200ResponseDataInnerAPIModelStatusEnum;
    /**
     * Transaction type
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    type?: B2bTransactions200ResponseDataInnerAPIModelTypeEnum;
    /**
     * Transaction updated at
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    updatedAt?: string;
    /**
     * User uuid
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    userUuid?: string;
    /**
     * 
     * @type {B2bTransactions200ResponseDataInnerFailedReasonAPIModel}
     * @memberof B2bTransactions200ResponseDataInnerAPIModel
     */
    failedReason?: B2bTransactions200ResponseDataInnerFailedReasonAPIModel;
}


/**
 * @export
 */
export const B2bTransactions200ResponseDataInnerAPIModelStatusEnum = {
    pending: 'pending',
    succeeded: 'succeeded',
    cancelled: 'cancelled',
    failed: 'failed',
    need_verify: 'need_verify',
    refunded: 'refunded'
} as const;
export type B2bTransactions200ResponseDataInnerAPIModelStatusEnum = typeof B2bTransactions200ResponseDataInnerAPIModelStatusEnum[keyof typeof B2bTransactions200ResponseDataInnerAPIModelStatusEnum];

/**
 * @export
 */
export const B2bTransactions200ResponseDataInnerAPIModelTypeEnum = {
    buy_card: 'buy_card',
    buy_iban: 'buy_iban',
    buy_iban_invoice: 'buy_iban_invoice',
    sell_card: 'sell_card',
    sell_iban: 'sell_iban',
    deposit: 'deposit',
    withdraw: 'withdraw',
    fiat_deposit: 'fiat_deposit',
    fiat_withdraw: 'fiat_withdraw',
    caas_buy: 'caas_buy',
    caas_sell: 'caas_sell',
    buy_iban_refund: 'buy_iban_refund',
    sell_iban_refund: 'sell_iban_refund',
    fiat_deposit_refund: 'fiat_deposit_refund',
    fiat_withdraw_refund: 'fiat_withdraw_refund',
    iban_charge_refund: 'iban_charge_refund',
    service_deposit: 'service_deposit',
    service_withdraw: 'service_withdraw',
    service_fiat_deposit: 'service_fiat_deposit',
    service_fiat_withdraw: 'service_fiat_withdraw'
} as const;
export type B2bTransactions200ResponseDataInnerAPIModelTypeEnum = typeof B2bTransactions200ResponseDataInnerAPIModelTypeEnum[keyof typeof B2bTransactions200ResponseDataInnerAPIModelTypeEnum];


/**
 * Check if a given object implements the B2bTransactions200ResponseDataInnerAPIModel interface.
 */
export function instanceOfB2bTransactions200ResponseDataInnerAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bTransactions200ResponseDataInnerAPIModelFromJSON(json: any): B2bTransactions200ResponseDataInnerAPIModel {
    return B2bTransactions200ResponseDataInnerAPIModelFromJSONTyped(json, false);
}

export function B2bTransactions200ResponseDataInnerAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bTransactions200ResponseDataInnerAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : json['address'],
        'beneficiaryCountry': !exists(json, 'beneficiary_country') ? undefined : json['beneficiary_country'],
        'beneficiaryIban': !exists(json, 'beneficiary_iban') ? undefined : json['beneficiary_iban'],
        'beneficiaryName': !exists(json, 'beneficiary_name') ? undefined : json['beneficiary_name'],
        'beneficiarySwift': !exists(json, 'beneficiary_swift') ? undefined : json['beneficiary_swift'],
        'blockchainHash': !exists(json, 'blockchain_hash') ? undefined : json['blockchain_hash'],
        'createdAt': !exists(json, 'created_at') ? undefined : json['created_at'],
        'cryptoAmount': !exists(json, 'crypto_amount') ? undefined : json['crypto_amount'],
        'cryptoCurrency': !exists(json, 'crypto_currency') ? undefined : json['crypto_currency'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
        'fiatAmount': !exists(json, 'fiat_amount') ? undefined : json['fiat_amount'],
        'fiatCurrency': !exists(json, 'fiat_currency') ? undefined : json['fiat_currency'],
        'isOffchain': !exists(json, 'is_offchain') ? undefined : json['is_offchain'],
        'merchantTransactionId': !exists(json, 'merchant_transaction_id') ? undefined : json['merchant_transaction_id'],
        'network': !exists(json, 'network') ? undefined : json['network'],
        'purpose': !exists(json, 'purpose') ? undefined : json['purpose'],
        'recipients': !exists(json, 'recipients') ? undefined : ((json['recipients'] as Array<any>).map(B2bTransactions200ResponseDataInnerRecipientsInnerAPIModelFromJSON)),
        'relatedCharge': !exists(json, 'related_charge') ? undefined : json['related_charge'],
        'relatedRefund': !exists(json, 'related_refund') ? undefined : json['related_refund'],
        'relatedTransaction': !exists(json, 'related_transaction') ? undefined : json['related_transaction'],
        'senderCountry': !exists(json, 'sender_country') ? undefined : json['sender_country'],
        'senderName': !exists(json, 'sender_name') ? undefined : json['sender_name'],
        'senderSwift': !exists(json, 'sender_swift') ? undefined : json['sender_swift'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'updatedAt': !exists(json, 'updated_at') ? undefined : json['updated_at'],
        'userUuid': !exists(json, 'user_uuid') ? undefined : json['user_uuid'],
        'failedReason': !exists(json, 'failed_reason') ? undefined : B2bTransactions200ResponseDataInnerFailedReasonAPIModelFromJSON(json['failed_reason']),
    };
}

export function B2bTransactions200ResponseDataInnerAPIModelToJSON(value?: B2bTransactions200ResponseDataInnerAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'beneficiary_country': value.beneficiaryCountry,
        'beneficiary_iban': value.beneficiaryIban,
        'beneficiary_name': value.beneficiaryName,
        'beneficiary_swift': value.beneficiarySwift,
        'blockchain_hash': value.blockchainHash,
        'created_at': value.createdAt,
        'crypto_amount': value.cryptoAmount,
        'crypto_currency': value.cryptoCurrency,
        'fee': value.fee,
        'fiat_amount': value.fiatAmount,
        'fiat_currency': value.fiatCurrency,
        'is_offchain': value.isOffchain,
        'merchant_transaction_id': value.merchantTransactionId,
        'network': value.network,
        'purpose': value.purpose,
        'recipients': value.recipients === undefined ? undefined : ((value.recipients as Array<any>).map(B2bTransactions200ResponseDataInnerRecipientsInnerAPIModelToJSON)),
        'related_charge': value.relatedCharge,
        'related_refund': value.relatedRefund,
        'related_transaction': value.relatedTransaction,
        'sender_country': value.senderCountry,
        'sender_name': value.senderName,
        'sender_swift': value.senderSwift,
        'status': value.status,
        'type': value.type,
        'updated_at': value.updatedAt,
        'user_uuid': value.userUuid,
        'failed_reason': B2bTransactions200ResponseDataInnerFailedReasonAPIModelToJSON(value.failedReason),
    };
}

