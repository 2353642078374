import { Alert as MuiAlert, AlertProps, AlertTitle, Theme } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { NotificationMessageProps } from '@/components/output/NotificationMessage/types';

const useStyles = makeStyles()((theme: Theme) => ({
  alert: {
    margin: theme.spacing(4),
    marginTop: 0,
  },
}));

const Alert: React.FC<AlertProps> = (props: AlertProps) => <MuiAlert elevation={2} {...props} />;

const NotificationMessage: React.FC<NotificationMessageProps> = ({
  title,
  className,
  children,
  severity = 'error',
  'data-test': dataTest,
  ...props
}) => {
  const { cx, classes } = useStyles();
  return (
    <Alert severity={severity} className={cx(className, classes.alert)} data-test={dataTest} {...props}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </Alert>
  );
};

export default React.memo(NotificationMessage);
