import BigNumber from 'bignumber.js';
import { useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { fetchKYCLimits } from '@/features/kyc/actions';
import { makeSelectKYCLimits, makeSelectKYCStatus } from '@/features/kyc/selectors';
import type { KYCLimits } from '@/features/kyc/types';
import { useLoadableData } from '@/hooks';
import { noopAsync } from '@/utils/functions';
import { mapStoredState, storedDataLoaded } from '@/utils/model';

const limitsFetch = (force: boolean) => fetchKYCLimits({ force });

const noLimits = storedDataLoaded<KYCLimits, string>({});
const noLimitsSelector = () => noLimits;

const limitsSelector = makeSelectKYCLimits();
const statusSelector = makeSelectKYCStatus();

const toBinNumberIfPresented = (v?: string) => (v ? new BigNumber(v) : undefined);

const useKYCLimits = (currency: string) => {
  const status = useAppSelector(statusSelector);
  const selector = status !== 'complete' ? limitsSelector : noLimitsSelector;
  const fetch = status !== 'complete' ? limitsFetch : noopAsync;
  const { data: allData, ...rest } = useLoadableData(fetch, selector);
  return useMemo(
    () => ({
      ...rest,
      data: mapStoredState(allData, (v) =>
        v[currency]?.remain || v[currency]?.total
          ? {
              remain: toBinNumberIfPresented(v[currency]?.remain),
              total: toBinNumberIfPresented(v[currency]?.total),
            }
          : undefined,
      ),
    }),
    [allData, currency, rest],
  );
};

export default useKYCLimits;
