/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModel
 */
export interface B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModel {
    /**
     * remain limit per month
     * @type {string}
     * @memberof B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModel
     */
    remain?: string;
    /**
     * total limit per month
     * @type {string}
     * @memberof B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModel
     */
    total?: string;
}

/**
 * Check if a given object implements the B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModel interface.
 */
export function instanceOfB2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModelFromJSON(json: any): B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModel {
    return B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModelFromJSONTyped(json, false);
}

export function B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'remain': !exists(json, 'remain') ? undefined : json['remain'],
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModelToJSON(value?: B2bUserCardLimits200ResponseDataCurrenciesLimitsValueMonthAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'remain': value.remain,
        'total': value.total,
    };
}

