import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import type { AppAsyncThunkAction, AppRootState } from '@/app/store';
import type { LoadingStateWithDirty } from '@/utils/model';
import type { Func } from '@/utils/ts';
import { isThenable } from '@/utils/ts';

import useFetching from './useFetching';
import useSubmitting from './useSubmitting';

export interface UseLoadableDataType<T> {
  data: LoadingStateWithDirty<T>;
  loading: boolean;
  forceRefresh: Func;
}

const falseOp = () => false;

export default function useLoadableData<T, R = void>(
  // for the moment created function should fail-safe
  fetchActionFactory: ((force: boolean) => AppAsyncThunkAction<R, unknown>) | ((force: boolean) => Promise<R>),
  dataSelector: (state: AppRootState) => LoadingStateWithDirty<T>,
  dataFetchingSelector?: ((state: AppRootState) => boolean) | undefined,
): UseLoadableDataType<T> {
  const [fetching, withFetching] = useSubmitting(false);
  const dispatch = useAppDispatch();
  const data = useAppSelector(dataSelector);
  const dataFetching = useAppSelector(dataFetchingSelector || falseOp);
  const isDirty = !data || data.isDirty;
  const loading = dataFetching || fetching;
  const callOrDispatch = useMemo(
    () =>
      withFetching(async (force: boolean) => {
        const call = fetchActionFactory(force);
        // to think of unwrap the action
        return isThenable(call) ? call : dispatch(call);
      }),
    [dispatch, fetchActionFactory, withFetching],
  );
  const fetch = useCallback(() => callOrDispatch(false), [callOrDispatch]);
  const forceRefresh = useCallback(() => callOrDispatch(true), [callOrDispatch]);
  useFetching(fetch, isDirty, loading);
  return { data, forceRefresh, loading };
}
