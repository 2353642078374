import React, { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import Space from '@/components/layout/Space';

const useStyles = makeStyles()(() => ({
  container: {
    flexGrow: 1,
    alignItems: 'center',
  },
  left: {
    display: 'flex',
    justifySelf: 'flex-start',
    marginRight: 'auto',
  },
  right: {
    display: 'flex',
    justifySelf: 'flex-end',
    marginLeft: 'auto',
  },
}));

interface LeftRightPositioningProps {
  left?: ReactNode;
  right?: ReactNode;
  className?: string;
  leftClassName?: string;
  rightClassName?: string;
  style?: React.CSSProperties;
}

export const LeftRightPositioning: React.FC<LeftRightPositioningProps> = ({
  left,
  right,
  className,
  leftClassName,
  rightClassName,
  style,
}) => {
  const { cx, classes } = useStyles();

  return (
    <Space className={cx(classes.container, className)} style={style}>
      {left && <Space className={cx(classes.left, leftClassName)}>{left}</Space>}
      {right && <Space className={cx(classes.right, rightClassName)}>{right}</Space>}
    </Space>
  );
};

export default React.memo(LeftRightPositioning);
