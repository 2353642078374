import { Grid } from '@mui/material';
import React, { useCallback } from 'react';

import { FormattedMessage, TextFieldInput } from '@/components';
import { I18nFeatureMock } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import type { Value } from '@/utils/model';

import type { Card3dsInputProps } from './types';

const Card3dsInput: React.FC<Card3dsInputProps> = ({ 'data-test': dataTest, onChange }) => {
  const [value, setValue] = useStateMountSafe<Value<string>>({ hasError: false });

  const doUpdate3ds = useCallback(
    (newValue: string) => {
      const hasError = newValue.length !== 4;
      setValue({ hasError, value: newValue });
      onChange?.({ hasError, value: newValue });
    },
    [onChange, setValue],
  );
  return (
    <Grid container spacing={2} columns={2} data-test={dataTest}>
      <Grid item xs={2}>
        <TextFieldInput
          InputProps={{ className: 'ym-hide-content' }}
          value={value}
          onChange={doUpdate3ds}
          messages={{
            label: <FormattedMessage id={I18nFeatureMock.COMPONENTS_CARD3DS_INPUT_LABEL} />,
            error: <FormattedMessage id={I18nFeatureMock.COMPONENTS_CARD3DS_INPUT_ERROR} />,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(Card3dsInput);
