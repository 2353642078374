
import { fetchCards } from '@/features/cards/actions';
import { makeSelectCards } from '@/features/cards/selectors';
import { useLoadableData } from '@/hooks';

const fetch = (force: boolean) => fetchCards({ force });
const selectCards = makeSelectCards();

export default function useCards() {
  return useLoadableData(fetch, selectCards);
}
