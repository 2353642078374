/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel
 */
export interface B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel {
    /**
     * Alpha-3 country code
     * @type {string}
     * @memberof B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel
     */
    docCountryCode?: string;
    /**
     * Image ID
     * @type {string}
     * @memberof B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel
     */
    docId?: string;
    /**
     * Document check status
     * @type {string}
     * @memberof B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel
     */
    docState?: B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModelDocStateEnum;
    /**
     * Document type
     * @type {string}
     * @memberof B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel
     */
    docType?: string;
    /**
     * Optional error message for a partner
     * @type {string}
     * @memberof B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel
     */
    partnerMessage?: string;
    /**
     * Optional array of error codes
     * @type {Array<string>}
     * @memberof B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel
     */
    rejectLabels?: Array<string>;
    /**
     * Optional field with reject type
     * @type {string}
     * @memberof B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel
     */
    rejectType?: B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModelRejectTypeEnum;
    /**
     * Optional error message for a user
     * @type {string}
     * @memberof B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel
     */
    userMessage?: string;
}


/**
 * @export
 */
export const B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModelDocStateEnum = {
    green: 'green',
    red: 'red',
    not_started: 'not_started'
} as const;
export type B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModelDocStateEnum = typeof B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModelDocStateEnum[keyof typeof B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModelDocStateEnum];

/**
 * @export
 */
export const B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModelRejectTypeEnum = {
    RETRY: 'RETRY',
    FINAL: 'FINAL'
} as const;
export type B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModelRejectTypeEnum = typeof B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModelRejectTypeEnum[keyof typeof B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModelRejectTypeEnum];


/**
 * Check if a given object implements the B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel interface.
 */
export function instanceOfB2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModelFromJSON(json: any): B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel {
    return B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModelFromJSONTyped(json, false);
}

export function B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'docCountryCode': !exists(json, 'doc_country_code') ? undefined : json['doc_country_code'],
        'docId': !exists(json, 'doc_id') ? undefined : json['doc_id'],
        'docState': !exists(json, 'doc_state') ? undefined : json['doc_state'],
        'docType': !exists(json, 'doc_type') ? undefined : json['doc_type'],
        'partnerMessage': !exists(json, 'partner_message') ? undefined : json['partner_message'],
        'rejectLabels': !exists(json, 'reject_labels') ? undefined : json['reject_labels'],
        'rejectType': !exists(json, 'reject_type') ? undefined : json['reject_type'],
        'userMessage': !exists(json, 'user_message') ? undefined : json['user_message'],
    };
}

export function B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModelToJSON(value?: B2bUserGetKycDocuments200ResponseDataReviewResultDocumentsInnerAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'doc_country_code': value.docCountryCode,
        'doc_id': value.docId,
        'doc_state': value.docState,
        'doc_type': value.docType,
        'partner_message': value.partnerMessage,
        'reject_labels': value.rejectLabels,
        'reject_type': value.rejectType,
        'user_message': value.userMessage,
    };
}

