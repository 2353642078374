import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useCards } from '@/features/cards/hooks';
import { newCardId } from '@/features/cards/types';
import { I18nFeatureCards } from '@/generated/i18n/i18n';

import type { CardsSelectProps } from './types';

const CardsSelect: React.FC<CardsSelectProps> = ({ 'data-test': dataTest, value, onChange, disabled }) => {
  const cardState = useCards();
  const cards = useMemo(
    () => [
      ...(cardState.data.data?.map(({ cardId, maskedNumber }) => ({
        cardId,
        message: (
          <FormattedMessage
            id={I18nFeatureCards.COMPONENTS_SELECT_OLDCARD_TITLE}
            values={{ mask: maskedNumber.substring(maskedNumber.length - 4) }}
          />
        ),
      })) || []),
      {
        cardId: newCardId,
        message: <FormattedMessage id={I18nFeatureCards.COMPONENTS_SELECT_NEWCARD_TITLE} />,
      },
    ],
    [cardState.data.data],
  );
  const doSelectCard = useCallback(async (_: unknown, newValue: string) => onChange(newValue), [onChange]);
  return (
    <FormControl variant="standard">
      <RadioGroup aria-labelledby="cards" name="cards" value={value} onChange={doSelectCard}>
        {cards.map(({ cardId, message }) => (
          <FormControlLabel
            className="ym-hide-content"
            value={cardId}
            key={cardId}
            control={<Radio data-test={dataTest && `${dataTest}-${cardId}-select`} disabled={disabled} />}
            label={message}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default React.memo(CardsSelect);
