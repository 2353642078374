import { createAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { createAppAsyncThunk } from '@/app/actions';
import { sendCallback } from '@/features/intercom/actions';
import { MercuryQueryParams } from '@/features/intercom/types';
import { goalReached, YMGoals } from '@/infrastructure/ym';
import createCache from '@/utils/cache';
import { asType } from '@/utils/ts';

import { makeSelectRequest } from './selectors';
import {
  GlobalIdentifiedNotification,
  GlobalLoadingState,
  GlobalNotification,
  NAMESPACE,
  WidgetInitialRequest,
} from './types';

export const globalInit = createAction(`${NAMESPACE}/INIT`);
export const addNotificationToStore = createAction<GlobalIdentifiedNotification>(`${NAMESPACE}/storeNotification`);
export const removeNotificationFromStore = createAction<GlobalIdentifiedNotification['id']>(
  `${NAMESPACE}/removeNotification`,
);

export const createNotification = createAppAsyncThunk(
  `${NAMESPACE}/createNotification`,
  async ({ timeout = 6000, ...notification }: GlobalNotification & { timeout?: number | 'infinite' }, { dispatch }) => {
    const id = uuid();
    dispatch(addNotificationToStore({ id, ...notification }));
    if (timeout !== 'infinite') {
      setTimeout(() => dispatch(removeNotificationFromStore(id)), timeout);
    }
  },
);

export const storeRequestState = createAction<
  Partial<WidgetInitialRequest & { initByMessagingRequired: true } & { initialized: 'by-message' | 'by-url' }>
>(`${NAMESPACE}/storeRequestState`);

export const initializeFromURL = createAppAsyncThunk(`${NAMESPACE}/initialize`, async (_, { dispatch }) => {
  goalReached(YMGoals.WIDGET_OPENED);
  const queryParams = new URLSearchParams(window.location.search);
  const locale = queryParams.get(asType<MercuryQueryParams>('lang')) || undefined;
  const token = queryParams.get(asType<MercuryQueryParams>('token')) || undefined;
  const buyAmount = queryParams.get(asType<MercuryQueryParams>('buy-amount')) || undefined;
  const buyNetwork = queryParams.get(asType<MercuryQueryParams>('buy-network')) || undefined;
  const buyCurrency = queryParams.get(asType<MercuryQueryParams>('buy-currency')) || undefined;
  const fiatCurrency = queryParams.get(asType<MercuryQueryParams>('fiat-currency')) || undefined;
  const merchantTx = queryParams.get(asType<MercuryQueryParams>('tx-id')) || undefined;
  const address = queryParams.get(asType<MercuryQueryParams>('address')) || undefined;
  const isMockEnabled = !!queryParams.get(asType<MercuryQueryParams>('mock'));
  const initByMessaging = !!queryParams.get(asType<MercuryQueryParams>('messaging-init'));
  dispatch(
    storeRequestState({
      locale,
      token,
      buyNetwork,
      buyAmount,
      buyCurrency,
      fiatCurrency,
      merchantTx,
      address,
      isMockEnabled,
      ...(initByMessaging ? { initByMessagingRequired: true } : {}),
      initialized: 'by-url',
    }),
  );
  if (!initByMessaging) {
    goalReached(YMGoals.WIDGET_INITIALIZED);
    await dispatch(sendCallback({ type: 'loaded' }));
  } else {
    await dispatch(sendCallback({ type: 'ready-to-init' }));
  }
});

const localeCache = createCache<string>('NCPS_LOCALE');

export const storeLoading = createAction<GlobalLoadingState>(`${NAMESPACE}/storeLoading`);
export const storeLocale = createAction<string>(`${NAMESPACE}/storeLocale`);
export const storeViewHeight = createAction<number>(`${NAMESPACE}/storeViewHeight`);

export const initLocale = createAppAsyncThunk(`${NAMESPACE}/initLocale`, async (_, { dispatch, getState }) => {
  const { locale: baseLocale } = makeSelectRequest()(getState());
  let locale = baseLocale;
  if (!locale) {
    try {
      locale = localeCache.read();
    } catch (e) {
      console.error(e);
    }
  }
  if (!locale) {
    locale = window.env.DEFAULT_LOCALE;
  }
  await dispatch(storeLocale(locale));
});
