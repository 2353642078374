import React, { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

import type { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  border: {
    borderBottom: 'solid 1px',
    color: theme.colors.secondary,
    width: '100%',
  },
  content: {
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    color: theme.typography.h4.color,
    fontWeight: theme.typography.h4.fontWeight,
    fontSize: theme.typography.h4.fontSize,
  },
}));

const DividerWithText: React.FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.border} />
      <span className={classes.content}>{children}</span>
      <div className={classes.border} />
    </div>
  );
};

export default React.memo(DividerWithText);
