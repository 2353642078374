import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { GlobalBackdrop } from '@/features/global/components';
import { initPurchase } from '@/features/purchase/actions';
import { makeSelectRequestedPurchase } from '@/features/purchase/selectors';
import { useFetching } from '@/hooks';
import { InitStatus } from '@/utils/model';

const selectPaymentInitState = makeSelectRequestedPurchase();

const withPaymentInit =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props: P) => {
    const dispatch = useAppDispatch();
    const initState = useAppSelector(selectPaymentInitState);
    const init = useCallback(async () => dispatch(initPurchase()), [dispatch]);
    useFetching(init, initState.status === InitStatus.NOT_INITIALIZED);
    return initState.status === InitStatus.FINISHED ? <Component {...props} /> : <GlobalBackdrop isLoading />;
  };

export default withPaymentInit;
