import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureBuyPurchase } from '@/generated/i18n/i18n';

import type { RatesUnavailableProps } from './types';

const RatesUnavailable: React.FC<RatesUnavailableProps> = ({ 'data-test': dataTest }) => (
  <FormattedMessage
    id={I18nFeatureBuyPurchase.COMPONENTS_RATES_SUMMARY_NOTAVAILABLE}
    tagName="span"
    data-test={dataTest}
  />
);

export default React.memo(RatesUnavailable);
