import React from 'react';

import DataFetchError from '@/components/output/DataFetchError';
import FormattedMessage from '@/components/output/FormattedMessage';
import SupportEmail from '@/components/output/SupportEmail';
import { I18nComponents } from '@/generated/i18n/i18n';

import type { IFrameErrorBannerProps } from './types';

const IFrameErrorBanner: React.FC<IFrameErrorBannerProps> = ({
  'data-test': dataTest,
  refresh,
  title,
  description,
}) => (
  <DataFetchError
    data-test={dataTest}
    title={title || <FormattedMessage id={I18nComponents.IFRAME_ERROR_BANNER_TITLE} />}
    description={
      description || (
        <FormattedMessage
          id={I18nComponents.IFRAME_ERROR_BANNER_DESCRIPTION}
          values={{ email: <SupportEmail data-test={dataTest && `${dataTest}-support`} /> }}
          tagName="span"
        />
      )
    }
    refresh={refresh}
  />
);

export default React.memo(IFrameErrorBanner);
