import { createSelector } from '@reduxjs/toolkit';

import type { AppRootState } from '@/app/store';
import { makeSelectRequest } from '@/features/global/selectors';
import { NAMESPACE } from '@/features/mock/types';

export const makeSelectIsMockEnabled = () =>
  createSelector(makeSelectRequest(), (state) => !!state.isMockEnabled && window.env.MOCK_MODE_SUPPORTED === 'true');

export const makeSelectMockKYCStatus = () => (state: AppRootState) => state[NAMESPACE].status;
export const makeSelectMockInit = () => (state: AppRootState) => state[NAMESPACE].init;
export const makeSelectMockInitStatus = () => (state: AppRootState) => state[NAMESPACE].init.status;
export const makeSelectMockTransaction = () => (state: AppRootState) => state[NAMESPACE].init.value?.tx;
