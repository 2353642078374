import { Divider, useMediaQuery } from '@mui/material';
import React from 'react';

import { FormattedMessage } from '@/components';
import { withAuthorizationInit } from '@/features/auth/hocs';
import { TransactionCardWidget } from '@/features/transaction/components';
import { I18nFeatureBuyTransaction } from '@/generated/i18n/i18n';
import { MainLayout } from '@/layout';
import { withKYCGuard } from '@/pages/buy/kyc/hocs';
import { BuyPageRoutes, buyRoute } from '@/pages/buy/routes';

const TransactionPage: React.FC = () => {
  const isLowHeight = useMediaQuery('@media (max-height: 450px)');
  return <MainLayout
    header={{
      title: <FormattedMessage id={I18nFeatureBuyTransaction.PAGE_TITLE} />,
    }}
    buttons={{
      back: buyRoute(BuyPageRoutes.PURCHASE),
    }}
  >
    {!isLowHeight && <Divider orientation="horizontal" />}
    <TransactionCardWidget data-test="card" />
  </MainLayout>;
}

export default withAuthorizationInit(withKYCGuard(buyRoute(BuyPageRoutes.KYC))(TransactionPage));
