import React from 'react';
import { useSelector } from 'react-redux';

import { FormattedMessage } from '@/components';
import { makeSelectIsMockEnabled } from '@/features/mock/selectors';
import { I18nFeatureMock } from '@/generated/i18n/i18n';
import { MessageLayout } from '@/layout';

const selectIsMockEnabled = makeSelectIsMockEnabled();

const withMockEnabled =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props: P) => {
    const isMockEnabled = useSelector(selectIsMockEnabled);
    return isMockEnabled ? (
      <Component {...props} />
    ) : (
      <MessageLayout
        message={<FormattedMessage id={I18nFeatureMock.DISABLED_TITLE} />}
        description={<FormattedMessage id={I18nFeatureMock.DISABLED_DESCRIPTION} />}
      />
    );
  };

export default withMockEnabled;
