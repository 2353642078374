import BigNumber from 'bignumber.js';
import { useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { fetchCardLimits } from '@/features/purchase/actions';
import { makeSelectCardLimits, makeSelectPurchase } from '@/features/purchase/selectors';
import { PurchaseLimits } from '@/features/purchase/types';
import { createLimitsPairKey } from '@/features/purchase/utils';
import { useLoadableData } from '@/hooks';
import { mapStoredState } from '@/utils/model';

const selectPurchase = makeSelectPurchase();

const toBinNumberIfPresented = (v?: string) => (v ? new BigNumber(v) : undefined);

const toCurrencyLimits = (limits: PurchaseLimits, currency: string) => {
  const min = toBinNumberIfPresented(limits[currency]?.min);
  const dailyLimit = toBinNumberIfPresented(limits[currency]?.day?.remain || limits[currency]?.day?.total);
  const monthlyLimit = toBinNumberIfPresented(limits[currency]?.month?.remain || limits[currency]?.month?.total);
  const max = toBinNumberIfPresented(limits[currency]?.max);
  return {
    min,
    dailyLimit,
    monthlyLimit,
    max,
    activeLimit: dailyLimit || monthlyLimit || max,
    currency,
  };
};

const usePurchaseCardLimits = () => {
  const { fiatToPay, cryptoToBuy } = useAppSelector(selectPurchase);
  const pair = useMemo(() => createLimitsPairKey(fiatToPay, cryptoToBuy.currency), [cryptoToBuy.currency, fiatToPay]);
  const fetch = useMemo(
    () => (force: boolean) => fetchCardLimits({ force, cryptoToBuy: cryptoToBuy.currency, fiatToPay }),
    [cryptoToBuy.currency, fiatToPay],
  );
  const selectLimits = useMemo(() => makeSelectCardLimits(pair), [pair]);
  const { data, ...rest } = useLoadableData(fetch, selectLimits);
  return useMemo(
    () => ({
      ...rest,
      data: mapStoredState(data, (limits) => ({
        fiat: toCurrencyLimits(limits, fiatToPay),
        crypto: toCurrencyLimits(limits, cryptoToBuy.currency),
      })),
    }),
    [cryptoToBuy.currency, data, fiatToPay, rest],
  );
};

export default usePurchaseCardLimits;
