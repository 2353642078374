/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bTransactions200ResponseDataInnerFailedReasonAPIModel
 */
export interface B2bTransactions200ResponseDataInnerFailedReasonAPIModel {
    /**
     * Recipient country
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerFailedReasonAPIModel
     */
    publicMessage?: string;
    /**
     * Recipient IBAN
     * @type {string}
     * @memberof B2bTransactions200ResponseDataInnerFailedReasonAPIModel
     */
    message?: string;
}

/**
 * Check if a given object implements the B2bTransactions200ResponseDataInnerFailedReasonAPIModel interface.
 */
export function instanceOfB2bTransactions200ResponseDataInnerFailedReasonAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bTransactions200ResponseDataInnerFailedReasonAPIModelFromJSON(json: any): B2bTransactions200ResponseDataInnerFailedReasonAPIModel {
    return B2bTransactions200ResponseDataInnerFailedReasonAPIModelFromJSONTyped(json, false);
}

export function B2bTransactions200ResponseDataInnerFailedReasonAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bTransactions200ResponseDataInnerFailedReasonAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'publicMessage': !exists(json, 'public_message') ? undefined : json['public_message'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function B2bTransactions200ResponseDataInnerFailedReasonAPIModelToJSON(value?: B2bTransactions200ResponseDataInnerFailedReasonAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'public_message': value.publicMessage,
        'message': value.message,
    };
}

