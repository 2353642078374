import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/app/hooks';
import { initLocale } from '@/features/global/actions';
import { makeSelectLocale, makeSelectLocaleInitializing } from '@/features/global/selectors';
import { useFetching } from '@/hooks';
import { InitStatus } from '@/utils/model';

const selectLocale = makeSelectLocale();
const selectLocaleInitializing = makeSelectLocaleInitializing();

const withI18nSupport =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props: P) => {
    const dispatch = useAppDispatch();
    const locale = useSelector(selectLocale);
    const localeState = useSelector(selectLocaleInitializing);
    const init = useCallback(async () => dispatch(initLocale()), [dispatch]);
    useFetching(init, localeState === InitStatus.NOT_INITIALIZED);
    return localeState === InitStatus.FINISHED ? <Component locale={locale} {...props} /> : null;
  };

export default withI18nSupport;
