import React, { ReactNode } from 'react';

import { PrimaryButton, SecondaryButton } from '@/components';
import type { Func } from '@/utils/ts';

export interface MainButtonProps {
  'data-test'?: string;
  type?: 'primary' | 'secondary';
  title: ReactNode;
  submit: boolean;
  onAct?: Func;
  disabled?: boolean;
}

export const MainButton: React.FC<MainButtonProps> = ({
  type,
  title,
  submit,
  onAct,
  disabled,
  'data-test': dataTest,
}) =>
  type === 'secondary' ? (
    <SecondaryButton
      data-test={dataTest}
      onClick={!submit ? onAct : undefined}
      disabled={disabled}
      title={title}
      submit={submit}
    />
  ) : (
    <PrimaryButton
      data-test={dataTest}
      onClick={!submit ? onAct : undefined}
      disabled={disabled}
      title={title}
      submit={submit}
    />
  );

export default React.memo(MainButton);
