import { Grid } from '@mui/material';
import React, { useCallback } from 'react';

import { FormattedMessage, TextFieldInput } from '@/components';
import { I18nFeatureMock } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import type { Value } from '@/utils/model';

import type { NewCardInputProps } from './types';

const NewCardInput: React.FC<NewCardInputProps> = ({ 'data-test': dataTest, onChange }) => {
  const [cardNumber, setCardNumber] = useStateMountSafe<Value<string>>({ hasError: false });
  const [validThru, setValidThru] = useStateMountSafe<Value<string>>({ hasError: false });
  const [cvv, setCVV] = useStateMountSafe<Value<string>>({ hasError: false });
  const [holder, setHolder] = useStateMountSafe<Value<string>>({ hasError: false });

  const doCallChange = useCallback(
    (newCardNumber: Value<string>, newValidThru: Value<string>, newCVV: Value<string>, newHolder: Value<string>) => {
      onChange?.({
        value: {
          cvv: newCVV.value || '',
          holder: newHolder.value || '',
          number: newCardNumber.value || '',
          expiration: {
            month: newValidThru.value?.split('/')[0] || '',
            year: newValidThru.value?.split('/')[1] || '',
          },
        },
        hasError:
          !newCardNumber.value
          || newCardNumber.hasError
          || !newValidThru.value
          || newValidThru.hasError
          || !newCVV.value
          || newCVV.hasError
          || !newHolder.value
          || newHolder.hasError,
      });
    },
    [onChange],
  );

  const doUpdateCardNumber = useCallback(
    (value: string) => {
      const hasError = value.length !== 16 || !/^\d+$/.test(value);
      setCardNumber({ hasError, value });
      doCallChange({ hasError, value }, validThru, cvv, holder);
    },
    [cvv, doCallChange, holder, setCardNumber, validThru],
  );
  const doUpdateValidThru = useCallback(
    (value: string) => {
      const hasError = !/^([0][1-9]|[1][0-2])\/([2-9][0-9])/.test(value);
      setValidThru({ hasError, value });
      doCallChange(cardNumber, { hasError, value }, cvv, holder);
    },
    [cardNumber, cvv, doCallChange, holder, setValidThru],
  );
  const doUpdateCVV = useCallback(
    (value: string) => {
      const hasError = value.length !== 3 || !/^\d+$/.test(value);
      setCVV({ hasError, value });
      doCallChange(cardNumber, validThru, { hasError, value }, holder);
    },
    [cardNumber, doCallChange, holder, setCVV, validThru],
  );
  const doUpdateHolder = useCallback(
    (value: string) => {
      const hasError = !value.length;
      setHolder({ hasError, value });
      doCallChange(cardNumber, validThru, cvv, { hasError, value });
    },
    [cardNumber, cvv, doCallChange, setHolder, validThru],
  );
  return (
    <Grid container spacing={2} columns={2} data-test={dataTest}>
      <Grid item xs={2}>
        <TextFieldInput
          InputProps={{ className: 'ym-hide-content' }}
          value={cardNumber}
          onChange={doUpdateCardNumber}
          messages={{
            label: <FormattedMessage id={I18nFeatureMock.COMPONENTS_CARD_NUMBER_LABEL} />,
            error: <FormattedMessage id={I18nFeatureMock.COMPONENTS_CARD_NUMBER_ERROR} />,
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <TextFieldInput
          InputProps={{ className: 'ym-hide-content' }}
          value={validThru}
          onChange={doUpdateValidThru}
          messages={{
            label: <FormattedMessage id={I18nFeatureMock.COMPONENTS_CARD_THRU_LABEL} />,
            error: <FormattedMessage id={I18nFeatureMock.COMPONENTS_CARD_THRU_ERROR} />,
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <TextFieldInput
          InputProps={{ className: 'ym-hide-content' }}
          value={cvv}
          onChange={doUpdateCVV}
          messages={{
            label: <FormattedMessage id={I18nFeatureMock.COMPONENTS_CARD_CVC_LABEL} />,
            error: <FormattedMessage id={I18nFeatureMock.COMPONENTS_CARD_CVC_ERROR} />,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextFieldInput
          InputProps={{ className: 'ym-hide-content' }}
          value={holder}
          onChange={doUpdateHolder}
          messages={{
            label: <FormattedMessage id={I18nFeatureMock.COMPONENTS_CARD_HOLDER_LABEL} />,
            error: <FormattedMessage id={I18nFeatureMock.COMPONENTS_CARD_HOLDER_ERROR} />,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(NewCardInput);
