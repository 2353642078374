import { createSelector } from '@reduxjs/toolkit';

import type { AppRootState } from '@/app/store';
import { makeSelectUser } from '@/features/auth/selectors';
import { makeSelectRequest, makeSelectLocale } from '@/features/global/selectors';
import { NAMESPACE } from '@/features/kyc/types';
import { makeSelectIsMockEnabled, makeSelectMockKYCStatus } from '@/features/mock/selectors';
import { CallbackPageRoutes, callbackRoute } from '@/pages/callback/routes';
import { MockPageRoutes, mockRoute } from '@/pages/mock/routes';
import { mapStoredState, storedDataLoaded } from '@/utils/model';

export const makeSelectKYCData = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE],
    (state) => state.level1,
  );

export const makeSelectKYCURL = () =>
  createSelector(
    makeSelectIsMockEnabled(),
    makeSelectKYCData(),
    makeSelectRequest(),
    makeSelectLocale(),
    (isMock, kyc, init, locale) =>
      isMock
        ? storedDataLoaded(
            `${window.location.origin}${mockRoute(MockPageRoutes.KYC)}?${new URLSearchParams({
              token: init.token || '',
              lang: locale,
              success: `${window.location.origin}${callbackRoute(CallbackPageRoutes.KYC_SUCCESS)}`,
              failure: `${window.location.origin}${callbackRoute(CallbackPageRoutes.KYC_FAILURE)}`,
              mock: 'true',
            }).toString()}`,
          )
        : mapStoredState(
            kyc,
            (data) =>
              `${window.env.MERCURYO_KYC_URL}?${new URLSearchParams({
                access_token: data.kycToken,
                lang: locale,
                success_url: `${window.location.origin}${callbackRoute(CallbackPageRoutes.KYC_SUCCESS)}`,
                failure_url: `${window.location.origin}${callbackRoute(CallbackPageRoutes.KYC_FAILURE)}`,
              }).toString()}`,
          ),
  );

export const makeSelectKYCStatus = () =>
  createSelector(
    makeSelectUser(),
    makeSelectMockKYCStatus(),
    (userState, mockedStatus) => mockedStatus.data || userState.data?.kyc1Status,
  );

export const makeSelectKYCRequiredExplicitly = () => (state: AppRootState) => state[NAMESPACE].explicitlyRequired;

export const makeSelectKYCStatusIsFinal = () =>
  createSelector(makeSelectKYCStatus(), (kycStatus) => kycStatus && ['complete', 'failed'].includes(kycStatus));

export const makeSelectKYCDirtiness = () => createSelector(makeSelectKYCData(), (state) => state.isDirty);
export const makeSelectKYCLimits = () => (state: AppRootState) => state[NAMESPACE].limits;
export const makeSelectKYCLimitsDirtiness = () => (state: AppRootState) => state[NAMESPACE].limits.isDirty;
