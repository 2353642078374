import type { MercuryoCallbackLoaded } from '@/features/intercom/types';

export const NAMESPACE = 'callback';

export interface MercuryoNativeWidgetLoaded {
  type: 'intercom-snippet__ready';
}

export interface MercuryoKYCCallbackResult {
  type: 'kyc';
  data: { success: boolean };
}

export type MercuryoKYCCommand = MercuryoKYCCallbackResult;
export type MercuryoKYCCallback = MercuryoCallbackLoaded | MercuryoNativeWidgetLoaded | MercuryoKYCCommand;

export interface MercuryoCardCallbackResult {
  type: 'payment';
  data: { success: boolean; txId?: string; msg?: string };
}

export type MercuryoCardCommand = MercuryoCardCallbackResult;
export type MercuryoCallbackMessageCard = MercuryoCallbackLoaded | MercuryoNativeWidgetLoaded | MercuryoCardCommand;

export type CallbackStatus = 'processing' | 'error';

export interface CallbackState {
  status: CallbackStatus;
}
