import { isAnyOf, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import type { AddListenerOptions } from '@/app/store';
import type { I18nMessages } from '@/generated/i18n/i18n';

import { storeLoading } from './actions';

import type { AnyAsyncThunk } from '@reduxjs/toolkit/src/matchers';

const locked: (I18nMessages | undefined)[] = [];

export const addGlobalLockListener = (actionToListen: AnyAsyncThunk, messageId?: I18nMessages): AddListenerOptions => ({
  // FIXME: don't know how to help TS to choose appropriate implementation based on matcher
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  matcher: isAnyOf(isPending(actionToListen), isFulfilled(actionToListen), isRejected(actionToListen)),
  effect: async (action, listenerApi) => {
    if (isPending(action)) {
      locked.push(messageId);
      listenerApi.dispatch(storeLoading({ isLoading: true, messageId }));
    } else if (isFulfilled(action) || isRejected(action)) {
      const previousId = locked.pop();
      listenerApi.dispatch(storeLoading({ isLoading: !!locked.length, messageId: previousId }));
    }
  },
});
