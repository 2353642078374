import { Stack } from '@mui/material';
import React from 'react';

import { FormattedMessage, LeftRightPositioning, SecondaryTitle } from '@/components';
import { useRates } from '@/features/purchase/hooks';
import { I18nFeatureBuyPurchase } from '@/generated/i18n/i18n';

import { RatesFee, RatesSubtotal, RatesTotal } from './components';

import type { RatesSummaryProps } from './types';

const RatesSummary: React.FC<RatesSummaryProps> = ({ 'data-test': dataTest, title }) => {
  const { data: rates, loading } = useRates();
  return (
    <Stack direction="column" spacing={2} data-test={dataTest}>
      <SecondaryTitle>{title}</SecondaryTitle>
      <Stack direction="column">
        <LeftRightPositioning
          left={<FormattedMessage id={I18nFeatureBuyPurchase.COMPONENTS_RATES_SUMMARY_LABELS_SUBTOTAL} />}
          right={
            <RatesSubtotal
              rates={rates}
              isLoading={loading || rates.isDirty}
              data-test={dataTest && `${dataTest}-subtotal`}
            />
          }
        />
        <LeftRightPositioning
          left={<FormattedMessage id={I18nFeatureBuyPurchase.COMPONENTS_RATES_SUMMARY_LABELS_FEES} />}
          right={
            <RatesFee rates={rates} isLoading={loading || rates.isDirty} data-test={dataTest && `${dataTest}-fees`} />
          }
        />
      </Stack>
      <LeftRightPositioning
        left={
          <SecondaryTitle>
            <FormattedMessage id={I18nFeatureBuyPurchase.COMPONENTS_RATES_SUMMARY_LABELS_TOTAL} />
          </SecondaryTitle>
        }
        right={
          <RatesTotal rates={rates} isLoading={loading || rates.isDirty} data-test={dataTest && `${dataTest}-total`} />
        }
      />
    </Stack>
  );
};

export default React.memo(RatesSummary);
