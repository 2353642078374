import React from 'react';
import { Route } from 'react-router-dom';

import { FormattedMessage, NotificationMessage } from '@/components';
import { I18nCommon } from '@/generated/i18n/i18n';
import { BlankLayout } from '@/layout';
import { AppPageRoutes } from '@/pages/routes';

import PagesKYCFailure from './kyc-failure';
import PagesKYCSuccess from './kyc-success';
import PagesPaymentFailure from './payment-failure';
import PagesPaymentSuccess from './payment-success';
import { CallbackPageRoutes } from './routes';

export const CallbackDefaultRoute: React.FC = () => (
  <BlankLayout>
    <NotificationMessage
      data-test="callback-unknown"
      severity="error"
      title={<FormattedMessage id={I18nCommon.ERRORS_PAGENOTFOUND_TITLE} />}
    >
      <FormattedMessage id={I18nCommon.ERRORS_PAGENOTFOUND_CONTENT} />
    </NotificationMessage>
  </BlankLayout>
);

export default [
  <Route path={AppPageRoutes.CALLBACK} key={AppPageRoutes.CALLBACK}>
    <Route path={CallbackPageRoutes.PAYMENT_SUCCESS} Component={PagesPaymentSuccess} />
    <Route path={CallbackPageRoutes.PAYMENT_FAILURE} Component={PagesPaymentFailure} />
    <Route path={CallbackPageRoutes.KYC_SUCCESS} Component={PagesKYCSuccess} />
    <Route path={CallbackPageRoutes.KYC_FAILURE} Component={PagesKYCFailure} />
    <Route path="*" Component={CallbackDefaultRoute} />
  </Route>,
];
