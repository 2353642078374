import type { SavedCard } from '@/features/cards/types';
import { B2bUserCards200ResponseDataInnerAPIModel, UserApi } from '@/generated/api/mercuryo/api';
import { mercuryoConfigurationFactory } from '@/infrastructure/security/auth.provider';
import { parseMercuryoCall } from '@/utils/api';
import { notEmpty } from '@/utils/ts';

const userApi = new UserApi(mercuryoConfigurationFactory('auth'));

const parseCard = ({ cardId, cardNumber }: B2bUserCards200ResponseDataInnerAPIModel): SavedCard | null =>
  cardId && cardNumber ? { cardId, maskedNumber: cardNumber } : null;

export const queryCards = async () =>
  ((await parseMercuryoCall(userApi.b2bUserCards())) || []).map(parseCard).filter(notEmpty);
