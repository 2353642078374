/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { B2bUserSetPhone200ResponseDataInnerAPIModel } from './B2bUserSetPhone200ResponseDataInnerAPIModel';
import {
    B2bUserSetPhone200ResponseDataInnerAPIModelFromJSON,
    B2bUserSetPhone200ResponseDataInnerAPIModelFromJSONTyped,
    B2bUserSetPhone200ResponseDataInnerAPIModelToJSON,
} from './B2bUserSetPhone200ResponseDataInnerAPIModel';

/**
 * 
 * @export
 * @interface B2bUserSetPhone200ResponseAPIModel
 */
export interface B2bUserSetPhone200ResponseAPIModel {
    /**
     * Verification info, empty if phone verification is not required
     * @type {Array<B2bUserSetPhone200ResponseDataInnerAPIModel>}
     * @memberof B2bUserSetPhone200ResponseAPIModel
     */
    data?: Array<B2bUserSetPhone200ResponseDataInnerAPIModel>;
    /**
     * HTTP status code
     * @type {number}
     * @memberof B2bUserSetPhone200ResponseAPIModel
     */
    status?: number;
}

/**
 * Check if a given object implements the B2bUserSetPhone200ResponseAPIModel interface.
 */
export function instanceOfB2bUserSetPhone200ResponseAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bUserSetPhone200ResponseAPIModelFromJSON(json: any): B2bUserSetPhone200ResponseAPIModel {
    return B2bUserSetPhone200ResponseAPIModelFromJSONTyped(json, false);
}

export function B2bUserSetPhone200ResponseAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserSetPhone200ResponseAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(B2bUserSetPhone200ResponseDataInnerAPIModelFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function B2bUserSetPhone200ResponseAPIModelToJSON(value?: B2bUserSetPhone200ResponseAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(B2bUserSetPhone200ResponseDataInnerAPIModelToJSON)),
        'status': value.status,
    };
}

