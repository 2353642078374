/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bUserKycShareTokenRequestAPIModel
 */
export interface B2bUserKycShareTokenRequestAPIModel {
    /**
     * Level of kyc
     * @type {number}
     * @memberof B2bUserKycShareTokenRequestAPIModel
     */
    kycLevel?: B2bUserKycShareTokenRequestAPIModelKycLevelEnum;
    /**
     * share_token
     * @type {string}
     * @memberof B2bUserKycShareTokenRequestAPIModel
     */
    shareToken: string;
}


/**
 * @export
 */
export const B2bUserKycShareTokenRequestAPIModelKycLevelEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type B2bUserKycShareTokenRequestAPIModelKycLevelEnum = typeof B2bUserKycShareTokenRequestAPIModelKycLevelEnum[keyof typeof B2bUserKycShareTokenRequestAPIModelKycLevelEnum];


/**
 * Check if a given object implements the B2bUserKycShareTokenRequestAPIModel interface.
 */
export function instanceOfB2bUserKycShareTokenRequestAPIModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shareToken" in value;

    return isInstance;
}

export function B2bUserKycShareTokenRequestAPIModelFromJSON(json: any): B2bUserKycShareTokenRequestAPIModel {
    return B2bUserKycShareTokenRequestAPIModelFromJSONTyped(json, false);
}

export function B2bUserKycShareTokenRequestAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserKycShareTokenRequestAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kycLevel': !exists(json, 'kyc_level') ? undefined : json['kyc_level'],
        'shareToken': json['share_token'],
    };
}

export function B2bUserKycShareTokenRequestAPIModelToJSON(value?: B2bUserKycShareTokenRequestAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kyc_level': value.kycLevel,
        'share_token': value.shareToken,
    };
}

