/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsAPIModel } from './B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsAPIModel';
import {
    B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsAPIModelFromJSON,
    B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsAPIModelFromJSONTyped,
    B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsAPIModelToJSON,
} from './B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsAPIModel';

/**
 * 
 * @export
 * @interface B2bUserIbanLimitsBuy200ResponseDataAPIModel
 */
export interface B2bUserIbanLimitsBuy200ResponseDataAPIModel {
    /**
     * 
     * @type {B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsAPIModel}
     * @memberof B2bUserIbanLimitsBuy200ResponseDataAPIModel
     */
    currenciesLimits?: B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsAPIModel;
}

/**
 * Check if a given object implements the B2bUserIbanLimitsBuy200ResponseDataAPIModel interface.
 */
export function instanceOfB2bUserIbanLimitsBuy200ResponseDataAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bUserIbanLimitsBuy200ResponseDataAPIModelFromJSON(json: any): B2bUserIbanLimitsBuy200ResponseDataAPIModel {
    return B2bUserIbanLimitsBuy200ResponseDataAPIModelFromJSONTyped(json, false);
}

export function B2bUserIbanLimitsBuy200ResponseDataAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserIbanLimitsBuy200ResponseDataAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currenciesLimits': !exists(json, 'currenciesLimits') ? undefined : B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsAPIModelFromJSON(json['currenciesLimits']),
    };
}

export function B2bUserIbanLimitsBuy200ResponseDataAPIModelToJSON(value?: B2bUserIbanLimitsBuy200ResponseDataAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currenciesLimits': B2bUserIbanLimitsBuy200ResponseDataCurrenciesLimitsAPIModelToJSON(value.currenciesLimits),
    };
}

