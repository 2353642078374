import { Divider, useMediaQuery } from '@mui/material';
import BigNumber from 'bignumber.js';
import { debounce } from 'lodash';
import React, { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { FormattedMessage } from '@/components';
import { withAuthorizationInit } from '@/features/auth/hocs';
import { MoneyAmountItem } from '@/features/dictionary/components';
import { makeSelectPending } from '@/features/global/selectors';
import { storeCryptoToBuy } from '@/features/purchase/actions';
import { RatesSummary } from '@/features/purchase/components';
import { withPaymentInit } from '@/features/purchase/hocs';
import { useRates } from '@/features/purchase/hooks';
import { makeSelectPurchase } from '@/features/purchase/selectors';
import { createSavedCardTransaction } from '@/features/transaction/actions';
import { I18nFeatureBuyPurchase } from '@/generated/i18n/i18n';
import { useFirstRenderEffect, useStateMountSafe, useSubmitting } from '@/hooks';
import { MainLayout } from '@/layout';
import type { Value } from '@/utils/model';

import { AddressBlock, CardsBlock, SubmitButton, RatesSummaryTitle } from './components';
import { useSubmitPurchase } from './hooks';

const selectPurchase = makeSelectPurchase();
const selectCreationPending = makeSelectPending(createSavedCardTransaction);

const PurchasePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const purchase = useAppSelector(selectPurchase);
  const isCreating = useAppSelector(selectCreationPending);
  const [updatingAmount, withUpdating] = useSubmitting(false);
  const [amount, setAmount] = useStateMountSafe<Value<BigNumber>>({
    hasError: false,
    value: new BigNumber(purchase.cryptoToBuy.amount),
  });
  const rates = useRates();
  useFirstRenderEffect(() => {
    if (!rates.loading && !rates.data.isDirty && !rates.data.data) {
      rates.forceRefresh();
    }
  });
  const doChangeAmount = useMemo(
    () =>
      withUpdating((value: Value<BigNumber>) => {
        setAmount(value);
        if (value.value && !value.hasError) {
          dispatch(
            storeCryptoToBuy({
              amount: value.value.toString(10),
              currency: purchase.cryptoToBuy.currency,
              network: purchase.cryptoToBuy.network,
            }),
          );
        }
      }),
    [dispatch, purchase.cryptoToBuy.currency, purchase.cryptoToBuy.network, setAmount, withUpdating],
  );
  const debouncedDoChangeAmount = useMemo(() => debounce(doChangeAmount, 500), [doChangeAmount]);
  const { disabled, onSubmit } = useSubmitPurchase(amount.hasError || updatingAmount || isCreating);
  const isLowHeight = useMediaQuery('@media (max-height: 450px)');

  return (
    <MainLayout
      header={{
        title: <FormattedMessage id={I18nFeatureBuyPurchase.PAGE_TITLE} />,
      }}
      buttons={{
        main: {
          title: <SubmitButton data-test="submit" loading={isCreating} />,
          disabled,
          submit: true,
          onAct: onSubmit,
        },
      }}
    >
      <AddressBlock data-test="address" asset={purchase.cryptoToBuy.currency} address={purchase.address} />
      {!isLowHeight && <Divider orientation="horizontal" />}
      <MoneyAmountItem
        data-test="amount"
        currency={purchase.cryptoToBuy.currency}
        value={amount}
        onChange={debouncedDoChangeAmount}
        readonly={updatingAmount || rates.loading || rates.data.isDirty || isCreating}
      />
      <RatesSummary data-test="rates" title={<RatesSummaryTitle data-test="ratesTitle" />} />
      <CardsBlock data-test="cards" />
    </MainLayout>
  );
};

export default withAuthorizationInit(withPaymentInit(PurchasePage));
