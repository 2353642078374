import { createReducer } from '@reduxjs/toolkit';

import { storeFeatureFlags } from '@/features/feature-toggle/actions';
import type { FeatureToggleState } from '@/features/feature-toggle/types';
import { InitStatus } from '@/utils/model';

const initialState: FeatureToggleState = {
  initialized: InitStatus.NOT_INITIALIZED,
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(storeFeatureFlags, (state) => ({
    ...state,
    initialized: InitStatus.FINISHED,
  }));
});

export default reducer;
