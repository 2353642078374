import { Theme, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Space } from '@/components';
import TitleButton, { TitleButtonProp } from '@/layout/components/TitleButton';

import type { CSSObject } from 'tss-react';

const useStyles = makeStyles()((theme: Theme) => {
  const leftTitleButton: CSSObject = {
    height: '26px',
  };
  const rightTitleButton: CSSObject = {
    position: 'absolute',
    right: 0,
    height: '26px',
  };
  return {
    logoBox: {
      flexShrink: 0,
      overflow: 'visible',
      backgroundColor: theme.colors.lightBackground,
      '& > span': {
        flex: '1',
        height: '68px',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    leftTitleButton,
    rightTitleButton,
    headerBox: {
      flexShrink: 0,
      overflow: 'visible',
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.colors.primaryBackground,
      '@media (max-height: 450px)': {
        paddingBottom: theme.spacing(1),
      },
    },
    titleBox: {
      flexGrow: 1,
      alignItems: 'flex-start',
      position: 'relative',
    },
  };
});

export type TitleVariant = 'h1' | 'h2' | 'h3' | 'h4';

export interface HeaderBoxProps {
  'data-test'?: string;
  variant?: TitleVariant;
  title?: ReactNode;
  className?: string;
  logo?: { src: string; alt: string };
  buttons?: {
    leftTitle?: TitleButtonProp;
    rightTitle?: TitleButtonProp;
  };
  extra?: ReactNode;
}

export const HeaderBox: React.FC<HeaderBoxProps> = ({
  variant = 'h1',
  title,
  logo,
  buttons,
  className,
  extra,
  'data-test': dataTest,
}) => {
  const { cx, classes } = useStyles();
  return (
    <>
      {logo && (
        <Space className={classes.logoBox}>
          <Space>
            <img alt={logo.alt} src={logo.src} data-test={dataTest && `${dataTest}-logo`} />
          </Space>
        </Space>
      )}
      <Space direction="vertical" className={cx(classes.headerBox, className)}>
        <Space
          className={classes.titleBox}
          size={2}
          style={{ justifyContent: buttons?.leftTitle ? 'inherit' : 'center' }}
        >
          {buttons?.leftTitle && (
            <TitleButton
              data-test={dataTest && `${dataTest}-titleButton`}
              type={buttons.leftTitle}
              className={classes.leftTitleButton}
            />
          )}
          {title ? (
            <Typography
              component="span"
              className={className}
              style={{ flexGrow: 1 }}
              variant={variant}
              data-test={dataTest && `${dataTest}-title`}
            >
              {title}
            </Typography>
          ) : (
            <>&nbsp;</>
          )}
          {buttons?.rightTitle && (
            <TitleButton
              data-test={dataTest && `${dataTest}-rightButton`}
              type={buttons.rightTitle}
              className={classes.rightTitleButton}
            />
          )}
        </Space>
        {extra}
      </Space>
    </>
  );
};

export default React.memo(HeaderBox);
