/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Crypto currency info
 * @export
 * @interface PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel
 */
export interface PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel {
    /**
     * Currency name
     * @type {string}
     * @memberof PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel
     */
    currency?: string;
    /**
     * Currency network
     * @type {string}
     * @memberof PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel
     */
    network?: string;
    /**
     * Label of network
     * @type {string}
     * @memberof PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel
     */
    networkLabel?: string;
    /**
     * Network unstoppable domain name
     * @type {string}
     * @memberof PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel
     */
    networkUd?: string;
    /**
     * Need to show network name (for internal use)
     * @type {boolean}
     * @memberof PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel
     */
    showNetworkIcon?: boolean;
}

/**
 * Check if a given object implements the PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel interface.
 */
export function instanceOfPublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModelFromJSON(json: any): PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel {
    return PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModelFromJSONTyped(json, false);
}

export function PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'network': !exists(json, 'network') ? undefined : json['network'],
        'networkLabel': !exists(json, 'network_label') ? undefined : json['network_label'],
        'networkUd': !exists(json, 'network_ud') ? undefined : json['network_ud'],
        'showNetworkIcon': !exists(json, 'show_network_icon') ? undefined : json['show_network_icon'],
    };
}

export function PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModelToJSON(value?: PublicCurrencies200ResponseDataConfigCryptoCurrenciesInnerAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'network': value.network,
        'network_label': value.networkLabel,
        'network_ud': value.networkUd,
        'show_network_icon': value.showNetworkIcon,
    };
}

