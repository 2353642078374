/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bFiatBuyCardRequestAPIModel
 */
export interface B2bFiatBuyCardRequestAPIModel {
    /**
     * The address to which the crypto should be transferred
     * @type {string}
     * @memberof B2bFiatBuyCardRequestAPIModel
     */
    address: string;
    /**
     * CVV<p>Example: `123`</p>
     * @type {string}
     * @memberof B2bFiatBuyCardRequestAPIModel
     */
    cardCvv: string;
    /**
     * Card expiration month<p>Example: `11`</p><p>Required if card_id not filled</p>
     * @type {string}
     * @memberof B2bFiatBuyCardRequestAPIModel
     */
    cardExpirationMonth?: string;
    /**
     * Card expiration year<p>Example: `2026`</p><p>Required if card_id not filled</p>
     * @type {string}
     * @memberof B2bFiatBuyCardRequestAPIModel
     */
    cardExpirationYear?: string;
    /**
     * Cardholder name<p>Example: `John Doe`</p><p>Required if card_id not filled</p>
     * @type {string}
     * @memberof B2bFiatBuyCardRequestAPIModel
     */
    cardHolderName?: string;
    /**
     * Card ID<p>Example: `1337`</p><p>Required if card data not filled</p>
     * @type {string}
     * @memberof B2bFiatBuyCardRequestAPIModel
     */
    cardId?: string;
    /**
     * Card number<p>Example: `1234567890123456`</p><p>Required if card_id not filled</p>
     * @type {string}
     * @memberof B2bFiatBuyCardRequestAPIModel
     */
    cardNumber?: string;
    /**
     * Unique custom ID to check transaction status. If empty, it will be generated
     * @type {string}
     * @memberof B2bFiatBuyCardRequestAPIModel
     */
    merchantTransactionId?: string;
    /**
     * Customer's Taxpayer Identification Number <p>Required for Brazil users.</p><p>Example: `123.456.789-10`</p>
     * @type {string}
     * @memberof B2bFiatBuyCardRequestAPIModel
     */
    tin?: string;
    /**
     * Transaction token returned by [`/b2b/fiat/buy-rates`](#operation/b2bBuyRates) endpoint
     * @type {string}
     * @memberof B2bFiatBuyCardRequestAPIModel
     */
    trxToken: string;
}

/**
 * Check if a given object implements the B2bFiatBuyCardRequestAPIModel interface.
 */
export function instanceOfB2bFiatBuyCardRequestAPIModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "cardCvv" in value;
    isInstance = isInstance && "trxToken" in value;

    return isInstance;
}

export function B2bFiatBuyCardRequestAPIModelFromJSON(json: any): B2bFiatBuyCardRequestAPIModel {
    return B2bFiatBuyCardRequestAPIModelFromJSONTyped(json, false);
}

export function B2bFiatBuyCardRequestAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bFiatBuyCardRequestAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'cardCvv': json['card_cvv'],
        'cardExpirationMonth': !exists(json, 'card_expiration_month') ? undefined : json['card_expiration_month'],
        'cardExpirationYear': !exists(json, 'card_expiration_year') ? undefined : json['card_expiration_year'],
        'cardHolderName': !exists(json, 'card_holder_name') ? undefined : json['card_holder_name'],
        'cardId': !exists(json, 'card_id') ? undefined : json['card_id'],
        'cardNumber': !exists(json, 'card_number') ? undefined : json['card_number'],
        'merchantTransactionId': !exists(json, 'merchant_transaction_id') ? undefined : json['merchant_transaction_id'],
        'tin': !exists(json, 'tin') ? undefined : json['tin'],
        'trxToken': json['trx_token'],
    };
}

export function B2bFiatBuyCardRequestAPIModelToJSON(value?: B2bFiatBuyCardRequestAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'card_cvv': value.cardCvv,
        'card_expiration_month': value.cardExpirationMonth,
        'card_expiration_year': value.cardExpirationYear,
        'card_holder_name': value.cardHolderName,
        'card_id': value.cardId,
        'card_number': value.cardNumber,
        'merchant_transaction_id': value.merchantTransactionId,
        'tin': value.tin,
        'trx_token': value.trxToken,
    };
}

