import { createAction } from '@reduxjs/toolkit';

import { createAppAsyncThunk } from '@/app/actions';
import { sendMessageToParent } from '@/utils/messaging';

import { MercuryoCallback, MercuryoCommand, NAMESPACE } from './types';

export const sendCallback = createAppAsyncThunk(`${NAMESPACE}/sendCallback`, async (message: MercuryoCallback) =>
  sendMessageToParent(message),
);

export const receiveCommand = createAction<MercuryoCommand>(`${NAMESPACE}/receiveCommand`);
