import BigNumber from 'bignumber.js';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

export interface UseLocaleSettingsType {
  decimalSeparator: string;
  groupSeparator: string;
  formatDateTime: (value?: Parameters<Intl.DateTimeFormat['format']>[0] | string) => string;
  formatBigNumber: (fraction: number, value?: BigNumber) => string;
}

export default function useLocaleSettings(): UseLocaleSettingsType {
  const { formatNumberToParts, formatDate, formatTime } = useIntl();
  const decimalSeparator = useMemo(
    () => formatNumberToParts(1.1).find(({ type }: Intl.NumberFormatPart) => type === 'decimal')?.value || '.',
    [formatNumberToParts],
  );
  const groupSeparator = useMemo(
    () => formatNumberToParts(1111).find(({ type }: Intl.NumberFormatPart) => type === 'group')?.value || ' ',
    [formatNumberToParts],
  );
  const formatDateTime = useCallback(
    (value: Parameters<Intl.DateTimeFormat['format']>[0] | string) =>
      !value
        ? ''
        : `${formatDate(value)} ${formatTime(value, {
            second: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}`,
    [formatDate, formatTime],
  );
  const formatBigNumber = useCallback(
    (fraction: number, value: BigNumber = new BigNumber(0)) =>
      value.toFormat(fraction, { decimalSeparator, groupSeparator }),
    [decimalSeparator, groupSeparator],
  );

  return {
    decimalSeparator,
    groupSeparator,
    formatDateTime,
    formatBigNumber,
  };
}
