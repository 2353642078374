import { createAction } from '@reduxjs/toolkit';

import { createAppAsyncThunk } from '@/app/actions';
import { makeSelectRequest } from '@/features/global/selectors';
import { sendCallback } from '@/features/intercom/actions';
import { saveToken } from '@/infrastructure/security/token.holder';
import { ymInitUser } from '@/infrastructure/ym';
import { withAPICall } from '@/utils/api';
import type { CommonLoadingState } from '@/utils/model';

import { queryUser } from './api';
import { makeSelectUser } from './selectors';
import { AuthTokenStatus, NAMESPACE, User } from './types';

export const storeToken = createAction<
  | { token: string; user: User; status: AuthTokenStatus.AUTHORIZED }
  | { status: Exclude<AuthTokenStatus, AuthTokenStatus.AUTHORIZED> }
>(`${NAMESPACE}/storeToken`);

export const storeUser = createAction<CommonLoadingState<User>>(`${NAMESPACE}/storeUser`);
export const fetchUser = createAppAsyncThunk(
  `${NAMESPACE}/fetchUser`,
  async ({ force }: { force?: boolean }, { dispatch, getState }) => {
    const oldUser = makeSelectUser()(getState());
    if (!force && !oldUser.isDirty) {
      return;
    }
    const user = await withAPICall(queryUser)();
    ymInitUser(user.data?.email);
    dispatch(storeUser(user));
  },
);

export const initAuth = createAppAsyncThunk(`${NAMESPACE}/initialize`, async (_, { dispatch, getState }) => {
  const initState = makeSelectRequest()(getState());
  try {
    if (!initState.token) {
      throw new Error('Token is undefined');
    }
    // TODO: duplicates reducer code, reason: token is required to query user, but user is required to bypass auth
    saveToken(initState.token);
    const user = await queryUser();
    if (!user) {
      throw new Error('User is not found');
    }
    dispatch(
      storeToken({
        token: initState.token,
        user,
        status: AuthTokenStatus.AUTHORIZED,
      }),
    );
  } catch (e) {
    console.error('Unable to init auth', e);
    await dispatch(sendCallback({ type: 'payment-failed', data: { retry: true } }));
    dispatch(storeToken({ status: AuthTokenStatus.FORBIDDEN }));
  }
});
