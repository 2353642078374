import React from 'react';
import { useSelector } from 'react-redux';

import { GlobalBackdrop } from '@/features/global/components';
import { makeSelectGlobalLoading } from '@/features/global/selectors';

const selectLoadingState = makeSelectGlobalLoading();

const withGlobalBackdropSupport =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props) => {
    const { isLoading, messageId } = useSelector(selectLoadingState);

    return (
      <>
        <Component {...props} />
        <GlobalBackdrop isLoading={isLoading} messageId={messageId} />
      </>
    );
  };

export default withGlobalBackdropSupport;
