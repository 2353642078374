import { CircularProgress } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { DataFetchError, FormattedMessage, IFrameErrorBanner, ProgressBox } from '@/components';
import type { MercuryoKYCCallback } from '@/features/callback/types';
import { createNotification } from '@/features/global/actions';
import { useKYCDataURL } from '@/features/kyc/hooks';
import { makeSelectIsMockEnabled } from '@/features/mock/selectors';
import { I18nFeatureBuyKyc } from '@/generated/i18n/i18n';
import { useIFrameLoading, useSubmitting } from '@/hooks';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import type { KYCWidgetProps } from './types';

const selectMockEnabled = makeSelectIsMockEnabled();

const KYCWidget: React.FC<KYCWidgetProps> = ({ 'data-test': dataTest }) => {
  const isMockEnabled = useAppSelector(selectMockEnabled);
  const { data: url, forceRefresh } = useKYCDataURL();
  const origin = useMemo(() => (url.data ? new URL(url.data).origin : undefined), [url.data]);
  const [refreshing, withRefreshing] = useSubmitting(false);
  const { state, withMarkLoading, markLoaded } = useIFrameLoading();
  const fullRefresh = useMemo(
    () => withRefreshing(withMarkLoading(forceRefresh)),
    [forceRefresh, withMarkLoading, withRefreshing],
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const listener = async (event: MessageEvent) => {
      if (!origin || !event.origin.includes(origin)) {
        return;
      }

      const e: MercuryoKYCCallback = event.data;
      if (e.type === 'loaded' || e.type === 'intercom-snippet__ready') {
        markLoaded();
      } else if (e.type === 'kyc') {
        goalReached(e.data.success ? YMGoals.KYC_SUCCESS : YMGoals.KYC_FAILURE);
        if (!e.data.success) {
          await dispatch(
            createNotification({
              titleId: I18nFeatureBuyKyc.MESSAGES_FAILED_TITLE,
              messageId: I18nFeatureBuyKyc.MESSAGES_FAILED_DESCRIPTION,
              severity: 'error',
            }),
          );
          await fullRefresh();
        } else {
          await dispatch(
            createNotification({
              titleId: I18nFeatureBuyKyc.MESSAGES_SUCCESS_TITLE,
              messageId: I18nFeatureBuyKyc.MESSAGES_SUCCESS_DESCRIPTION,
              severity: 'success',
            }),
          );
          await fullRefresh();
        }
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, [dispatch, fullRefresh, navigate, origin, markLoaded]);
  const kycGoalTriggered = useRef(false);
  useEffect(() => {
    if (url.data && !kycGoalTriggered.current) {
      kycGoalTriggered.current = true;
      goalReached(YMGoals.KYC_STARTED);
    }
  }, [url.data]);
  if (!url.data) {
    return url.error ? (
      <DataFetchError refresh={forceRefresh} data-test={dataTest} />
    ) : (
      <CircularProgress color="inherit" size={25} style={{ alignSelf: 'center' }} />
    );
  }
  return (
    <>
      {!refreshing && (
        <iframe
          title="KYC"
          src={url.data}
          referrerPolicy={!isMockEnabled ? 'no-referrer' : undefined}
          data-test={dataTest}
          style={{ height: '100%', border: 0 }}
          allow="camera"
          hidden={state !== 'loaded'}
        />
      )}
      {state === 'loading' && (
        <ProgressBox title={<FormattedMessage id={I18nFeatureBuyKyc.COMPONENTS_WIDGET_LOADING} />} />
      )}
      {state === 'timeout' && <IFrameErrorBanner data-test={dataTest} refresh={fullRefresh} />}
    </>
  );
};

export default React.memo(KYCWidget);
