import { createAction } from '@reduxjs/toolkit';

import { createAppAsyncThunk } from '@/app/actions';

import { queryCurrencies } from './api';
import { makeSelectCurrencies } from './selectors';
import { NAMESPACE } from './types';

export const markCurrenciesDirty = createAction(`${NAMESPACE}/markCurrenciesDirty`);

export const fetchCurrencies = createAppAsyncThunk(
  `${NAMESPACE}/fetchCurrencies`,
  async ({ force }: { force?: boolean }, { abort, getState }) => {
    const { isDirty } = makeSelectCurrencies()(getState());
    if (!isDirty && !force) {
      abort('cached');
      throw new Error(); // to make ts happier
    }
    return queryCurrencies();
  },
);
