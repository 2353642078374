import { Stack } from '@mui/material';
import React, { useCallback } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { SecondaryButton } from '@/components';
import { useKYCStatus } from '@/features/kyc/hooks';
import { proceedMockKYC } from '@/features/mock/actions';
import { MercuryoKYCStatusAPIModel } from '@/generated/api/ncps/api';

import KYCStatusView from '../KYCStatusView';

import type { KYCStatusSelectProps } from './types';


const KYCStatusSelect: React.FC<KYCStatusSelectProps> = ({ 'data-test': dataTest }) => {
  const dispatch = useAppDispatch();
  const { data: status } = useKYCStatus();
  const doSelect = useCallback(
    (newStatus: MercuryoKYCStatusAPIModel) => dispatch(proceedMockKYC({ status: newStatus })),
    [dispatch],
  );
  return (
    <Stack spacing={0.6} direction="column">
      {Object.values(MercuryoKYCStatusAPIModel).map((st) => (
        <SecondaryButton
          key={st}
          data-test={`${dataTest}-${st}`}
          onClick={() => doSelect(st)}
          disabled={status === st}
          title={<KYCStatusView value={st} />}
        />
      ))}
    </Stack>
  );
};

export default React.memo(KYCStatusSelect);
