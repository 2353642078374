import { useCallback, useEffect, useRef } from 'react';

const useIsMounted = (): (() => boolean) => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return useCallback((): boolean => isMounted.current, []);
};
export default useIsMounted;
