import { createReducer } from '@reduxjs/toolkit';

import { storeToken, storeUser } from '@/features/auth/actions';
import type { AuthState } from '@/features/auth/types';
import { AuthTokenStatus, User } from '@/features/auth/types';
import { saveToken } from '@/infrastructure/security/token.holder';
import { InitStatus, storedDirtyData } from '@/utils/model';

const initialState: AuthState = {
  tokenStatus: AuthTokenStatus.EMPTY,
  initStatus: InitStatus.NOT_INITIALIZED,
  token: '',
  user: storedDirtyData,
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storeToken, (state, { payload }) => {
      // it's bad to call a side effect in reducer, but there is no better place for this
      saveToken((payload as { token: string }).token);
      return {
        ...state,
        user: (payload as { user: User }).user
          ? { data: (payload as { user: User }).user, isDirty: false }
          : state.user,
        token: (payload as { token: string }).token || state.token,
        tokenStatus: payload.status,
        initStatus: InitStatus.FINISHED,
      };
    })
    .addCase(storeUser, (state, { payload: user }) => ({ ...state, user: { ...user, isDirty: false } }));
});

export default reducer;
