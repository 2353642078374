import React, { ReactNode, useState } from 'react';
import DetectableOverflow from 'react-detectable-overflow';

import Tooltip from '@/components/output/Tooltip';

interface TruncatedTextProps {
  children?: ReactNode;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({ children }) => {
  const [isOverflowed, setOverflowed] = useState(false);
  return isOverflowed ? (
    <Tooltip placement="top-start" title={children}>
      <span>
        <DetectableOverflow onChange={setOverflowed}>{children}</DetectableOverflow>
      </span>
    </Tooltip>
  ) : (
    <DetectableOverflow onChange={setOverflowed}>{children}</DetectableOverflow>
  );
};

export default React.memo(TruncatedText);
