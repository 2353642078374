import { Alert as MuiAlert, AlertProps, AlertTitle, Stack, Theme } from '@mui/material';
import React, { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { FormattedMessage } from '@/components';
import { removeNotificationFromStore } from '@/features/global/actions';
import { makeSelectNotifications } from '@/features/global/selectors';
import type { GlobalNotificationWithMessage, GlobalNotificationWithMessageId } from '@/features/global/types';
import { GlobalIdentifiedNotification } from '@/features/global/types';

const Alert: React.FC<AlertProps> = (props: AlertProps) => <MuiAlert elevation={6} {...props} />;

const useStyles = makeStyles()((theme: Theme) => ({
  toastBox: {
    position: 'absolute',
    zIndex: 1000,
    paddingTop: theme.spacing(2.5),
    padding: theme.spacing(2),
  },
}));

const selectNotifications = makeSelectNotifications();

const Notifications: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectNotifications);
  const removeNotificationFactory = useCallback(
    (id: GlobalIdentifiedNotification['id']) => async () => {
      await dispatch(removeNotificationFromStore(id));
    },
    [dispatch],
  );

  return notifications.length > 0 ? (
    <Stack className={classes.toastBox} direction="row">
      {notifications.map((notification) => (
        <Alert
          data-test={`notification-message-${
            (notification as GlobalNotificationWithMessageId).messageId
            || (notification as GlobalNotificationWithMessage).message
          }`}
          key={notification.id}
          severity={notification.severity}
          onClose={removeNotificationFactory(notification.id)}
        >
          {notification.titleId && (
            <AlertTitle>
              <FormattedMessage id={notification.titleId} />
            </AlertTitle>
          )}
          {(notification as GlobalNotificationWithMessage).message || (
            <FormattedMessage id={(notification as GlobalNotificationWithMessageId).messageId} />
          )}
        </Alert>
      ))}
    </Stack>
  ) : null;
};

export default React.memo(Notifications);
