import { Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';

interface SecondaryTitleProps extends PropsWithChildren {
  'data-test'?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const SecondaryTitle: React.FC<SecondaryTitleProps> = ({
  className,
  children,
  style,
  'data-test': dataTest,
}) => (
  <Typography component="span" className={className} style={style} variant="h2" data-test={dataTest}>
    {children}
  </Typography>
);

export default React.memo(SecondaryTitle);
