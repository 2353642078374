import React from 'react';
import { useSelector } from 'react-redux';

import { FormattedMessage } from '@/components';
import { makeSelectMockInit } from '@/features/mock/selectors';
import { I18nFeatureMock } from '@/generated/i18n/i18n';
import { MessageLayout } from '@/layout';

const selectInitialization = makeSelectMockInit();

const withMockTxData =
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props: P) => {
    const initState = useSelector(selectInitialization);
    return initState.value?.tx ? (
      <Component {...props} />
    ) : (
      <MessageLayout
        message={<FormattedMessage id={I18nFeatureMock.ERROR_TITLE} />}
        description={<FormattedMessage id={I18nFeatureMock.ERROR_DESCRIPTION} />}
      />
    );
  };

export default withMockTxData;
