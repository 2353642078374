import type { LoadingStateWithDirty } from '@/utils/model';

export const NAMESPACE = 'transaction';

export interface RedirectCardData {
  url: string;
}

export interface TransactionState {
  redirectCard: LoadingStateWithDirty<RedirectCardData>;
}
