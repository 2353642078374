import { Link } from '@mui/material';
import React from 'react';

import type { SupportEmailProps } from './types';

const SupportEmail: React.FC<SupportEmailProps> = ({ 'data-test': dataTest }) => (
  <Link href={`mailto:${window.env.SUPPORT_EMAIL}`} data-test={dataTest}>
    {window.env.SUPPORT_EMAIL}
  </Link>
);

export default React.memo(SupportEmail);
