import { CircularProgress } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { useFirstRenderEffect, useStateMountSafe } from '@/hooks';

const waitForYM = (repeats: number) =>
  new Promise((resolve) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (repeats <= 0 || window.ym) {
      resolve('');
    } else {
      setTimeout(async () => {
        await waitForYM(repeats - 1);
        resolve('');
      }, 100);
    }
  });

let scriptCreated = false;
let scriptLoaded = false;

const withYandexMetrika =
  <P extends PropsWithChildren>(Component: React.FC<P>): React.FC<P> =>
  (props: P) => {
    const ymIsRequired = window.env.YM_ENABLED === 'true' && !!window.env.YM_ID;
    const [loaded, setLoaded] = useStateMountSafe(!ymIsRequired || scriptLoaded);
    useFirstRenderEffect(() => {
      if (!loaded && !scriptCreated) {
        scriptCreated = true;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `(function(m, e, t, r, i, k, a) {
          m[i] = m[i] || function() {
            (m[i].a = m[i].a || []).push(arguments);
          };
          m[i].l = 1 * new Date();
          k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
        })
        (window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
        
        ym(${window.env.YM_ID}, 'init', {
          clickmap: false,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: false,
          trackHash: true,
        });`;
        document.head.append(script);
        (async () => {
          await waitForYM(10);
          scriptLoaded = true;
          setLoaded(true);
        })();
      }
    });
    return loaded ? <Component {...props} /> : <CircularProgress color="inherit" size={16} />;
  };

export default withYandexMetrika;
