import React, { ReactNode } from 'react';

import Space from '@/components/layout/Space';

interface HeaderTitleProps {
  'data-test'?: string;
  title?: ReactNode;
  postfix?: ReactNode;
}

const HeaderTitle: React.FC<HeaderTitleProps> = ({ title, postfix, 'data-test': dataTest }) => (
  <Space
    size={2}
    style={{
      flexGrow: 1,
      alignItems: 'center',
    }}
  >
    <Space size={0} style={{ flexGrow: 1 }} data-test={dataTest && `${dataTest}-title`}>
      {title || <>&nbsp;</>}
    </Space>
    {postfix && (
      <Space style={{ justifySelf: 'flex-end' }} size={0} data-test={dataTest && `${dataTest}-postfix`}>
        {postfix}
      </Space>
    )}
  </Space>
);

export default React.memo(HeaderTitle);
