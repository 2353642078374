import { createReducer } from '@reduxjs/toolkit';

import { fetchCurrencies, markCurrenciesDirty } from '@/features/dictionary/actions';
import type { DictionaryState } from '@/features/dictionary/types';
import { storedDirtyData } from '@/utils/model';
import { withIgnoreAbortion } from '@/utils/redux';

const initialState: DictionaryState = {
  currencies: storedDirtyData,
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markCurrenciesDirty, (state) =>
      !state.currencies.isDirty ? { ...state, currencies: { ...state.currencies, isDirty: false } } : state,
    )
    .addCase(fetchCurrencies.fulfilled, (state, action) => ({
      ...state,
      currencies: { data: action.payload, isDirty: false },
    }))
    .addCase(
      fetchCurrencies.rejected,
      withIgnoreAbortion((state, action) => ({
        ...state,
        currencies: { error: action.error?.code || `${action.error}`, isDirty: false },
      })),
    );
});

export default reducer;
