import React from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { withStore } from '@/app/hocs';
import { withFeatureToggle } from '@/features/feature-toggle/hocs';
import {
  withGlobalBackdropSupport,
  withGlobalInitialization,
  withI18nSupport,
  withMobileDetection,
} from '@/features/global/hocs';
import { withTheme, withErrorBoundary, withI18n } from '@/hocs';
import { withSentryInitialization } from '@/infrastructure/sentry';
import { withYandexMetrika } from '@/infrastructure/ym/hocs';
import { pagesBuy, pagesCallback, pagesMock } from '@/pages';
import { AppPageRoutes, appRoute } from '@/pages/routes';

const DefaultRoute: React.FC = () => {
  const location = useLocation();
  console.warn(`URL not found: ${location?.pathname}`);
  return <Navigate to={appRoute(AppPageRoutes.BUY)} />;
};

const App: React.FC = () => (
  <BrowserRouter basename={window.env.PUBLIC_URL}>
    <Routes>
      {pagesBuy}
      {pagesMock}
      {pagesCallback}
      <Route path="*" Component={DefaultRoute} />
    </Routes>
  </BrowserRouter>
);

// prettier-ignore
export default withSentryInitialization(
  withYandexMetrika(
    withStore(
      withI18nSupport(
        withI18n(
          withTheme(
            withGlobalBackdropSupport(
              withGlobalInitialization(
                withErrorBoundary(
                  withMobileDetection(
                    withFeatureToggle(
                      App,
                    ),
                  ),
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  ),
);
