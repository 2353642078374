export type Func<T extends unknown[] = [], R = unknown> = (...args: T) => Promise<R> | R;
export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];
export type DeepPartial<T> = T extends object ? { [P in keyof T]?: DeepPartial<T[P]> } : T;

/* ---------- Typesafe checkers --------- */
export function nameof<T>(key: keyof T): keyof T {
  return key;
}

export function extractKey<T, K extends Extract<keyof T, string>>(o: T, k: K): K {
  return k;
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function asType<T>(t: T): T {
  return t;
}

// do not check the return type, we can only "trust" the caller
export function isThenable<T>(input: unknown): input is Promise<T> {
  return !!input && typeof (input as Promise<T>).then === 'function';
}
