/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface B2bUserSendKycDocumentsRequestDocumentInnerAPIModel
 */
export interface B2bUserSendKycDocumentsRequestDocumentInnerAPIModel {
    /**
     * User's document files.\n     *                              document.files must contain an array with filename as key and file content as value.\n     *                              For current document type requirements for list of files differs.<br><br>\n     *                              Extensions in file names must correspond .jpg, jpeg, .png. or .pdf <br><br>\n     *                              Required docs:<br>\n     *                              for type = id_card. Count of files must be 2.\n     *                                                  File names must be 'side-1.', 'side-2.';<br>\n     *                              for type = passport. Count of files must be 1\n     *                                                  File names must be 'side-1.';<br>\n     *                              for type = driver_license. Count of files must be 2.\n     *                                                  File names must be 'side-1.', 'side-2.'.<br>\n     *                              for type = residence_permit. Count of files must be 2.\n     *                                                  File names must be 'side-1.', 'side-2.'.<br>\n     *                              for type = proof_of_address. Count of files must be 1.\n     *                                                  File names must be 'side-1.'.<br>
     * @type {Array<string>}
     * @memberof B2bUserSendKycDocumentsRequestDocumentInnerAPIModel
     */
    files?: Array<string>;
    /**
     * User's document type.<br>\n     *                                      For kyc1: id_card,driver_license,passport.<br>\n     *                                      For kyc1 from Russia: id_card or driver_license or passport\n     *                                          and residence_permit or proof_of_address.<br>\n     *                                      For kyc2: residence_permit,proof_of_address<br>
     * @type {string}
     * @memberof B2bUserSendKycDocumentsRequestDocumentInnerAPIModel
     */
    type?: B2bUserSendKycDocumentsRequestDocumentInnerAPIModelTypeEnum;
}


/**
 * @export
 */
export const B2bUserSendKycDocumentsRequestDocumentInnerAPIModelTypeEnum = {
    id_card: 'id_card',
    driver_license: 'driver_license',
    passport: 'passport',
    residence_permit: 'residence_permit',
    proof_of_address: 'proof_of_address'
} as const;
export type B2bUserSendKycDocumentsRequestDocumentInnerAPIModelTypeEnum = typeof B2bUserSendKycDocumentsRequestDocumentInnerAPIModelTypeEnum[keyof typeof B2bUserSendKycDocumentsRequestDocumentInnerAPIModelTypeEnum];


/**
 * Check if a given object implements the B2bUserSendKycDocumentsRequestDocumentInnerAPIModel interface.
 */
export function instanceOfB2bUserSendKycDocumentsRequestDocumentInnerAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function B2bUserSendKycDocumentsRequestDocumentInnerAPIModelFromJSON(json: any): B2bUserSendKycDocumentsRequestDocumentInnerAPIModel {
    return B2bUserSendKycDocumentsRequestDocumentInnerAPIModelFromJSONTyped(json, false);
}

export function B2bUserSendKycDocumentsRequestDocumentInnerAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): B2bUserSendKycDocumentsRequestDocumentInnerAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'files': !exists(json, 'files') ? undefined : json['files'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function B2bUserSendKycDocumentsRequestDocumentInnerAPIModelToJSON(value?: B2bUserSendKycDocumentsRequestDocumentInnerAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'files': value.files,
        'type': value.type,
    };
}

