import BigNumber from 'bignumber.js';
import pLimit from 'p-limit';

import type { Crypto } from '@/features/purchase/types';
import { B2bBuyRatesPaymentAliasEnum, BuyApi, UserApi } from '@/generated/api/mercuryo/api';
import { mercuryoConfigurationFactory, ResponseError } from '@/infrastructure/security/auth.provider';
import { parseMercuryoCall } from '@/utils/api';
import { asType } from '@/utils/ts';

const buyApi = new BuyApi(mercuryoConfigurationFactory('auth'));
const userApi = new UserApi(mercuryoConfigurationFactory('auth'));

const ratesLimit = pLimit(1);

export const queryRates = async (fiat: string, crypto: Crypto) =>
  ratesLimit(async () =>
    parseMercuryoCall(
      buyApi.b2bOorBuyRates(
        fiat,
        crypto.currency,
        undefined,
        crypto.amount,
        crypto.network,
        B2bBuyRatesPaymentAliasEnum.card,
      ),
    ),
  );

export const queryMinAmount = async (fiat: string, crypto: Crypto) => {
  try {
    await queryRates(fiat, crypto);
  } catch (e: unknown) {
    if (e instanceof ResponseError) {
      const minValueStr = e.data.data?.data?.[crypto.currency]?.min;
      if (minValueStr) {
        const minValue = new BigNumber(minValueStr);
        return asType<Crypto>({
          currency: crypto.currency,
          amount: minValue.toString(),
          network: crypto.network,
        });
      }
    }
    throw e;
  }
  return crypto;
};

export const queryPurchaseLimits = async (fiat: string, crypto: string) =>
  parseMercuryoCall(userApi.b2bUserCardLimits(fiat, crypto, 'buy'));
