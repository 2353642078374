import { useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import useKYCData from '@/features/kyc/hooks/useKYCData';
import { makeSelectKYCURL } from '@/features/kyc/selectors';

const selector = makeSelectKYCURL();

const useKYCDataURL = () => {
  const { forceRefresh, loading } = useKYCData();
  const url = useAppSelector(selector);
  return useMemo(() => ({ forceRefresh, loading, data: url }), [forceRefresh, loading, url]);
};

export default useKYCDataURL;
