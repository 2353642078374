import {
  Configuration as MercuryoConfiguration,
  ConfigurationParameters as MercuryoConfigurationParameters,
  Middleware,
  ResponseContext,
} from '@/generated/api/mercuryo/api';
import {
  Configuration as NCPSConfiguration,
  ConfigurationParameters as NCPSConfigurationParameters,
} from '@/generated/api/ncps/api';

import { getToken } from './token.holder';

export interface ResponseErrorData {
  headers: Headers;
  ok: boolean;
  status: number;
  statusText: string;
  // if json
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export class ResponseError extends Error {
  private _data;

  constructor(data: ResponseErrorData) {
    super('response error');
    this._data = data;
  }

  get data() {
    return this._data;
  }
}

export const parseErrorResponse = async (error: Response): Promise<ResponseError> => {
  let data;
  try {
    data = await error.json();
  } catch (e) {
    try {
      console.warn(`unable to parse error response ${e}`);
    } catch (e2) {
      console.warn(`unable to parse error response ${e?.toString()}`);
    }
  }
  return new ResponseError({
    data,
    headers: new Headers(error.headers),
    ok: error?.ok,
    status: error?.status,
    statusText: error?.statusText,
  });
};

export const parseErrorMiddleware: Middleware = {
  async post(context: ResponseContext): Promise<Response | void> {
    if (!context.response.ok) {
      throw await parseErrorResponse(context.response);
    }
    return context.response;
  },
};

export const reloginMiddleware: Middleware = parseErrorMiddleware;

export const typedConfigurationFactory = <
  P = MercuryoConfigurationParameters | NCPSConfigurationParameters,
  C = MercuryoConfiguration | NCPSConfiguration,
>(
  basePath: string,
  Configuration: new (v: P) => C,
  params?: P,
  auth?: boolean,
): C =>
  new Configuration({
    ...params,
    middleware: [...(auth ? [reloginMiddleware] : []), parseErrorMiddleware],
    basePath,
    credentials: 'omit', // To not disclose our cookies to 3dparty like mercuryo
  } as P);

type ConfigurationType = 'auth' | 'public' | 'no-forbidden';

const mercuryoAPIPath = window.env.MERCURYO_API_URL.replace(/\/$/, '');
const mockAPIPath = window.env.MOCK_API_URL.replace(/\/$/, '');

export const apiBasePath = `${mercuryoAPIPath}`;

export const mercuryoConfigurationFactory = (
  type: ConfigurationType,
  params?: MercuryoConfigurationParameters,
): MercuryoConfiguration =>
  typedConfigurationFactory(
    apiBasePath,
    MercuryoConfiguration,
    type === 'public' ? params : { ...params, apiKey: () => getToken() || '' },
    type === 'auth',
  );

export const ncpsConfigurationFactory = (
  type: ConfigurationType,
  params?: NCPSConfigurationParameters,
): NCPSConfiguration =>
  typedConfigurationFactory(
    mockAPIPath,
    NCPSConfiguration,
    type === 'public' ? params : { ...params, accessToken: () => getToken() || '' },
    type === 'auth',
  );
