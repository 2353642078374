import { makeSelectPending } from '@/features/global/selectors';
import { fetchKYCData } from '@/features/kyc/actions';
import { makeSelectKYCData } from '@/features/kyc/selectors';
import { useLoadableData } from '@/hooks';

const fetch = (force: boolean) => fetchKYCData({ force });
const selector = makeSelectKYCData();
const selectPending = makeSelectPending(fetchKYCData);

const useKYCData = () => useLoadableData(fetch, selector, selectPending);

export default useKYCData;
