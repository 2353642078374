import { Divider } from '@mui/material';
import React from 'react';

import { FormattedMessage } from '@/components';
import { withAuthorizationInit } from '@/features/auth/hocs';
import { useUserData } from '@/features/auth/hooks';
import { useKYCStatus } from '@/features/kyc/hooks';
import { KYCStatusSelect, KYCStatusView, MockBanner } from '@/features/mock/components';
import { withMockInitialization, withMockSupported } from '@/features/mock/hocs';
import { I18nFeatureMock } from '@/generated/i18n/i18n';
import { MainLayout } from '@/layout';

const MockKYCPage: React.FC = () => {
  const { data: status } = useKYCStatus();
  const { data: user } = useUserData();
  return (
    <MainLayout
      header={{
        title: <FormattedMessage id={I18nFeatureMock.PAGES_KYC_TITLE} />,
      }}
    >
      <MockBanner data-test="mock-banner" />
      <FormattedMessage
        id={I18nFeatureMock.PAGES_KYC_DESCRIPTION}
        values={{ kyc: <KYCStatusView value={status} />, email: user.data?.email }}
      />
      <Divider orientation="horizontal" />
      <KYCStatusSelect data-test="mock-kyc" />
    </MainLayout>
  );
};

export default withAuthorizationInit(withMockInitialization(withMockSupported(MockKYCPage)));
