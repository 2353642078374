import { createSelector } from '@reduxjs/toolkit';

import type { AppRootState } from '@/app/store';
import { makeSelectRates, makeSelectRateDirtiness, makeSelectPurchase } from '@/features/purchase/selectors';
import { NAMESPACE } from '@/features/transaction/types';
import { asType } from '@/utils/ts';

export type CreateTransactionUnavailabilityReason =
  | 'no-address'
  | 'no-rate'
  | 'dirty-rate'
  | 'no-merchant-tx'
  | 'too-low-amount'
  | 'too-high-amount'
  | 'kyc-required';

export const makeSelectCreateTxData = () =>
  createSelector(
    makeSelectRates(),
    makeSelectRateDirtiness(),
    makeSelectPurchase(),
    (rate, isDirty, { address, merchantTx }) => {
      if (!rate.data) {
        return {
          valid: false,
          reason: asType<CreateTransactionUnavailabilityReason>('no-rate'),
        };
      }
      if (isDirty) {
        return {
          valid: false,
          reason: asType<CreateTransactionUnavailabilityReason>('dirty-rate'),
        };
      }
      if (!address) {
        return {
          valid: false,
          reason: asType<CreateTransactionUnavailabilityReason>('no-address'),
        };
      }
      if (!merchantTx) {
        return {
          valid: false,
          reason: asType<CreateTransactionUnavailabilityReason>('no-merchant-tx'),
        };
      }
      return {
        valid: true,
        data: { rate: rate.data, address, merchantTx },
      };
    },
  );

export const makeSelectRedirectCard = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE],
    (state) => state.redirectCard,
  );
