import type { Func } from './ts';

export const withVoidOrThrow =
  <V extends unknown[], R>(func: Func<V, R>): Func<V, void> =>
  async (...args: V): Promise<void> => {
    await func(...args);
  };

export const withSuppressError =
  <V extends unknown[], R>(func: Func<V, R>, errorFn?: (error: unknown) => void): Func<V, void> =>
  async (...args: V): Promise<void> => {
    try {
      await func(...args);
    } catch (e) {
      errorFn?.(e);
    }
  };

export const withRethrowError =
  <V extends unknown[], R>(func: Func<V, R>, errorFn?: (error: unknown) => void): Func<V, void> =>
  async (...args: V): Promise<void> => {
    try {
      await func(...args);
    } catch (e) {
      throw errorFn ? errorFn?.(e) : e;
    }
  };

export const withOnSuccess =
  <V extends unknown[], R>(func: Func<V, R>, onSuccess: (result: R) => Promise<void> | void): Func<V, R> =>
  async (...args: V): Promise<R> => {
    const result = await func(...args);
    await onSuccess(result);
    return result;
  };

export const withOnError =
  <V extends unknown[], R, E>(func: Func<V, R>, onError: (error: E) => Promise<void> | void): Func<V, R> =>
  async (...args: V): Promise<R> => {
    try {
      return await func(...args);
    } catch (e) {
      onError(e as E);
      throw e;
    }
  };

export const withFinally =
  <V extends unknown[], R>(func: Func<V, R>, onFinally: Func): Func<V, R> =>
  async (...args: V): Promise<R> => {
    try {
      return await func(...args);
    } finally {
      await withSuppressError(onFinally)();
    }
  };

export const onlyUnique = <T>(value: T, index: number, self: T[]) => self.indexOf(value) === index;

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop: Func = () => {};
export const noopAsync = async () => {};
export const identity = <A>(x: A) => x;

export const timeout = async (timeoutMS = 1000) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(''), timeoutMS);
  });
