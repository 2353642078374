import { createReducer } from '@reduxjs/toolkit';

import {
  markKYCStatusDirty,
  storeInitialized,
  storeMockKYCStatus,
  storeMockTransaction,
} from '@/features/mock/actions';
import type { MockState } from '@/features/mock/types';
import { InitStatus, storedDirtyData } from '@/utils/model';

const initialState: MockState = {
  status: storedDirtyData,
  init: { status: InitStatus.NOT_INITIALIZED, value: undefined },
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storeMockKYCStatus, (state, { payload: data }) => ({
      ...state,
      status: { ...data, isDirty: false },
    }))
    .addCase(markKYCStatusDirty, (state) =>
      !state.status.isDirty ? { ...state, status: { ...state.status, isDirty: true } } : state,
    )
    .addCase(storeInitialized, (state, { payload: value }) => ({
      ...state,
      init: { status: InitStatus.FINISHED, value },
    }))
    .addCase(storeMockTransaction, (state, { payload: data }) => ({
      ...state,
      tx: data,
    }));
});

export default reducer;
