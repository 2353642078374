import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchExplicitKYCRequirement, saveExplicitKYCRequirement } from '@/features/kyc/actions';
import { makeSelectKYCRequiredExplicitly } from '@/features/kyc/selectors';
import { useLoadableData } from '@/hooks';

const selector = makeSelectKYCRequiredExplicitly();
const fetch = (force: boolean) => fetchExplicitKYCRequirement({ force });
const selectPending = makeSelectPending(fetchExplicitKYCRequirement);

const useExplicitKYCRequirement = () => {
  const dispatch = useAppDispatch();
  const data = useLoadableData(fetch, selector, selectPending);
  const update = useCallback((isRequired: boolean) => dispatch(saveExplicitKYCRequirement({ isRequired })), [dispatch]);
  return useMemo(() => ({ ...data, update }), [data, update]);
};

export default useExplicitKYCRequirement;
