import { createReducer } from '@reduxjs/toolkit';

import {
  markRateDirty,
  storeCryptoToBuy,
  storeCardLimits,
  storeSelectedCard,
  markCardLimitsDirty,
  fetchRates,
  initPurchase,
} from '@/features/purchase/actions';
import type { PurchaseState } from '@/features/purchase/types';
import { createPurchaseKey } from '@/features/purchase/utils';
import { InitStatus, storedDataError, storedDataLoaded, storedDirtyData } from '@/utils/model';
import { withIgnoreAbortion } from '@/utils/redux';

const initialState: PurchaseState = {
  requested: { value: undefined, status: InitStatus.NOT_INITIALIZED },
  limits: {},
  purchase: {
    fiatToPay: 'USD',
    cryptoToBuy: { amount: '0', currency: 'USDT' },
    purchaseKey: createPurchaseKey('USD', { amount: '0', currency: 'USDT' }),
  },
  rate: { value: storedDirtyData, purchaseKey: createPurchaseKey('USD', { amount: '0', currency: 'USDT' }) },
  pendingRates: [],
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(initPurchase.fulfilled, (state, { payload }) => ({
      ...state,
      requested: { value: payload, status: InitStatus.FINISHED },
      purchase: payload
        ? {
            cryptoToBuy: payload.buy,
            fiatToPay: payload.fiatCurrency,
            purchaseKey: createPurchaseKey(payload.fiatCurrency, payload.buy),
          }
        : state.purchase,
    }))
    .addCase(initPurchase.rejected, (state) => ({
      ...state,
      requested: { value: undefined, status: InitStatus.FINISHED },
    }))
    .addCase(fetchRates.pending, (state, { meta: { requestId } }) => ({
      ...state,
      pendingRates: [...state.pendingRates, requestId],
    }))
    .addCase(fetchRates.fulfilled, (state, { payload: { rate }, meta: { requestId } }) =>
      requestId === state.purchase.purchaseKey
        ? {
            ...state,
            pendingRates: state.pendingRates.filter((id) => id !== requestId),
            rate: { value: storedDataLoaded(rate), purchaseKey: requestId },
          }
        : state,
    )
    .addCase(
      fetchRates.rejected,
      withIgnoreAbortion((state, { meta: { requestId }, error }) =>
        requestId === state.purchase.purchaseKey
          ? {
              ...state,
              pendingRates: state.pendingRates.filter((id) => id !== requestId),
              rate: { value: storedDataError(`${error.name}`), purchaseKey: requestId },
            }
          : state,
      ),
    )
    .addCase(markRateDirty, (state, { payload: { purchaseKey } }) =>
      !state.rate.value.isDirty && purchaseKey === state.rate.purchaseKey
        ? {
            ...state,
            rate: { ...state.rate, value: { ...state.rate, isDirty: true } },
          }
        : state,
    )
    .addCase(storeSelectedCard, (state, { payload }) =>
      state.selectedCardId !== payload ? { ...state, selectedCardId: payload } : state,
    )
    .addCase(storeCryptoToBuy, (state, { payload }) =>
      state.purchase.cryptoToBuy.amount !== payload.amount
      || state.purchase.cryptoToBuy.currency !== payload.currency
      || state.purchase.cryptoToBuy.network !== payload.network
        ? {
            ...state,
            purchase: {
              ...state.purchase,
              cryptoToBuy: payload,
              purchaseKey: createPurchaseKey(state.purchase.fiatToPay, payload),
            },
          }
        : state,
    )
    .addCase(storeCardLimits, (state, { payload: { pair, data } }) => ({
      ...state,
      limits: { ...state.limits, [pair]: { ...data, isDirty: false } },
    }))
    .addCase(markCardLimitsDirty, (state, { payload: { pair } }) =>
      !state.limits[pair]?.isDirty
        ? { ...state, limits: { ...state.limits, [pair]: { ...state.limits[pair], isDirty: true } } }
        : state,
    );
});

export default reducer;
