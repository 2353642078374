import React from 'react';
import { Route } from 'react-router-dom';

import { FormattedMessage, NotificationMessage } from '@/components';
import { I18nCommon } from '@/generated/i18n/i18n';
import { BlankLayout } from '@/layout';
import { AppPageRoutes } from '@/pages/routes';

import Pages3ds from './3ds';
import PagesCard from './card';
import PagesKYC from './kyc';
import { MockPageRoutes } from './routes';

export const MockDefaultRoute: React.FC = () => (
  <BlankLayout>
    <NotificationMessage
      data-test="mock-unknown"
      severity="error"
      title={<FormattedMessage id={I18nCommon.ERRORS_PAGENOTFOUND_TITLE} />}
    >
      <FormattedMessage id={I18nCommon.ERRORS_PAGENOTFOUND_CONTENT} />
    </NotificationMessage>
  </BlankLayout>
);

export default window.env.MOCK_MODE_SUPPORTED === 'true'
  ? [
      <Route path={AppPageRoutes.MOCK} key={AppPageRoutes.MOCK}>
        <Route path={MockPageRoutes.CARD_3DS} Component={Pages3ds} />
        <Route path={MockPageRoutes.CARD} Component={PagesCard} />
        <Route path={MockPageRoutes.KYC} Component={PagesKYC} />
        <Route path="*" Component={MockDefaultRoute} />
        <Route path="" Component={MockDefaultRoute} />
      </Route>,
    ]
  : [];
