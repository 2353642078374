import { createSelector } from '@reduxjs/toolkit';

import type { AppRootState } from '@/app/store';

import { NAMESPACE } from './types';

export const makeSelectCallbackStatus = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE],
    (state) => state.status,
  );
