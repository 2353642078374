import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/app/hooks';
import { processCardCallback, processKYCCallback } from '@/features/callback/actions';
import { makeSelectCallbackStatus } from '@/features/callback/selectors';
import { useFetching } from '@/hooks';

const selectStatus = makeSelectCallbackStatus();

const useCallbackProcessing = (type: 'KYC' | 'payment', success: boolean) => {
  const status = useSelector(selectStatus);
  const dispatch = useAppDispatch();
  const process = useCallback(
    async () => dispatch(type === 'KYC' ? processKYCCallback({ success }) : processCardCallback({ success })),
    [dispatch, success, type],
  );
  useFetching(process, status === 'processing');
};

export default useCallbackProcessing;
