export enum I18nCommon {
  BACKDROP_TITLE = 'common.backdrop.title',
  ERRORS_GLOBAL_TITLE = 'common.errors.global.title',
  ERRORS_GLOBAL_CONTENT = 'common.errors.global.content',
  ERRORS_UNAUTHORIZED_TITLE = 'common.errors.unauthorized.title',
  ERRORS_UNAUTHORIZED_CONTENT = 'common.errors.unauthorized.content',
  ERRORS_PAGENOTFOUND_TITLE = 'common.errors.pageNotFound.title',
  ERRORS_PAGENOTFOUND_CONTENT = 'common.errors.pageNotFound.content',
  MESSAGES_OPERATIONS_INIT = 'common.messages.operations.init',
  MESSAGES_OPERATIONS_LOGOUT = 'common.messages.operations.logout',
  MESSAGES_FORBIDDEN_TITLE = 'common.messages.forbidden.title',
  MESSAGES_FORBIDDEN_CONTENT = 'common.messages.forbidden.content',
  MESSAGES_MAINTENANCE_TITLE = 'common.messages.maintenance.title',
  MESSAGES_MAINTENANCE_CONTENT = 'common.messages.maintenance.content'
}
export enum I18nComponents {
  CLIPBOARD_COPY_TOOLTIP = 'components.clipboard.copy.tooltip',
  CLIPBOARD_COPIED_TOOLTIP = 'components.clipboard.copied.tooltip',
  DATA_FETCH_TITLE = 'components.data.fetch.title',
  DATA_FETCH_DESCRIPTION = 'components.data.fetch.description',
  DATA_FETCH_REFRESH = 'components.data.fetch.refresh',
  DATA_FETCH_BACK = 'components.data.fetch.back',
  IFRAME_ERROR_BANNER_TITLE = 'components.iframe.error.banner.title',
  IFRAME_ERROR_BANNER_DESCRIPTION = 'components.iframe.error.banner.description',
  SEARCH_PLACEHOLDER = 'components.search.placeholder'
}
export enum I18nFeatureBuyKyc {
  PAGE_TITLE = 'feature.buy.kyc.page.title',
  COMPONENTS_RESULT_STATUSES_INCOMPLETE_MESSAGE = 'feature.buy.kyc.components.result.statuses.incomplete.message',
  COMPONENTS_RESULT_STATUSES_COMPLETE_MESSAGE = 'feature.buy.kyc.components.result.statuses.complete.message',
  COMPONENTS_RESULT_STATUSES_UNDERREVIEW_MESSAGE = 'feature.buy.kyc.components.result.statuses.underReview.message',
  COMPONENTS_RESULT_STATUSES_FAILED_MESSAGE = 'feature.buy.kyc.components.result.statuses.failed.message',
  COMPONENTS_WIDGET_TITLE = 'feature.buy.kyc.components.widget.title',
  COMPONENTS_WIDGET_LOADING = 'feature.buy.kyc.components.widget.loading',
  MESSAGES_FAILED_TITLE = 'feature.buy.kyc.messages.failed.title',
  MESSAGES_FAILED_DESCRIPTION = 'feature.buy.kyc.messages.failed.description',
  MESSAGES_SUCCESS_TITLE = 'feature.buy.kyc.messages.success.title',
  MESSAGES_SUCCESS_DESCRIPTION = 'feature.buy.kyc.messages.success.description'
}
export enum I18nFeatureBuyPurchase {
  PAGE_TITLE = 'feature.buy.purchase.page.title',
  COMPONENTS_ADDRESS_BLOCK_TEXT = 'feature.buy.purchase.components.address.block.text',
  COMPONENTS_CARDS_BLOCK_SELECT = 'feature.buy.purchase.components.cards.block.select',
  COMPONENTS_RATES_SUMMARY_NOTAVAILABLE = 'feature.buy.purchase.components.rates.summary.notAvailable',
  COMPONENTS_RATES_SUMMARY_TOTAL = 'feature.buy.purchase.components.rates.summary.total',
  COMPONENTS_RATES_SUMMARY_LABELS_FEES = 'feature.buy.purchase.components.rates.summary.labels.fees',
  COMPONENTS_RATES_SUMMARY_LABELS_SUBTOTAL = 'feature.buy.purchase.components.rates.summary.labels.subtotal',
  COMPONENTS_RATES_SUMMARY_LABELS_TOTAL = 'feature.buy.purchase.components.rates.summary.labels.total',
  COMPONENTS_SUMMARY_TITLE = 'feature.buy.purchase.components.summary.title',
  COMPONENTS_SUMMARY_REFRESH_TITLE = 'feature.buy.purchase.components.summary.refresh.title',
  COMPONENTS_SUMMARY_RETRY_DESCRIPTION = 'feature.buy.purchase.components.summary.retry.description',
  COMPONENTS_SUBMIT_KYC_TITLE = 'feature.buy.purchase.components.submit.kyc.title',
  COMPONENTS_SUBMIT_BUY_TITLE = 'feature.buy.purchase.components.submit.buy.title'
}
export enum I18nFeatureCards {
  COMPONENTS_SELECT_OLDCARD_TITLE = 'feature.cards.components.select.oldCard.title',
  COMPONENTS_SELECT_NEWCARD_TITLE = 'feature.cards.components.select.newCard.title'
}
export enum I18nFeatureBuyTransaction {
  PAGE_TITLE = 'feature.buy.transaction.page.title',
  PAGE_PREPARE = 'feature.buy.transaction.page.prepare',
  COMPONENTS_WIDGET_LOADING = 'feature.buy.transaction.components.widget.loading',
  MESSAGES_FAILED_TITLE = 'feature.buy.transaction.messages.failed.title',
  MESSAGES_FAILED_DESCRIPTION = 'feature.buy.transaction.messages.failed.description',
  MESSAGES_SUCCESS_TITLE = 'feature.buy.transaction.messages.success.title',
  MESSAGES_SUCCESS_DESCRIPTION = 'feature.buy.transaction.messages.success.description'
}
export enum I18nFeatureMock {
  DISABLED_TITLE = 'feature.mock.disabled.title',
  DISABLED_DESCRIPTION = 'feature.mock.disabled.description',
  ERROR_TITLE = 'feature.mock.error.title',
  ERROR_DESCRIPTION = 'feature.mock.error.description',
  COMPONENTS_BANNER_TITLE = 'feature.mock.components.banner.title',
  COMPONENTS_BANNER_DESCRIPTION = 'feature.mock.components.banner.description',
  COMPONENTS_CARD_TITLE = 'feature.mock.components.card.title',
  COMPONENTS_CARD_NUMBER_LABEL = 'feature.mock.components.card.number.label',
  COMPONENTS_CARD_NUMBER_ERROR = 'feature.mock.components.card.number.error',
  COMPONENTS_CARD_THRU_LABEL = 'feature.mock.components.card.thru.label',
  COMPONENTS_CARD_THRU_ERROR = 'feature.mock.components.card.thru.error',
  COMPONENTS_CARD_CVC_LABEL = 'feature.mock.components.card.cvc.label',
  COMPONENTS_CARD_CVC_ERROR = 'feature.mock.components.card.cvc.error',
  COMPONENTS_CARD_HOLDER_LABEL = 'feature.mock.components.card.holder.label',
  COMPONENTS_CARD_HOLDER_ERROR = 'feature.mock.components.card.holder.error',
  COMPONENTS_CARD3DS_INPUT_LABEL = 'feature.mock.components.card3ds.input.label',
  COMPONENTS_CARD3DS_INPUT_ERROR = 'feature.mock.components.card3ds.input.error',
  COMPONENTS_KYC_STATUSES_COMPLETE = 'feature.mock.components.kyc.statuses.complete',
  COMPONENTS_KYC_STATUSES_INCOMPLETE = 'feature.mock.components.kyc.statuses.incomplete',
  COMPONENTS_KYC_STATUSES_UNDERREVIEW = 'feature.mock.components.kyc.statuses.underReview',
  COMPONENTS_KYC_STATUSES_FAILED = 'feature.mock.components.kyc.statuses.failed',
  PAGES_CARD_TITLE = 'feature.mock.pages.card.title',
  PAGES_CARD_DESCRIPTION = 'feature.mock.pages.card.description',
  PAGES_CARD_SUBMIT = 'feature.mock.pages.card.submit',
  PAGES_CARD_KYC_FAILED_TITLE = 'feature.mock.pages.card.kyc.failed.title',
  PAGES_CARD_KYC_FAILED_DESCRIPTION = 'feature.mock.pages.card.kyc.failed.description',
  PAGES_CARD_KYC_BUTTON_TITLE = 'feature.mock.pages.card.kyc.button.title',
  PAGES_CARD3DS_TITLE = 'feature.mock.pages.card3ds.title',
  PAGES_CARD3DS_DESCRIPTION = 'feature.mock.pages.card3ds.description',
  PAGES_CARD3DS_SUBMIT = 'feature.mock.pages.card3ds.submit',
  PAGES_KYC_TITLE = 'feature.mock.pages.kyc.title',
  PAGES_KYC_DESCRIPTION = 'feature.mock.pages.kyc.description'
}
export enum I18nFeatureCallback {
  PAGE_TITLE = 'feature.callback.page.title',
  COMPONENTS_BANNER_INPROGRESS_TITLE = 'feature.callback.components.banner.inProgress.title',
  COMPONENTS_BANNER_INPROGRESS_DESCRIPTION = 'feature.callback.components.banner.inProgress.description',
  COMPONENTS_BANNER_ERROR_TITLE = 'feature.callback.components.banner.error.title',
  COMPONENTS_BANNER_ERROR_DESCRIPTION = 'feature.callback.components.banner.error.description'
}

export type I18nMessages = I18nCommon | I18nComponents | I18nFeatureBuyKyc | I18nFeatureBuyPurchase | I18nFeatureCards | I18nFeatureBuyTransaction | I18nFeatureMock | I18nFeatureCallback;
