import {
  InputBaseComponentProps,
  InputLabelProps as BaseInputLabelProps,
  InputProps as BaseInputProps,
  TextField,
} from '@mui/material';
import React, { ReactNode, useState } from 'react';

import type { Value } from '@/utils/model';
import type { Func } from '@/utils/ts';

export interface TextFieldInputProps {
  'data-test'?: string;
  InputProps?: Partial<Omit<BaseInputProps, 'disableUnderline' | 'inputComponent'>>;
  InputLabelProps?: Partial<BaseInputLabelProps>;
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  value?: Value<string>;
  onChange?: Func<[string]>;
  messages?: {
    helperText?: ReactNode;
    label?: ReactNode;
    error?: ReactNode;
  };
}

const TextFieldInput: React.FC<TextFieldInputProps> = ({
  'data-test': dataTest,
  InputProps,
  InputLabelProps,
  inputComponent,
  value,
  onChange,
  messages,
}) => {
  const [isFocussed, setFocussed] = useState<boolean>(false);
  return (
    <TextField
      style={{display: 'flex'}}
      data-test={dataTest}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      InputLabelProps={{
        ...InputLabelProps,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'data-test': dataTest && `${dataTest}-label`,
      }}
      variant="filled"
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'data-test': dataTest && `${dataTest}-input`,
        disableUnderline: true,
        ...(inputComponent ? { inputComponent } : {}),
        ...InputProps,
      }}
      label={messages?.label}
      value={value?.value || ''}
      onChange={async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (!onChange) {
          return;
        }
        const inputValue = event.target.value;
        if (inputValue !== value?.value) {
          await onChange(inputValue);
        }
      }}
      onFocus={(): void => setFocussed(true)}
      onBlur={(): void => setFocussed(false)}
      {...(value?.hasError && !isFocussed
        ? { helperText: messages?.error, error: true }
        : { helperText: messages?.helperText })}
      // FIXME: best place to trim, but onBlur handler stopping event propagation, so i.e submit does't work at all
      // onBlur={async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
      //   const inputValue = event.target.value?.trim();
      //   await onChange(inputValue);
      // }}
    />
  );
};

export default React.memo(TextFieldInput);
