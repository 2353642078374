import { fetchMockKYC } from '@/features/mock/actions';
import { makeSelectMockKYCStatus } from '@/features/mock/selectors';
import { useLoadableData } from '@/hooks';

const fetch = (force: boolean) => fetchMockKYC({ force });
const selector = makeSelectMockKYCStatus();

const useMockKYCData = () => useLoadableData(fetch, selector);

export default useMockKYCData;
