import { Stack } from '@mui/material';
import BigNumber from 'bignumber.js';
import isNil from 'lodash/isNil';
import React, { useMemo } from 'react';

import { useCurrency } from '@/features/dictionary/hooks';
import { useLocaleSettings } from '@/hooks';

import type { MoneyAmountValueProps } from './types';

const toBigNumber = (value?: string | BigNumber) => {
  if (typeof value !== 'string') {
    return value;
  }
  try {
    return new BigNumber(value);
  } catch (e) {
    return undefined;
  }
};

const MoneyAmountValue: React.FC<MoneyAmountValueProps> = ({
  value,
  currency,
  withSign,
  withCurrency,
  className,
  style,
  currencyClassName,
  'data-test': dataTest,
}) => {
  const { formatBigNumber } = useLocaleSettings();
  const displayOptions = useCurrency(currency);
  const numValue = useMemo(() => toBigNumber(value), [value]);
  const formattedValue = useMemo(() => {
    const fraction = displayOptions.data.data?.digitsToDisplay;
    if (!fraction) {
      return '';
    }
    return formatBigNumber(fraction, numValue);
  }, [displayOptions.data.data?.digitsToDisplay, formatBigNumber, numValue]);
  return !isNil(value) ? (
    <Stack className={className} style={{ display: 'inline-flex', ...style }} spacing={.6} direction="row">
      <span style={{ display: 'inline-flex' }} className="ym-hide-content" data-test={dataTest && `${dataTest}-amount`}>
        {withSign && numValue?.isPositive() ? '+' : ''}
        {formattedValue}
      </span>
      {withCurrency && currency && (
        <span className={currencyClassName} data-test={dataTest && `${dataTest}-currency`}>
          {currency}
        </span>
      )}
    </Stack>
  ) : null;
};

export default React.memo(MoneyAmountValue);
