import type { CaseReducer } from '@reduxjs/toolkit';
import type { AsyncThunkRejectedActionCreator } from '@reduxjs/toolkit/dist/createAsyncThunk';

export const withIgnoreAbortion =
  <State, ActionCreator extends AsyncThunkRejectedActionCreator<unknown, unknown>>(
    func: CaseReducer<State, ReturnType<ActionCreator>>,
  ): CaseReducer<State, ReturnType<ActionCreator>> =>
  (state, action) => {
    if (action.meta.aborted || action.meta.condition) {
      return state;
    }
    return func(state, action);
  };
