import { createReducer } from '@reduxjs/toolkit';

import { fetchCards, markCardsDirty } from '@/features/cards/actions';
import type { CardsState } from '@/features/cards/types';
import { storedDirtyData } from '@/utils/model';
import { withIgnoreAbortion } from '@/utils/redux';

const initialState: CardsState = {
  cards: storedDirtyData,
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markCardsDirty, (state) =>
      !state.cards.isDirty ? { ...state, cards: { ...state.cards, isDirty: true } } : state,
    )
    .addCase(fetchCards.fulfilled, (state, action) => ({
      ...state,
      cards: { data: action.payload, isDirty: false },
    }))
    .addCase(
      fetchCards.rejected,
      withIgnoreAbortion((state, action) => ({
        ...state,
        cards: { error: action.error?.code || `${action.error}`, isDirty: false },
      })),
    );
});

export default reducer;
