/* eslint-disable */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * On- and Off-Ramp API Specification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PublicCurrencies200ResponseDataConfigAPIModel } from './PublicCurrencies200ResponseDataConfigAPIModel';
import {
    PublicCurrencies200ResponseDataConfigAPIModelFromJSON,
    PublicCurrencies200ResponseDataConfigAPIModelFromJSONTyped,
    PublicCurrencies200ResponseDataConfigAPIModelToJSON,
} from './PublicCurrencies200ResponseDataConfigAPIModel';

/**
 * 
 * @export
 * @interface PublicCurrencies200ResponseDataAPIModel
 */
export interface PublicCurrencies200ResponseDataAPIModel {
    /**
     * 
     * @type {PublicCurrencies200ResponseDataConfigAPIModel}
     * @memberof PublicCurrencies200ResponseDataAPIModel
     */
    config?: PublicCurrencies200ResponseDataConfigAPIModel;
    /**
     * Crypto currencies
     * @type {Array<string>}
     * @memberof PublicCurrencies200ResponseDataAPIModel
     */
    crypto?: Array<string>;
    /**
     * Fiat currencies
     * @type {Array<string>}
     * @memberof PublicCurrencies200ResponseDataAPIModel
     */
    fiat?: Array<string>;
}

/**
 * Check if a given object implements the PublicCurrencies200ResponseDataAPIModel interface.
 */
export function instanceOfPublicCurrencies200ResponseDataAPIModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PublicCurrencies200ResponseDataAPIModelFromJSON(json: any): PublicCurrencies200ResponseDataAPIModel {
    return PublicCurrencies200ResponseDataAPIModelFromJSONTyped(json, false);
}

export function PublicCurrencies200ResponseDataAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicCurrencies200ResponseDataAPIModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': !exists(json, 'config') ? undefined : PublicCurrencies200ResponseDataConfigAPIModelFromJSON(json['config']),
        'crypto': !exists(json, 'crypto') ? undefined : json['crypto'],
        'fiat': !exists(json, 'fiat') ? undefined : json['fiat'],
    };
}

export function PublicCurrencies200ResponseDataAPIModelToJSON(value?: PublicCurrencies200ResponseDataAPIModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': PublicCurrencies200ResponseDataConfigAPIModelToJSON(value.config),
        'crypto': value.crypto,
        'fiat': value.fiat,
    };
}

