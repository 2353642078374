import React from 'react';
import { Navigate } from 'react-router-dom';

import { usePurchaseKYCLimits } from '@/features/purchase/hooks';

const withKYCGuard =
  <P extends Record<string, unknown>>(pathToRedirect: string) =>
  (Component: React.FC<P>): React.FC<P> =>
  (props: P) => {
    const { isKYCRequired } = usePurchaseKYCLimits();
    return isKYCRequired ? <Navigate to={pathToRedirect} /> : <Component {...props} />;
  };

export default withKYCGuard;
