import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { DataFetchError, FormattedMessage, IFrameErrorBanner, ProgressBox } from '@/components';
import { MercuryoCallbackMessageCard } from '@/features/callback/types';
import { makeSelectIsMockEnabled } from '@/features/mock/selectors';
import { processCardTransactionCallback } from '@/features/transaction/actions';
import { useTransactionDataURL } from '@/features/transaction/hooks';
import { makeSelectCreateTxData } from '@/features/transaction/selectors';
import { I18nFeatureBuyTransaction } from '@/generated/i18n/i18n';
import { useIFrameLoading, useSubmitting } from '@/hooks';
import { BuyPageRoutes, buyRoute } from '@/pages/buy/routes';

import type { TransactionCardWidgetProps } from './types';

const selectTxData = makeSelectCreateTxData();
const selectMockEnabled = makeSelectIsMockEnabled();

const TransactionCardWidget: React.FC<TransactionCardWidgetProps> = ({ 'data-test': dataTest }) => {
  const isMockEnabled = useAppSelector(selectMockEnabled);
  const { data: iframeURL, loading: isURLLoading, forceRefresh } = useTransactionDataURL();
  const origin = useMemo(
    () => (iframeURL.data?.url ? new URL(iframeURL.data.url).origin : undefined),
    [iframeURL.data?.url],
  );
  const { data: tx } = useAppSelector(selectTxData);
  const [refreshing, withRefreshing] = useSubmitting(false);
  const { state, withMarkLoading, markLoaded } = useIFrameLoading();
  const fullRefresh = useMemo(
    () => withRefreshing(withMarkLoading(forceRefresh)),
    [forceRefresh, withMarkLoading, withRefreshing],
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const listener = async (event: MessageEvent) => {
      if (!origin || (!event.origin.includes(origin) && !event.origin.includes(window.location.origin))) {
        return;
      }

      const eventData: MercuryoCallbackMessageCard = event.data;
      if (eventData.type === 'loaded' || eventData.type === 'intercom-snippet__ready') {
        markLoaded();
      } else if (eventData.type === 'payment') {
        const result = await (async () => {
          try {
            return await dispatch(
              processCardTransactionCallback({
                success: eventData.data.success,
                msg: eventData.data.msg,
                tx: tx
                  ? {
                      txId: tx.merchantTx,
                      amount: `${tx.rate.toBuy.amount} ${tx.rate.toBuy.currency}`,
                      address: tx.address,
                    }
                  : undefined,
              }),
            ).unwrap();
          } catch (e) {
            console.error(e);
            return false;
          }
        })();
        if (result === 'failed') {
          navigate(`${buyRoute(BuyPageRoutes.PURCHASE)}`);
        } else if (result === 'kyc-required') {
          navigate(`${buyRoute(BuyPageRoutes.KYC)}`);
        }
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, [dispatch, navigate, origin, markLoaded, tx]);

  if (state !== 'loading' && !iframeURL?.data) {
    return <DataFetchError refresh={fullRefresh} />;
  }

  return (
    <>
      {!refreshing && !isURLLoading && iframeURL.data && (
        <iframe
          title="card"
          src={iframeURL.data.url}
          referrerPolicy={!isMockEnabled ? "no-referrer" : undefined}
          data-test={dataTest}
          style={{ height: '100%', border: 0 }}
          hidden={state !== 'loaded'}
        />
      )}
      {state === 'loading' && (
        <ProgressBox title={<FormattedMessage id={I18nFeatureBuyTransaction.COMPONENTS_WIDGET_LOADING} />} />
      )}
      {state === 'timeout' && <IFrameErrorBanner data-test={dataTest} refresh={fullRefresh} />}
    </>
  );
};

export default React.memo(TransactionCardWidget);
