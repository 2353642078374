import * as Sentry from '@sentry/react';
import React, { PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { NotificationMessage } from '@/components';

class ErrorBoundary extends React.Component<PropsWithChildren, { hasError: boolean }> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(/* error: string */): { hasError: boolean } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    Sentry.captureException(error);
  }

  render(): ReactNode {
    return this.state.hasError ? (
      <NotificationMessage title={<FormattedMessage id="common.errors.global.title" />}>
        <FormattedMessage id="common.errors.global.content" />
      </NotificationMessage>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
